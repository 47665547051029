import { Text } from "src/ui/Text/Text";
import { IoIosWarning } from "react-icons/io";
import { isReportsOpenSourcesIoSphere, OpenSourcesModel } from "@api/report";
import { OpenSourcesCategories } from "@api/report/constants";
import { Status } from "./Status/Status";
import s from "./OpenedData.module.scss";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";

export type OpenedDataProps = {
  openSources?: OpenSourcesModel;
} & RetrieveCardCommonProps;

export const OpenedData = ({ openSources, status }: OpenedDataProps) => {
  if (
    openSources &&
    isReportsOpenSourcesIoSphere(openSources) &&
    openSources?.status === "NO_DATA"
  )
    return null;

  return (
    <RetrieveCard title="Открытые данные" status={status}>
      <>
        <div className={s.container__alert}>
          <div className={s.container__alert__inner}>
            <IoIosWarning className={s.container__alert__inner__icon} />
            <Text variant="body-5">
              Сбор открытых данных занимает до 5 минут. Данные подгружаются в
              отчет автоматически.
            </Text>
          </div>
        </div>
        {openSources && (
          <div className={s.container__content}>
            <Text color="darkGray" variant="label-1">
              Источник
            </Text>
            <Text color="darkGray" variant="label-1">
              Статус
            </Text>

            {openSources &&
              Object.keys(openSources).map((openSource: string) => (
                <>
                  <Text variant="body-5">
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    {openSources[openSource]?.source
                      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        openSources[openSource]?.source
                      : OpenSourcesCategories[openSource]}
                  </Text>
                  <Status
                    // eslint-disable-next-line
                    status={openSources[openSource]?.status}
                    // eslint-disable-next-line
                    found={openSources[openSource]?.found}
                    // eslint-disable-next-line
                    value={openSources[openSource]?.value}
                  />
                </>
              ))}
          </div>
        )}
      </>
    </RetrieveCard>
  );
};
