export const parseError = (errorLike: unknown) => {
  if (typeof errorLike === "string") {
    return errorLike;
  }

  if (!errorLike) {
    return "";
  }

  if (typeof errorLike === "object") {
    if ("message" in errorLike && typeof errorLike.message === "string") {
      return errorLike.message;
    }
    if (
      "description" in errorLike &&
      typeof errorLike.description === "string"
    ) {
      return errorLike.description;
    }
    return String(errorLike);
  }

  return "";
};
