import React from "react";
import {
  ReportsPersonInfoData,
  ReportsPersonInfoDataEnum,
  reportsPersonInfoDataWithTitles,
} from "@api/report";
import { Text } from "../../ui/Text/Text";
import { Expand } from "../../ui/Expand/Expand";
import { useBooleanState } from "../../hooks/useBooleanState";
import s from "./Data.module.scss";
import { Block } from "../../ui/Block/Block";

interface DataProps {
  reportsPersonInfoData: ReportsPersonInfoData;
}

export const Data = ({ reportsPersonInfoData }: DataProps) => {
  const { state, toggle } = useBooleanState(true);

  return (
    <Block className={s.container}>
      <div className={s.container__header}>
        <Expand state={state} toggle={toggle}>
          <Text variant="subheader-4">{reportsPersonInfoData?.source}</Text>
        </Expand>
      </div>

      {state && (
        <div className={s.container__content}>
          {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.source_year] && (
            <>
              <Text color="darkGray" variant="subheader-2">
                {
                  reportsPersonInfoDataWithTitles[
                    ReportsPersonInfoDataEnum.source_year
                  ]
                }
              </Text>
              <Text>
                {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.source_year]}
              </Text>
            </>
          )}

          {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.full_name] && (
            <>
              <Text color="darkGray" variant="subheader-2">
                {
                  reportsPersonInfoDataWithTitles[
                    ReportsPersonInfoDataEnum.full_name
                  ]
                }
              </Text>
              <Text>
                {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.full_name]}
              </Text>
            </>
          )}

          {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.inn] && (
            <>
              <Text color="darkGray" variant="subheader-2">
                {reportsPersonInfoDataWithTitles[ReportsPersonInfoDataEnum.inn]}
              </Text>
              <Text>
                {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.inn]}
              </Text>
            </>
          )}

          {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.passport] && (
            <>
              <Text color="darkGray" variant="subheader-2">
                {
                  reportsPersonInfoDataWithTitles[
                    ReportsPersonInfoDataEnum.passport
                  ]
                }
              </Text>
              <Text>
                {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.passport]}
              </Text>
            </>
          )}

          {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.phone] && (
            <>
              <Text color="darkGray" variant="subheader-2">
                {
                  reportsPersonInfoDataWithTitles[
                    ReportsPersonInfoDataEnum.phone
                  ]
                }
              </Text>
              <Text>
                {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.phone]}
              </Text>
            </>
          )}

          {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.snils] && (
            <>
              <Text color="darkGray" variant="subheader-2">
                {
                  reportsPersonInfoDataWithTitles[
                    ReportsPersonInfoDataEnum.snils
                  ]
                }
              </Text>
              <Text>
                {reportsPersonInfoData?.[ReportsPersonInfoDataEnum.snils]}
              </Text>
            </>
          )}

          {reportsPersonInfoData?.other_data &&
            Object.keys(reportsPersonInfoData?.other_data).map((otherData) =>
              reportsPersonInfoData?.other_data?.[otherData] ? (
                <>
                  <Text color="darkGray" variant="subheader-2">
                    {otherData}
                  </Text>
                  <Text>
                    {String(reportsPersonInfoData?.other_data?.[otherData])}
                  </Text>
                </>
              ) : null,
            )}
        </div>
      )}
    </Block>
  );
};
