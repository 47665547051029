import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { MdFileCopy } from "react-icons/md";
import { Button } from "src/ui/Button/Button";
import { FC, useCallback, useMemo } from "react";
import { copyTextToClipboard } from "src/utils/copyToClipboard";
import { useNotification } from "src/hooks/useNotification";
import s from "./AccessesAddInvite.module.scss";

interface AccessesAddInviteProps {
  login: string;
  password: string;
}

export const AccessesAddInvite: FC<AccessesAddInviteProps> = ({
  login,
  password,
}) => {
  const text = useMemo(
    () =>
      `Вам предоставлен доступ к сервису OdysseySearch\nВойти в личный кабинет можно по адресу: \`${window.location.origin}/#/lk/user\`\n${login ? `Логин: ${login}\n` : ""}${password ? `Пароль: ${password}\n` : ""}`,
    [login, password],
  );

  const [api] = useNotification();

  const onCopy = useCallback(() => {
    try {
      copyTextToClipboard(text);
    } finally {
      api.success({
        message: "Скопирован в буфер обмена",
      });
    }
  }, [text, api]);

  return (
    <Flex vertical gap={4} className={s.card}>
      <Flex justify="space-between">
        <Text variant="subheader-3">Поделитесь доступом в личный кабинет</Text>
        <Flex gap={16}>
          <Button
            type="flat"
            size="small"
            pin="round"
            icon={<MdFileCopy size={16} />}
            onClick={onCopy}
          />
        </Flex>
      </Flex>
      <Text onClick={onCopy} className={s.card__text}>
        <pre>{text}</pre>
      </Text>
    </Flex>
  );
};
