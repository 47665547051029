import { ReportStatus } from "@api/report";

export const calculateReportStatus = (
  sourceStatuses: (ReportStatus | undefined)[]
): ReportStatus | undefined => {
  const sourceStatusesSet = new Set(
    sourceStatuses.filter((sourceStatus) => sourceStatus)
  );

  if (sourceStatusesSet.size === 0) return undefined;

  if (sourceStatusesSet.has(ReportStatus.InProgress))
    return ReportStatus.InProgress;

  if (
    sourceStatusesSet.has(ReportStatus.Canceled) &&
    !sourceStatusesSet.has(ReportStatus.Finished) &&
    !sourceStatusesSet.has(ReportStatus.InProgress)
  )
    return ReportStatus.Canceled;

  if (
    sourceStatusesSet.has(ReportStatus.Finished) &&
    !sourceStatusesSet.has(ReportStatus.InProgress)
  )
    return ReportStatus.Finished;

  if (
    sourceStatusesSet.has(ReportStatus.NoData) &&
    !sourceStatusesSet.has(ReportStatus.InProgress)
  )
    return ReportStatus.NoData;
};

export const getReportStatus = (
  sourceStatuses?: Record<string, ReportStatus>
): ReportStatus | undefined => {
  if (!sourceStatuses) return undefined;

  const sourceStatusesValues: ReportStatus[] = Object.values(sourceStatuses);

  return calculateReportStatus(sourceStatusesValues);
};
