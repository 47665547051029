import { forwardRef, PropsWithChildren, useCallback, useMemo } from "react";
import { Upload, UploadFile } from "antd";
import { UploadRef } from "antd/es/upload/Upload";
import classNames from "classnames";
import s from "./DropZone.module.scss";

export interface DropZoneFile {
  uid: string;
  name: string;
  lastModifiedDate: Date;
  originFileObj: File;
}

export interface DropZoneProps extends PropsWithChildren {
  value?: DropZoneFile[];
  onChange?: (value: DropZoneFile[]) => void;
  multiple?: boolean;
  accept?: string;
}

export const DropZone = forwardRef<UploadRef, DropZoneProps>(
  ({ children, value, onChange, multiple, accept }, ref) => {
    const handleChange = useCallback(
      ({ fileList }: { fileList: UploadFile[] }) => {
        const files: DropZoneFile[] = [];

        fileList.forEach(({ uid, name, originFileObj, lastModifiedDate }) => {
          if (originFileObj && lastModifiedDate) {
            files.push({
              uid,
              name,
              originFileObj,
              lastModifiedDate,
            });
          }
        });

        onChange?.(files);
      },
      [onChange],
    );

    const fileList: UploadFile[] | undefined = useMemo(
      () =>
        value?.map((dropZoneFile) => ({
          ...dropZoneFile,
          originFileObj: {
            ...dropZoneFile.originFileObj,
            uid: dropZoneFile.uid,
            lastModifiedDate: dropZoneFile.lastModifiedDate,
          },
        })),
      [value],
    );

    return (
      <Upload.Dragger
        ref={ref}
        rootClassName={classNames(s.dropZone, { [s.dropZone_hover]: true })}
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={() => false}
        accept={accept}
        multiple={multiple}
        showUploadList={false}
      >
        {children}
      </Upload.Dragger>
    );
  },
);
