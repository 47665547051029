import { useSearchParams } from "react-router-dom";
import { ReportType } from "@api/report/constants";
import { SearchFioWidget } from "@widgets/SearchFioWidget/SearchFioWidget";
import { SearchPhoneWidget } from "@widgets/SearchPhoneWidget/SearchPhoneWidget";
import { SearchAddressWidget } from "@widgets/SearchAddressWidget/SearchAddressWidget";
import { SearchSnilsWidget } from "@widgets/SearchSnilsWidget/SearchSnilsWidget";
import { SearchPassportWidget } from "@widgets/SearchPassportWidget/SearchPassportWidget";
import { SearchGrnWidget } from "@widgets/SearchGrnWidget/SearchGrnWidget";
import { SearchVinWidget } from "@widgets/SearchVinWidget/SearchVinWidget";
import { SearchDriverLicenseWidget } from "@widgets/SearchDriverLicenseWidget/SearchDriverLicenseWidget";
import { SearchEmailWidget } from "@widgets/SearchEmailWidget/SearchEmailWidget";
import { SearchOgrnWidget } from "@widgets/SearchOgrnWidget/SearchOgrnWidget";
import { SearchCreditRatingWidget } from "@widgets/SearchCreditRatingWidget/SearchCreditRatingWidget";
import { SearchScoringWidget } from "@widgets/SearchScoringWidget/SearchScoringWidget";
import { SearchInfoWidget } from "@widgets/SearchInfoWidget/SearchInfoWidget";
import { SearchInnFlWidget } from "@widgets/SearchInnFlWidget/SearchInnFlWidget";
import { SearchInnUlWidget } from "@widgets/SearchInnUlWidget/SearchInnUlWidget";
import { SearchPhotoWidget } from "@widgets/SearchPhotoWidget/SearchPhotoWidget";

export const Report = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") as ReportType;

  switch (type) {
    case ReportType.SEARCH_FIO: {
      return <SearchFioWidget type={type} />;
    }

    case ReportType.SEARCH_PHONE: {
      return <SearchPhoneWidget type={type} />;
    }

    case ReportType.SEARCH_CREDIT_REPORT: {
      return <SearchCreditRatingWidget type={type} />;
    }

    case ReportType.SEARCH_INN_UL: {
      return <SearchInnUlWidget type={type} />;
    }

    case ReportType.SEARCH_INN_FL: {
      return <SearchInnFlWidget type={type} />;
    }

    case ReportType.SEARCH_EMAIL: {
      return <SearchEmailWidget type={type} />;
    }

    case ReportType.SEARCH_VIN: {
      return <SearchVinWidget type={type} />;
    }

    case ReportType.SEARCH_OGRN: {
      return <SearchOgrnWidget type={type} />;
    }

    case ReportType.SEARCH_SNILS: {
      return <SearchSnilsWidget type={type} />;
    }

    case ReportType.SEARCH_PASSPORT: {
      return <SearchPassportWidget type={type} />;
    }

    case ReportType.SEARCH_PHOTO_BY_IMAGE: {
      return <SearchPhotoWidget type={type} />;
    }

    case ReportType.SEARCH_ADDRESS: {
      return <SearchAddressWidget type={type} />;
    }

    case ReportType.SEARCH_GRN: {
      return <SearchGrnWidget type={type} />;
    }

    case ReportType.SEARCH_DRIVER_LICENSE: {
      return <SearchDriverLicenseWidget type={type} />;
    }

    case ReportType.SEARCH_INFO: {
      return <SearchInfoWidget type={type} />;
    }

    case ReportType.SEARCH_SCORING: {
      return <SearchScoringWidget type={type} />;
    }

    default: {
      return null;
    }
  }
};
