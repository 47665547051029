import { FC, useCallback } from "react";
import { Alert, Flex, Tabs, TabsProps } from "antd";
import {
  IoIosWarning,
  IoMdPerson,
  IoMdCar,
  IoMdBriefcase,
  IoMdInformationCircle,
} from "react-icons/io";
import { useMainTabSearchParam } from "@pages/Main/hooks/useMainTabSearchParam";
import { Text } from "src/ui/Text/Text";
import {
  MdPhoneIphone,
  MdMapsHomeWork,
  MdOutlinePlaylistAddCheck,
  MdOutlineSpeed,
  MdPortrait,
} from "react-icons/md";
import { BsPassportFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { fileToBase64 } from "src/utils/files/fileToBase64";
import { SearchRequestWithSopd } from "@api/report";
import s from "./MainTabs.module.scss";
import { MainTabDriverLicense } from "./tabs/MainTabDriverLicense/MainTabDriverLicense";
import { MainTabCreditRating } from "./tabs/MainTabCreditRating/MainTabCreditRating";
import { MainTabCompany } from "./tabs/MainTabCompany/MainTabCompany";
import { MainTabPhoto } from "./tabs/MainTabPhoto/MainTabPhoto";
import { MainTabScoring } from "./tabs/MainTabScoring/MainTabScoring";
import { MainTabEmail } from "./tabs/MainTabEmail/MainTabEmail";
import { MainTabAuto } from "./tabs/MainTabAuto/MainTabAuto";
import { MainTabPassport } from "./tabs/MainTabPassport/MainTabPassport";
import { MainTabInnOrSnils } from "./tabs/MainTabInnOrSnils/MainTabInnOrSnils";
import { MainTabPhone } from "./tabs/MainTabPhone/MainTabPhone";
import { MainTabAddress } from "./tabs/MainTabAddress/MainTabAddress";
import { MainTabFio } from "./tabs/MainTabFio/MainTabFio";
import { MainTab } from "../../constants";
import {
  MainTabFieldValues,
  MainTabOnSubmitProps,
  MainTabProps,
} from "./types";
import { MainTabInfo } from "./tabs/MainTabInfo/MainTabInfo";

const items = [
  // {
  //     key: MainTab.Dossier,
  //     Icon: HiMiniGlobeAmericas,
  //     label: 'Полное досье',
  //     TabComponent: MainTabDossier,
  //     hint: 'Проверка чувствительна к количеству заполненных полей. Чем больше информации вы предоставите, тем более точное досье получите.',
  // },
  {
    key: MainTab.Fio,
    Icon: IoMdPerson,
    label: "По ФИО",
    TabComponent: MainTabFio,
    hint: "Доступен поиск по неполным данным",
  },
  {
    key: MainTab.Phone,
    Icon: MdPhoneIphone,
    label: "По телефону",
    TabComponent: MainTabPhone,
    hint: "",
  },
  {
    key: MainTab.Address,
    Icon: MdMapsHomeWork,
    label: "По адресу",
    TabComponent: MainTabAddress,
    hint: "Используйте свитчер для переключения на поиск по кадастровому номеру или адресу",
  },
  {
    key: MainTab.InnOrSnils,
    Icon: MdMapsHomeWork,
    label: "По ИНН или СНИЛС",
    TabComponent: MainTabInnOrSnils,
    hint: "Используйте свитчер для переключения на поиск по номеру СНИЛС или по номеру ИНН",
  },
  {
    key: MainTab.Passport,
    Icon: BsPassportFill,
    label: "По паспорту",
    TabComponent: MainTabPassport,
    hint: "Заполните поле без пробелов и знаков препинания",
  },
  {
    key: MainTab.Auto,
    Icon: IoMdCar,
    label: "По номеру авто",
    TabComponent: MainTabAuto,
    hint: "Используйте переключатель для поиска по номеру VIN или номеру авто",
  },
  {
    key: MainTab.DriverLicense,
    Icon: MdMapsHomeWork,
    label: "По ВУ",
    TabComponent: MainTabDriverLicense,
    hint: "Введите номер без пробелов",
  },
  {
    key: MainTab.Email,
    Icon: IoMail,
    label: "По Email",
    TabComponent: MainTabEmail,
    hint: "",
  },
  {
    key: MainTab.Company,
    Icon: IoMdBriefcase,
    label: "По Юридическим лицам",
    TabComponent: MainTabCompany,
    hint: "Используйте переключатель для поиска по номеру ОГРН или номеру ИНН для юридического лица",
  },
  {
    key: MainTab.Photo,
    Icon: MdPortrait,
    label: "По фото",
    TabComponent: MainTabPhoto,
    hint: "",
  },
  {
    key: MainTab.CreditRating,
    Icon: MdOutlinePlaylistAddCheck,
    label: "Кредитный рейтинг",
    TabComponent: MainTabCreditRating,
    hint: "",
  },
  {
    key: MainTab.Scoring,
    Icon: MdOutlineSpeed,
    label: "Скоринг",
    TabComponent: MainTabScoring,
    hint: "",
  },
  {
    key: MainTab.Info,
    Icon: IoMdInformationCircle,
    label: "По ключевому слову",
    TabComponent: MainTabInfo,
    hint: "",
  },
];

interface TabWrapperProps {
  hint: string;
  TabComponent: FC<MainTabProps>;
}

const TabWrapper: FC<TabWrapperProps> = ({ hint, TabComponent }) => {
  const [api] = useNotification();

  const onSubmit = useCallback(
    async <T extends MainTabFieldValues>({
      requestData,
      search,
      withSopd,
    }: MainTabOnSubmitProps<T>) => {
      const data: SearchRequestWithSopd & T = {
        ...requestData,
        sopd: undefined,
        sopdFileList: undefined,
      };

      if (withSopd) {
        const sopdFile: File | undefined =
          requestData.sopdFileList?.[0].originFileObj;
        data.sopd = sopdFile
          ? await fileToBase64(sopdFile).then((b) =>
              b?.replace(/^data:application\/pdf;base64,/, ""),
            )
          : undefined;
      }

      search(data)
        .then(() => {
          api.success({
            message: "Запрос успешно создан!",
          });
        })
        .catch((error: unknown) => {
          if (handleBackendErrors({ api, error })) return;

          api.error({
            message: "Произошла непредвиденная ошибка!",
            description: JSON.stringify(String(error), null, 2),
          });
        });
    },
    [api],
  );

  return (
    <Flex vertical gap={24}>
      {!!hint && (
        <Alert
          message={
            <Flex gap={12} align="center">
              <Flex className={s.icon_wrapper} align="center" justify="center">
                <IoIosWarning size={20} />
              </Flex>
              {hint}
            </Flex>
          }
          type="info"
        />
      )}
      <TabComponent onSubmit={onSubmit} />
    </Flex>
  );
};

const tabItems: TabsProps["items"] = items.map(
  ({ key, label, Icon, TabComponent, hint }) => ({
    key,
    label: (
      <Flex gap={12}>
        <Text variant="body-2">{label}</Text>
        <Icon size={20} />
      </Flex>
    ),
    children: <TabWrapper TabComponent={TabComponent} hint={hint} />,
  }),
);

interface MainTabsProps {}

export const MainTabs: FC<MainTabsProps> = () => {
  const [tab, setTab] = useMainTabSearchParam();

  const onChange = useCallback(
    (newTab: string) => {
      setTab(newTab as MainTab);
    },
    [setTab],
  );

  return (
    <Tabs
      activeKey={tab || MainTab.Fio}
      items={tabItems}
      type="line"
      onChange={onChange}
      className={s.tabs}
    />
  );
};
