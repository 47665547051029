import { FC } from "react";
import { Permission as PermissionType } from "@api/groups";
import classNames from "classnames";
import { Text } from "src/ui/Text/Text";
import { Flex } from "antd";
import {
  mapPermissionToLabel,
  mapPermissionToType,
} from "src/utils/permissions/constants";
import styles from "./Permission.module.scss";

interface PermissionProps {
  permission: PermissionType;
}

export const Permission: FC<PermissionProps> = ({ permission }) => {
  const label = mapPermissionToLabel[permission];
  const type = mapPermissionToType[permission];

  return (
    <Flex
      className={classNames(
        styles.permission,
        styles[`permission_type_${type}`],
      )}
      justify="center"
      align="center"
    >
      <Text variant="body-2">{label}</Text>
    </Flex>
  );
};
