import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";

export interface LineChartProps {
  data: ChartData<"line", number[], string>;
  options?: ChartOptions<"line">;
}

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
);

export const LineChart = ({ data, options }: LineChartProps) => (
  <Line data={data} options={options} />
);
