import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { ModalMessageContext } from "./ModalMessageContext";
import { ModalView } from "./ModalView";
import { ModalPickedProps } from "./types";

export const ModalMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<ModalPickedProps>();

  const openModal = useCallback((newModalProps: ModalPickedProps) => {
    setIsModalOpen(true);
    setModalProps(newModalProps);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setModalProps(undefined);
  }, []);

  const value = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal],
  );

  return (
    <ModalMessageContext.Provider value={value}>
      {children}
      <ModalView
        isModalOpen={isModalOpen}
        onClose={closeModal}
        {...modalProps}
        closable={!!modalProps?.closable}
      />
    </ModalMessageContext.Provider>
  );
};
