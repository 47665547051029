import { FC, useState } from "react";

import { Button, Input } from "antd";
import { SearchResponse, useSearchOtherFields } from "@api/search";
import s from "./OtherFields.module.scss";
import { Lists } from "../Lists/Lists";

export const OtherFields: FC = () => {
  const [info, setInfo] = useState("");
  const [inn, setInn] = useState("");
  const [passport, setPassport] = useState("");
  const [snils, setSnils] = useState("");
  const [grn, setGrn] = useState("");
  const [searchData, setSearchData] = useState<SearchResponse | null>(null);
  const { mutateAsync: searchOtherFields } = useSearchOtherFields();

  const search = async () => {
    const data = await searchOtherFields({
      info,
      inn,
      passport,
      snils,
      grn,
    });
    setSearchData(data ?? null);
  };
  return (
    <div className={s.wrapper}>
      <div className={s.redact_panel}>
        <Input
          required
          value={info}
          placeholder="E-mail"
          onChange={(e) => {
            setInfo(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={inn}
          placeholder="ИНН"
          onChange={(e) => {
            setInn(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={passport}
          placeholder="Паспорт"
          onChange={(e) => {
            setPassport(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={snils}
          placeholder="СНИЛС"
          onChange={(e) => {
            setSnils(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={grn}
          placeholder="ГРЗ"
          onChange={(e) => {
            setGrn(e.currentTarget.value);
          }}
        />
        <Button onClick={search}>Поиск</Button>
      </div>
      {searchData && <Lists lists={searchData} />}
    </div>
  );
};
