import { FC, useCallback, useMemo } from "react";
import { Flex, Image, Upload } from "antd";
import { Text } from "src/ui/Text/Text";
import { CurrentUser, EditMemberRequest, useUsersEdit } from "@api/groups";
import { FaPen } from "react-icons/fa";
import { Button } from "src/ui/Button/Button";
import { IoClose } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";
import { Input } from "src/ui/Input/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationMessage } from "src/utils/constants";
import * as yup from "yup";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { usePassword } from "src/app/layouts/MainLayout/components/PasswordContext";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { useBooleanState } from "src/hooks/useBooleanState";
import cn from "classnames";
import { useUsersProfilePictureRetrieveQuery } from "@api/groups/mutations/useUsersProfilePictureRetrieveQuery";
import { useNotification } from "src/hooks/useNotification";
import { useUsersProfilePictureMutation } from "@api/groups/mutations/useUsersProfilePictureMutation";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { FaCircleUser } from "react-icons/fa6";
import s from "./UserCard.module.scss";

const schema = yup
  .object<EditMemberRequest>()
  .shape({
    user_id: yup.number().required(validationMessage.Required),
    name: yup.string().required(validationMessage.Required),
    // password: yup.string().required(validationMessage.Required),
    expires_at: yup.string().required(validationMessage.Required),
  })
  .required();

interface UserCardViewProps {
  user: CurrentUser;
  photo?: string;
}

const UserCardView: FC<UserCardViewProps> = ({ user, photo }) => {
  const { login, name } = user;

  return (
    <Flex gap={12}>
      {photo ? (
        <Image
          rootClassName={s.card__avatar}
          preview={false}
          src={photo}
          alt={`${user.id} avatar`}
        />
      ) : (
        <FaCircleUser className={s.card__icon} />
      )}
      <Flex vertical gap={8}>
        <Text variant="subheader-4">{login}</Text>
        <Text variant="body-5">{name}</Text>
      </Flex>
    </Flex>
  );
};

interface UserCardEditProps {
  user: CurrentUser;
  photo?: string;
  onView: () => void;
}

const UserCardEdit: FC<UserCardEditProps> = ({ user, photo, onView }) => {
  const { name, id: user_id, expires_at } = user;
  const { password } = usePassword();
  const { control, handleSubmit } = useForm<EditMemberRequest>({
    defaultValues: {
      user_id,
      name,
      expires_at,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { openModal, closeModal } = useModalMessage();
  const { mutateAsync: editUser } = useUsersEdit();
  const { mutateAsync: editPhoto } = useUsersProfilePictureMutation();

  const [api] = useNotification();

  const onChangeUser = useCallback(
    (data: EditMemberRequest) => {
      editUser(data)
        .then(() => {
          onView();

          api.success({
            message: "Запрос успешно создан!",
          });
        })
        .catch((error) => {
          if (handleBackendErrors({ api, error })) return;

          api.error({
            message: "Произошла непредвиденная ошибка!",
            description: JSON.stringify(String(error), null, 2),
          });
        });
    },
    [editUser, onView, api],
  );

  const onSubmit = useCallback(
    (data: EditMemberRequest) => {
      if (password) {
        onChangeUser({ ...data, password });
      } else {
        openModal({
          children: (
            <AuthForm
              onSuccess={(loginData) => {
                if (loginData && "password" in loginData) {
                  onChangeUser({ ...data, password: loginData.password });
                }
                closeModal();
              }}
              onClose={closeModal}
            />
          ),
          maskClosable: false,
          closable: false,
        });
      }
    },
    [onChangeUser, openModal, closeModal, password],
  );

  const beforeUpload = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", String(user.id));

      editPhoto(formData)
        .then(() => {
          api.success({
            message: "Запрос успешно создан!",
          });
        })
        .catch((error) => {
          if (handleBackendErrors({ api, error })) return;

          api.error({
            message: "Произошла непредвиденная ошибка!",
            description: JSON.stringify(String(error), null, 2),
          });
        });

      return false; // Prevent default upload behavior
    },
    [user, api, editPhoto],
  );

  const uploadProps = useMemo(
    () => ({
      beforeUpload,
      showUploadList: false,
      accept: "image/png, image/jpeg",
    }),
    [beforeUpload],
  );

  return (
    <form className={s.card_edit} onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={12} vertical>
        <Flex gap={16} align="center">
          <Upload {...uploadProps}>
            {photo ? (
              <Image
                rootClassName={s.card__avatar}
                preview={false}
                src={photo}
                alt={`${user.id} avatar`}
              />
            ) : (
              <FaCircleUser className={s.card__icon} />
            )}
          </Upload>
          <Upload {...uploadProps}>
            <Button type="text">
              <Text variant="body-5">Заменить</Text>
            </Button>
          </Upload>
          <Button type="text">
            <Text variant="body-5" color="darkGray">
              Удалить
            </Text>
          </Button>
        </Flex>
        {/* <Flex vertical gap={16}>
                <Text variant='subheader-4'>Логин</Text>
                <Controller
                    name="login"
                    control={control}
                    render={({ field, fieldState }) => (
                        <Input
                            className={s.input}
                            {...field}
                            placeholder="Логин"
                            // required
                            disabled
                            validate={fieldState}
                        />
                    )}
                />
            </Flex> */}
        <Flex vertical gap={16}>
          <Text variant="subheader-4">Имя</Text>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                {...field}
                placeholder="Имя"
                required
                validate={fieldState}
              />
            )}
          />
        </Flex>
        <Button size="small" htmlType="submit">
          Сохранить
        </Button>
      </Flex>
    </form>
  );
};

interface UserCardProps {
  user: CurrentUser;
  editable?: boolean;
  type: "flat" | "border-box";
  size: "s" | "m";
}

export const UserCard: FC<UserCardProps> = ({ user, editable, type, size }) => {
  const { state: isEdit, toggle } = useBooleanState(false);

  const { data: photo } = useUsersProfilePictureRetrieveQuery({
    user_id: user.id,
  });

  return (
    <Flex
      className={cn(s.card, s[`card_type_${type}`], s[`card_size_${size}`])}
    >
      {editable ? (
        <Button
          type="flat"
          pin="round"
          onClick={toggle}
          className={s.card_button}
        >
          {isEdit ? <IoClose /> : <FaPen />}
        </Button>
      ) : null}
      {isEdit ? (
        <UserCardEdit user={user} onView={toggle} photo={photo} />
      ) : (
        <UserCardView user={user} photo={photo} />
      )}
    </Flex>
  );
};
