import React from "react";
import { Text } from "src/ui/Text/Text";
import { PhoneBookItem } from "@api/report";
import s from "./PhoneBook.module.scss";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";

export type PhoneBookProps = {
  phoneBook?: PhoneBookItem[];
} & RetrieveCardCommonProps;

export const PhoneBook = ({ phoneBook, status }: PhoneBookProps) => (
  <RetrieveCard title="Телефонная книга" status={status}>
    <div className={s.container}>
      <Text color="darkGray" variant="subheader-2">
        Записан
      </Text>
      <Text color="darkGray" variant="subheader-2">
        Номер телефонной книжки
      </Text>

      {phoneBook?.map((phone: PhoneBookItem) => (
        <>
          <Text variant="header-1">#{phone.name}</Text>
          <Text variant="header-1">{phone.phone}</Text>
        </>
      ))}
    </div>
  </RetrieveCard>
);
