import { YMaps, Map } from "@pbe/react-yandex-maps";
import { memo } from "react";

export type Point = [number, number];

export interface YandexMapProps {
  center?: Point;
}

export const YandexMap = memo(({ center }: YandexMapProps) => (
  <YMaps>
    <Map defaultState={{ center, zoom: 9 }} width="100%" height="400px" />
  </YMaps>
));
