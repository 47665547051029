import { ReportsCommonRetrieveResponse } from "@api/report/retrieve/responses/commonRetrieve";
import { Photo } from "@api/report/retrieve/photo";
import { Reports } from "./reports";
import { isOpenSources, OpenSourcesModel } from "./openSources";
import { CrossingBorders } from "./crossingBorders";
import { isReportsWithOpenSource } from "./responses";

export type ReportsFullRetrieve = {
  open_sources?: OpenSourcesModel;
  photo?: Photo;
  crossing_borders?: CrossingBorders;
} & ReportsCommonRetrieveResponse;

export const normalizeReportsFullRetrieve = (
  data: Reports,
): ReportsFullRetrieve => {
  const open_sources = undefined;

  if (
    data &&
    isReportsWithOpenSource(data) &&
    data?.open_sources &&
    isOpenSources(data?.open_sources)
  )
    return { ...data, open_sources: data?.open_sources?.iosphere };

  if (isReportsWithOpenSource(data))
    return { ...data, open_sources: data?.open_sources };

  return { ...data, open_sources };
};
