import { NotificationInstance } from "antd/es/notification/interface";
import axios from "axios";

interface HandleBackendErrorsProps {
  api: NotificationInstance;
  error: unknown;
}

export const handleBackendErrors = ({
  api,
  error,
}: HandleBackendErrorsProps): boolean => {
  if (
    axios.isAxiosError(error) &&
    (error.code === "ERR_NETWORK" ||
      (error.response?.status &&
        error.response.status >= 500 &&
        error.response.status < 600))
  ) {
    api.error({
      message: "Произошла непредвиденная ошибка!",
      description: "Сообщите об этом в поддержку",
    });

    return true;
  }

  return false;
};
