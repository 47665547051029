import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { users_edit } from "../queries";
import { EditMemberRequest } from "../types";

export const useUsersEdit = () =>
  useMutation({
    mutationKey: ["currentUser"],
    mutationFn: (data: EditMemberRequest) => users_edit(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["currentUser"] }),
  });
