import { useState, Dispatch, SetStateAction } from "react";

interface UsePaginationParams<TData> {
  data: TData[];
  limit: number;
  initialCursor?: number;
  onChange?: (page: number) => void;
}

interface UsePagination<TData> {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  dataSegment: TData[];
}

export const usePagination = <TData>({
  data,
  limit,
  initialCursor = 1,
}: UsePaginationParams<TData>): UsePagination<TData> => {
  const [page, setPage] = useState<number>(initialCursor);

  const pageSize = data.length / limit;

  const dataSegment: TData[] = data.slice(
    limit * (page - 1),
    limit * (page - 1) + limit,
  );

  return {
    page,
    setPage,
    pageSize,
    dataSegment,
  };
};
