import { FC } from "react";
import Sider from "antd/es/layout/Sider";
import s from "./MainLayoutAside.module.scss";

import { MainLayoutAsideMenu } from "./components/MainLayoutAsideMenu/MainLayoutAsideMenu";

interface MainLayoutAsideProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

export const MainLayoutAside: FC<MainLayoutAsideProps> = ({
  collapsed,
  onCollapse,
}) => (
  <Sider
    className={s.sider}
    defaultCollapsed
    width={230}
    collapsedWidth={80}
    collapsed={collapsed}
    onCollapse={onCollapse}
  >
    <MainLayoutAsideMenu collapsed={collapsed} />
  </Sider>
);
