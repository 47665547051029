export enum SearchCriteria {
  SEARCH_FIO = "SEARCH_FIO",
  SEARCH_PHONE = "SEARCH_PHONE",
  SEARCH_CREDIT_REPORT = "SEARCH_CREDIT_REPORT",
  SEARCH_INN_UL = "SEARCH_INN_UL",
  SEARCH_INN_FL = "SEARCH_INN_FL",
  SEARCH_EMAIL = "SEARCH_EMAIL",
  SEARCH_VIN = "SEARCH_VIN",
  SEARCH_OGRN = "SEARCH_OGRN",
  SEARCH_SNILS = "SEARCH_SNILS",
  SEARCH_PASSPORT = "SEARCH_PASSPORT",
  SEARCH_ADDRESS = "SEARCH_ADDRESS",
  SEARCH_DRIVER_LICENSE = "SEARCH_DRIVER_LICENSE",
  SEARCH_GRN = "SEARCH_GRN",
  SEARCH_INFO = "SEARCH_INFO",
  SEARCH_PHOTO_BY_FIO = "SEARCH_PHOTO_BY_FIO",
  SEARCH_PHOTO_BY_IMAGE = "SEARCH_PHOTO_BY_IMAGE",
  SEARCH_SCORING = "SEARCH_SCORING",
}
