export interface PluralizeParams {
  count: number;
  oneLabel: string;
  fromTwoTillFourLabel: string;
  label: string;
}

export const pluralize = ({
  count,
  oneLabel,
  fromTwoTillFourLabel,
  label,
}: PluralizeParams) => {
  if (count % 10 === 1) return oneLabel;

  if (count % 10 >= 2 && count % 10 <= 4) return fromTwoTillFourLabel;

  return label;
};
