import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    required: "Обязательное поле",
  },
  string: {
    min: (value) => `Минимальная длина поля: ${value.min}`,
    max: (value) => `Максимальная длина поля: ${value.max}`,
  },
});
