import { FC } from "react";

import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import s from "./CurrentUser.module.scss";

export const CurrentUser: FC = () => {
  const { data: user } = useCurrentUserQuery();
  return (
    <div className={s.wrapper}>
      <div>
        Имя:
        {user?.name}
      </div>
      <div>
        Логин:
        {user?.login}
      </div>
      <div>Группы:</div>
      <div className={s.groups}>
        {user?.groups.map((group) => (
          <div key={group.group_id}>
            <div>
              Группа:
              {group.name}
            </div>
            <div>Права доступа:</div>
            {group.permissions.map((value) => (
              <div key={value}>{value}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
