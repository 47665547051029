import { useMemo, useRef } from "react";
import { Text } from "src/ui/Text/Text";
import { ChartOptions } from "chart.js";
import { getDatasets } from "@pages/Analytics/components/CheckPaper/getDatasets";
import { StatisticsResponse } from "@api/analytics/types";
import { Interval } from "@pages/Analytics/types";
import { intervals, options } from "../../constants";
import { LineChart } from "./LineChart/LineChart";
import s from "./CheckPaper.module.scss";

interface CheckPaperProps {
  statisticsAll?: StatisticsResponse;
  intervalValue: Interval;
}

export const CheckPaper = ({
  statisticsAll,
  intervalValue,
}: CheckPaperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const lineChartOptions = useMemo<ChartOptions<"line">>(() => options, []);

  const data = {
    labels: statisticsAll?.labels.map((label) =>
      new Date(label).toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    ),
    datasets: getDatasets({
      datasets: statisticsAll?.datasets,
      height: ref.current?.clientHeight || 0,
    }),
  };

  return (
    <div className={s.container} ref={ref}>
      <Text variant="header-6" className={s.container__title}>
        Проверки
      </Text>
      <div className={s.container__total}>
        <div className={s.container__total__item}>
          <div className={s.container__total__item__title}>
            <div className={s.container__total__item__title__marker} />
            <Text variant="body-4">Все проверки</Text>
          </div>
          <Text variant="header-7">
            {statisticsAll?.datasets[0]?.data?.reduce(
              (dataItem, acc) => acc + dataItem,
              0,
            )}
          </Text>
          <Text variant="body-1" color="darkGray">
            {intervals[intervalValue]}
          </Text>
        </div>
        <LineChart data={data} options={lineChartOptions} />
      </div>
    </div>
  );
};
