import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { FC } from "react";
import s from "./Photo.module.scss";
import { ByFio } from "./components/ByFio/ByFio";
import { ByPhoto } from "./components/ByPhoto/ByPhoto";

const items: TabsProps["items"] = [
  {
    key: "ByFio",
    label: "По ФИО",
    children: <ByFio />,
  },
  {
    key: "ByPhoto",
    label: "По фото",
    children: <ByPhoto />,
  },
];

export const Photo: FC = () => (
  <div className={s.wrapper}>
    <Tabs defaultActiveKey="Groups" items={items} className={s.tab_body} />
  </div>
);
