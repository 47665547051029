import { FC } from "react";
import { Flex } from "antd";
import { useReportsRetrieveAll } from "@api/report";
import { mapReportTypeToTitleGetter } from "src/utils/reports/mapReportTypeToTitleGetter";
import { Text } from "src/ui/Text/Text";
import { generatePath } from "react-router";
import { AppRoute } from "src/app/routes/constants";
import { Link } from "react-router-dom";
import { Button } from "src/ui/Button/Button";
import { SearchItem } from "./components/SearchItem/SearchItem";
import s from "./MainLayoutHeaderSearch.module.scss";

const REPORTS_SHOW_ELEMENTS_COUNT = 3;

interface MainLayoutHeaderSearchProps {
  query: string;
  onClose: () => void;
}

export const MainLayoutHeaderSearch: FC<MainLayoutHeaderSearchProps> = ({
  query,
  onClose,
}) => {
  const { data: reports } = useReportsRetrieveAll(
    {
      request_contains: query,
      limit: REPORTS_SHOW_ELEMENTS_COUNT,
    },
    { enabled: Boolean(query) },
  );

  return (
    <Flex className={s.search} vertical>
      {reports?.length ? (
        <Flex vertical>
          <Text className={s.search__title} variant="subheader-4">
            История проверок
          </Text>
          {reports.map(({ type, request, id }) => (
            <SearchItem
              title={mapReportTypeToTitleGetter[type](request)}
              onClick={onClose}
              href={`${generatePath(AppRoute.Report, { reportId: id })}?type=${type}`}
            />
          ))}
        </Flex>
      ) : null}
      <Flex justify="center" className={s.search__more}>
        <Link
          to={generatePath(AppRoute.Search, { query })}
          className={s.search__more_link}
        >
          <Button fullWidth size="small" type="flat" onClick={onClose}>
            <Text color="green">Показать все</Text>
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};
