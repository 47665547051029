import { useParams } from "react-router";
import {
  useReportsAddressRetrieve,
  normalizeReportsFullRetrieve,
} from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";

export const SearchAddressWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: addressReport, isLoading } = useReportsAddressRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {addressReport && (
        <SearchFull
          report={normalizeReportsFullRetrieve(addressReport)}
          type={type}
        />
      )}
    </Skeleton>
  );
};
