import { useState, FC } from "react";
import { Modal, Upload, UploadProps, Flex } from "antd";
import { Button } from "src/ui/Button/Button";
import { IoMdDocument } from "react-icons/io";
import { Text } from "src/ui/Text/Text";
import styles from "./MultiFioModal.module.scss";

interface MultiFioModalProps {
  open: boolean;
  onClose?: () => void;
}

export const MultiFioModal: FC<MultiFioModalProps> = ({ open, onClose }) => {
  const [fileList, setFileList] = useState<UploadProps["fileList"]>([]);

  const handleOk = () => {
    onClose?.();
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <Modal
      title={<Text variant="header-6">Загрузка СОПД</Text>}
      open={open}
      className={styles.modal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="clear"
          type="flat"
          size="small"
          onClick={() => {
            setFileList([]);
          }}
        >
          Очистить
        </Button>,
        <Button key="submit" type="primary" size="small" onClick={handleOk}>
          Применить
        </Button>,
      ]}
    >
      <Flex vertical gap={12}>
        <Text color="darkGray">
          Загрузите файл в формате PDF подтверждающий согласие на обработку
          персональных данных
        </Text>
        <Upload.Dragger
          fileList={fileList}
          onChange={handleUpload}
          beforeUpload={() => false}
          accept=".pdf"
          multiple={false}
        >
          <Flex align="center" vertical gap={24}>
            <Flex align="center" vertical gap={16}>
              <IoMdDocument size={48} className={styles.icon} />
              <Text color="aqua" variant="body-6">
                Загрузите или перетащите файл в формате: PDF
              </Text>
            </Flex>
            <Button type="flat">
              <Text variant="body-4" color="green">
                Выбрать файл
              </Text>
            </Button>
          </Flex>
        </Upload.Dragger>
      </Flex>
    </Modal>
  );
};
