import { SearchCriteria } from "./types";

export const searchCriteriaDescriptions: Record<SearchCriteria, string> = {
  [SearchCriteria.SEARCH_FIO]: "По ФИО",
  [SearchCriteria.SEARCH_PHONE]: "По телефону",
  [SearchCriteria.SEARCH_CREDIT_REPORT]: "По паспорту",
  [SearchCriteria.SEARCH_PASSPORT]: "По паспорту",
  [SearchCriteria.SEARCH_INN_UL]: "По юридическим лицам",
  [SearchCriteria.SEARCH_OGRN]: "По юридическим лицам",
  [SearchCriteria.SEARCH_INN_FL]: "По ИНН ФЛ или СНИЛС",
  [SearchCriteria.SEARCH_SNILS]: "По ИНН ФЛ или Снилс",
  [SearchCriteria.SEARCH_EMAIL]: "По почте",
  [SearchCriteria.SEARCH_VIN]: "По авто",
  [SearchCriteria.SEARCH_GRN]: "По авто",
  [SearchCriteria.SEARCH_ADDRESS]: "По адресу",
  [SearchCriteria.SEARCH_DRIVER_LICENSE]: "По ВУ",
  [SearchCriteria.SEARCH_INFO]: "Сквозной Поиск",
  [SearchCriteria.SEARCH_PHOTO_BY_FIO]: "Поиск фото по ФИО",
  [SearchCriteria.SEARCH_PHOTO_BY_IMAGE]: "По фото",
  [SearchCriteria.SEARCH_SCORING]: "Скоринг",
};

export const searchCriteriaColors: Record<SearchCriteria, string> = {
  [SearchCriteria.SEARCH_FIO]: "#32ABAF",
  [SearchCriteria.SEARCH_PHONE]: "#252F41",
  [SearchCriteria.SEARCH_CREDIT_REPORT]: "#1B1D30",
  [SearchCriteria.SEARCH_INN_UL]: "#8441B1",
  [SearchCriteria.SEARCH_INN_FL]: "#E80981",
  [SearchCriteria.SEARCH_EMAIL]: "#5653CD",
  [SearchCriteria.SEARCH_VIN]: "#35C232",
  [SearchCriteria.SEARCH_OGRN]: "#8441B1",
  [SearchCriteria.SEARCH_SNILS]: "#E80981",
  [SearchCriteria.SEARCH_PASSPORT]: "#1B1D30",
  [SearchCriteria.SEARCH_ADDRESS]: "#C4FD8C",
  [SearchCriteria.SEARCH_DRIVER_LICENSE]: "#201E82",
  [SearchCriteria.SEARCH_GRN]: "#35C232",
  [SearchCriteria.SEARCH_INFO]: "#47B9FF",
  [SearchCriteria.SEARCH_PHOTO_BY_FIO]: "#69FF41",
  [SearchCriteria.SEARCH_PHOTO_BY_IMAGE]: "#AED0A5",
  [SearchCriteria.SEARCH_SCORING]: "#8DA896",
};
