import { useParams } from "react-router";
import {
  normalizeReportsFullRetrieve,
  useReportsInnFlRetrieve,
} from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";

export const SearchInnFlWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: innFlReport, isLoading } = useReportsInnFlRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {innFlReport && (
        <SearchFull
          report={normalizeReportsFullRetrieve(innFlReport)}
          type={type}
        />
      )}
    </Skeleton>
  );
};
