import { FC, useState } from "react";

import { Button, Input } from "antd";
import { SearchResponse, useSearchPhone } from "@api/search";
import s from "./Phone.module.scss";
import { Lists } from "../Lists/Lists";

export const Phone: FC = () => {
  const [phone, setPhone] = useState("");
  const [searchData, setSearchData] = useState<SearchResponse | null>(null);
  const { mutateAsync: searchPhone } = useSearchPhone();

  const search = async () => {
    const data = await searchPhone({
      phone,
    });
    setSearchData(data ?? null);
  };
  return (
    <div className={s.wrapper}>
      <div className={s.redact_panel}>
        <Input
          required
          value={phone}
          placeholder="Номер телефона"
          onChange={(e) => {
            setPhone(e.currentTarget.value);
          }}
        />
        <Button onClick={search}>Поиск</Button>
      </div>
      {searchData && <Lists lists={searchData} />}
    </div>
  );
};
