import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
// import { AccessesApi } from './components/AccessesApi/AccessesApi';
import { Text } from "src/ui/Text/Text";
import { BreadCrumps } from "src/components/BreadCrumps/BreadCrumps";
import { LuChevronLeft } from "react-icons/lu";
import { AppRoute } from "src/app/routes/constants";
import { Button } from "src/ui/Button/Button";
import { Link, useParams } from "react-router-dom";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { AccessesList } from "src/components/AccessesList/AccessesList";
import { useGroup } from "@api/groups/queries/useGroup";
import s from "./Accesses.module.scss";

export const Accesses = () => {
  const { data: user } = useCurrentUserQuery();
  const { group_id } = useParams();
  const { data: group } = useGroup(
    { group_id: Number(group_id) },
    { enabled: Boolean(group_id) },
  );

  if (!user) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Flex vertical gap={8}>
        <Link className={s.link} to={AppRoute.Lk}>
          <Flex gap={4} align="center">
            <Button type="flat" pin="round">
              <LuChevronLeft size={24} />
            </Button>
            <Text variant="header-8">Доступы</Text>
          </Flex>
        </Link>
        <BreadCrumps
          mapPathToTitle={{ [AppRoute.Accesses]: "Доступы" }}
          paths={[AppRoute.Accesses]}
        />
      </Flex>
      <Block>
        <AccessesList withPagination limit={10} group={group} />
      </Block>
    </Flex>
  );
};
