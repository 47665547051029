import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export const baseURL = "/";

export const axiosConfig: AxiosRequestConfig = {
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};

export const client = (() => {
  const instance: AxiosInstance = axios.create(axiosConfig);

  // Add a response interceptor
  instance.interceptors.response.use(
    <T>(response: AxiosResponse<T>): T => response.data,
  );

  return instance;
})();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Check if the error response status is in the 4xx range
        if (
          axios.isAxiosError(error) &&
          (error.code === "ERR_NETWORK" ||
            (error.response?.status &&
              error.response.status >= 400 &&
              error.response.status < 500))
        ) {
          return false; // Do not retry on client errors
        }
        return failureCount < 3; // Adjust the retry logic as needed
      },
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});
