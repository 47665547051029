export enum AppRoute {
  Admin = "admin",
  Lk = "/lk",
  History = "/history",
  User = "/lk/user",
  Search = "/lk/search/:query",
  Report = "/report/:reportId",
  Analytics = "/lk/group/:group_id/analytics/user/:user_id",
  Accesses = "/lk/group/:group_id/accesses",
  AccessesAdd = "/lk/group/:group_id/accesses/add",
  AccessesEdit = "/lk/group/:group_id/accesses/edit/user/:user_id",
  Root = "/",
}
