import { useQuery } from "@tanstack/react-query";

import { two_factor_generate } from "../queries";

export const useTwoFactorGenerate = () => {
  const response = useQuery({
    queryKey: ["twoFactorGenerate"],
    queryFn: two_factor_generate,
  });
  return {
    ...response,
    data: response.data,
  };
};
