import { useCallback } from "react";

import {
  IndicatorIconEnum,
  IndicatorModel,
} from "src/components/Indicator/Indicator";
import { CreditRating } from "@api/report";
import { Description } from "@entities/RetrieveCreditRating/components/CreditRatingResult/components/Description/Description";
import { Common } from "../../../../components/Common/Common";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";

export type CreditRatingResultProps = {
  creditRating?: CreditRating;
} & RetrieveCardCommonProps;

export const CreditRatingResult = ({
  creditRating,
  status,
}: CreditRatingResultProps) => {
  const getIndicatorModel = useCallback((marker?: number): IndicatorModel => {
    if (!marker)
      return {
        text: "дефолт",
        indicatorIcon: IndicatorIconEnum.cancel,
        color: "red",
      };

    if (marker >= 961)
      return {
        text: "Отличная оценка",
        indicatorIcon: IndicatorIconEnum.check,
        color: "green",
      };

    if (marker <= 960 && marker >= 801)
      return {
        text: "стандартный балл",
        indicatorIcon: IndicatorIconEnum.check,
        color: "green",
      };

    if (marker <= 800 && marker >= 721)
      return {
        text: "средняя оценка",
        indicatorIcon: IndicatorIconEnum.normal,
        color: "yellow",
      };

    if (marker <= 720 && marker >= 641)
      return {
        text: "ниже среднего",
        indicatorIcon: IndicatorIconEnum.normal,
        color: "yellow",
      };

    if (marker <= 640 && marker >= 300)
      return {
        text: "низкая оценка",
        indicatorIcon: IndicatorIconEnum.cancel,
        color: "red",
      };

    return {
      text: "дефолт",
      indicatorIcon: IndicatorIconEnum.cancel,
      color: "red",
    };
  }, []);

  return (
    <RetrieveCard title="Результаты кредитного рейтинга" status={status}>
      <>
        <Description />
        <Common
          marker={creditRating?.rating}
          getIndicatorModel={getIndicatorModel}
        />
      </>
    </RetrieveCard>
  );
};
