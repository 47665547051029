import { ChartOptions, TooltipItem } from "chart.js";
import { Interval } from "@pages/Analytics/types";

export const options: ChartOptions<"line"> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: (tooltipItem: TooltipItem<"line">) =>
          `${String(tooltipItem.raw)} запросов`,
        title: () => "", // Prevents displaying the title
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
    },
  },
};

export const intervalsOrder: Interval[] = [
  Interval.all,
  Interval.last30Days,
  Interval.last90Days,
  Interval.custom,
];

export const intervals: Record<Interval, string> = {
  [Interval.all]: "За все время",
  [Interval.last30Days]: "Последние 30 дней",
  [Interval.last90Days]: "Последние 90 дней",
  [Interval.custom]: "Указанный период",
};
