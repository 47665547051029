import { FC } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Button } from "src/ui/Button/Button";
import { CurrentUser, Group } from "@api/groups";
import { UserCard } from "src/components/UserCard/UserCard";
// import s from './UserSettingsMainInfo.module.scss';

interface UserSettingsMainInfoProps {
  user: CurrentUser;
  group?: Group;
}

export const UserSettingsMainInfo: FC<UserSettingsMainInfoProps> = ({
  user,
  group,
}) => {
  const isFillEnabled = false;
  let { name, contract_number, contract_date } = group || {};
  name = name && typeof name === "string" ? name : "-";
  contract_number =
    contract_number && typeof contract_number === "string"
      ? contract_number
      : "-";
  contract_date =
    contract_date && typeof contract_date === "string" ? contract_date : "-";

  return (
    <Flex vertical gap={32}>
      <Flex justify="space-between">
        <Text variant="header-6">Основная информация</Text>
        {isFillEnabled ? <Button size="small">Пополнить баланс</Button> : null}
      </Flex>
      <UserCard user={user} type="border-box" size="m" editable />
      {group ? (
        <Flex vertical gap={8}>
          <Flex vertical gap={8}>
            <Text color="darkGray" variant="body-5">
              Название компании
            </Text>
            <Text variant="subheader-3">{name}</Text>
          </Flex>
          <Flex vertical gap={8}>
            <Text color="darkGray" variant="body-5">
              Номер договора
            </Text>
            <Text variant="subheader-3">{contract_number}</Text>
          </Flex>
          <Flex vertical gap={8}>
            <Text color="darkGray" variant="body-5">
              Дата заключение договора
            </Text>
            <Text variant="subheader-3">{contract_date}</Text>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
