import React from "react";
import { Text } from "src/ui/Text/Text";
import s from "./Description.module.scss";

export const Description = () => (
  <div className={s.container}>
    <Text variant="label-3">Описание оценки</Text>
    <div className={s.container__table}>
      <Text variant="body-4" color="red">
        0 - 300
      </Text>
      <Text variant="body-4">
        Дефолт, кредитные предложения недоступны, ужасная кредитная история.
      </Text>

      <Text variant="body-4" color="red">
        300 - 640
      </Text>
      <Text variant="body-4">
        Очень низкая оценка, при которой шансов на банковский кредит практически
        нет. Финансирование придется искать в микрофинансовых организациях,
        кредитных потребительских кооперативах или ломбардах, плохая финансовая
        дисциплина.
      </Text>

      <Text variant="body-4" color="yellow">
        641 - 720
      </Text>
      <Text variant="body-4">
        Оценка ниже средней с высокой вероятностью отказа, либо получением
        дорогого кредита определенной категории (например, на товар) на
        небольшой срок, низкая финансовая дисциплина. данные Например Имя
      </Text>

      <Text variant="body-4" color="yellow">
        721 - 800
      </Text>
      <Text variant="body-4">
        Средняя оценка, при которой банк будет диктовать свои условия. Вероятно,
        снижение запрашиваемой суммы и повышенный процент по кредиту, нормальная
        финансовая дисциплина.
      </Text>

      <Text variant="body-4" color="green">
        801 - 960
      </Text>
      <Text variant="body-4">
        Стандартный балл для стандартных условий кредитования, хорошая
        финансовая дисциплина.
      </Text>

      <Text variant="body-4" color="green">
        961 - 1400
      </Text>
      <Text variant="body-4">
        Отличная оценка финансового рейтинга. С таким баллом можно самому
        выбирать банки, искать более выгодные предложения по кредитам и просить
        пониженную ставку, высокая финансовая дисциплина.
      </Text>
    </div>
  </div>
);
