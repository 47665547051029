import { ReactElement } from "react";
import { PieChart, Pie, Cell, Tooltip, PieProps, Legend } from "recharts";

export interface DataItem {
  name: string;
  value: number;
}

type PieChartComponentProps = {
  data: DataItem[];
  colors: string[];
  width?: number;
  height?: number;
  LegendContent?: ReactElement;
  layoutLegend?: "horizontal" | "vertical";
  alignLegend?: "left" | "center" | "right";
  verticalAlignLegend?: "top" | "middle" | "bottom";
} & PieProps;

export const PieChartComponent = ({
  data,
  colors,
  width,
  height,
  LegendContent,
  layoutLegend,
  alignLegend,
  verticalAlignLegend,
  cx,
  cy,
  labelLine,
  outerRadius,
  innerRadius,
  fill,
  dataKey,
}: PieChartComponentProps) => (
  <PieChart width={width} height={height}>
    <Pie
      data={data}
      cx={cx}
      cy={cy}
      labelLine={labelLine}
      outerRadius={outerRadius}
      innerRadius={innerRadius}
      fill={fill}
      dataKey={dataKey}
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${entry.name}`} fill={colors[index]} />
      ))}
    </Pie>
    <Tooltip />
    {LegendContent && (
      <Legend
        content={LegendContent}
        layout={layoutLegend}
        align={alignLegend}
        verticalAlign={verticalAlignLegend}
      />
    )}
  </PieChart>
);
