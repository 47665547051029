import { useParams } from "react-router";
import {
  normalizeReportsFullRetrieve,
  useReportsScoringRetrieve,
} from "@api/report";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { RetrieveScoring } from "@entities/RetrieveScoring/RetrieveScoring";

export const SearchScoringWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: scoringReport, isLoading } = useReportsScoringRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {scoringReport && (
        <RetrieveScoring
          report={normalizeReportsFullRetrieve(scoringReport)}
          type={type}
        />
      )}
    </Skeleton>
  );
};
