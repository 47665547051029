import { FC } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Link } from "react-router-dom";
import s from "./SearchItem.module.scss";

interface SearchItemProps {
  title: string;
  href: string;
  onClick: () => void;
}

export const SearchItem: FC<SearchItemProps> = ({ title, href, onClick }) => (
  <Link to={href} className={s.searchItem} onClick={onClick}>
    <Flex className={s.searchItem__content}>
      <Text variant="header-2">{title}</Text>
    </Flex>
  </Link>
);
