import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import {
  AllowedIpCreateRequest,
  AllowedIpDeleteRequest,
  AllowedIpSearchRequest,
} from "./types";
import { allowedIpCreate, allowedIpSearch } from "./queries";

enum AllowedIpQueryKey {
  Common = "Allowed-ip",
}

export const useAllowedIpCreateMutation = () =>
  useMutation({
    mutationKey: [AllowedIpQueryKey.Common],
    mutationFn: (data: AllowedIpCreateRequest) => allowedIpCreate(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [AllowedIpQueryKey.Common] }),
  });

export const useAllowedIpDeleteMutation = () =>
  useMutation({
    mutationKey: [AllowedIpQueryKey.Common],
    mutationFn: (data: AllowedIpDeleteRequest) => allowedIpCreate(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [AllowedIpQueryKey.Common] }),
  });

export const useAllowedIpSearchQuery = (data: AllowedIpSearchRequest) =>
  useQuery({
    queryKey: [AllowedIpQueryKey.Common],
    queryFn: () => allowedIpSearch(data),
  });
