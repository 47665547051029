import { FC, useCallback, useState } from "react";
import { Flex } from "antd";
import { Toggle } from "src/ui/Toggle/Toggle";
import { MainTabProps } from "../../types";
import { MainTabAddressNormal } from "./components/MainTabAddressNormal/MainTabAddressNormal";
import { MainTabAddressCadastral } from "./components/MainTabAddressCadastral/MainTabAddressCadastral";

type MainTabAddressProps = MainTabProps;

export const MainTabAddress: FC<MainTabAddressProps> = ({ onSubmit }) => {
  const [type, setType] = useState<"cadastral" | "normal">("normal");

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("cadastral");
    } else {
      setType("normal");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "normal" ? (
        <MainTabAddressNormal onSubmit={onSubmit} />
      ) : (
        <MainTabAddressCadastral onSubmit={onSubmit} />
      )}
      <Toggle
        value={type === "cadastral"}
        onChange={onTypeChange}
        title="По кадастровому номеру"
      />
    </Flex>
  );
};
