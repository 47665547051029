import {
  useMutation,
  useQueries,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { sessionsDelete, sessionsDeleteOther, sessionsSearch } from "./queries";
import {
  SessionsDeleteOtherRequest,
  SessionsDeleteRequest,
  SessionsSearchRequest,
} from "./types";

export const useSessionsDelete = () =>
  useMutation({
    mutationKey: ["sessions"],
    mutationFn: (data: SessionsDeleteRequest) => sessionsDelete(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["sessions"] }),
  });

export const useSessionsDeleteOther = () =>
  useMutation({
    mutationKey: ["sessions"],
    mutationFn: (data: SessionsDeleteOtherRequest) => sessionsDeleteOther(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["sessions"] }),
  });

export const useSessionsSearchQuery = (
  data: SessionsSearchRequest,
  { enabled }: Partial<UseQueryOptions>,
) =>
  useQuery({
    queryKey: ["sessions", data.group_id],
    queryFn: () => sessionsSearch(data),
    enabled,
  });

interface UseSessionsSearchAllQueryProps {
  groupIds: number[];
}

export const useSessionsSearchAllQuery = (
  { groupIds }: UseSessionsSearchAllQueryProps,
  { enabled }: Partial<UseQueryOptions>,
) =>
  useQueries({
    queries: groupIds.map((group_id) => ({
      queryKey: ["sessions", group_id],
      queryFn: () => sessionsSearch({ group_id }),
      enabled,
    })),
    combine: (results) => ({
      data: results.map((result) => result.data).flat(),
      isPending: results.some((result) => result.isPending),
      isLoading: results.some((result) => result.isLoading),
    }),
  });
