import { FC, useCallback, useState } from "react";
import { Flex } from "antd";
import { Toggle } from "src/ui/Toggle/Toggle";
import { MainTabProps } from "../../types";
import { MainTabInn } from "./components/MainTabInn/MainTabInn";
import { MainTabSnils } from "./components/MainTabSnils/MainTabSnils";

type MainTabInnOrSnilsProps = MainTabProps;

export const MainTabInnOrSnils: FC<MainTabInnOrSnilsProps> = ({ onSubmit }) => {
  const [type, setType] = useState<"snils" | "inn">("inn");

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("snils");
    } else {
      setType("inn");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "inn" ? (
        <MainTabInn onSubmit={onSubmit} />
      ) : (
        <MainTabSnils onSubmit={onSubmit} />
      )}
      <Toggle
        value={type === "snils"}
        onChange={onTypeChange}
        title="По номеру СНИЛС"
      />
    </Flex>
  );
};
