import { useMutation } from "@tanstack/react-query";
import { usePassword } from "src/app/layouts/MainLayout/components/PasswordContext";
import { queryClient } from "@api/client";
import { login } from "../queries";
import { LoginRequest } from "../types";

export const useLogin = () => {
  const { setPassword } = usePassword();

  return useMutation({
    mutationKey: ["login"],
    mutationFn: (data: LoginRequest) => login(data),
    onSuccess: async (_res, data) => {
      if (data.password) {
        setPassword(data.password);
      }
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ["currentUser"] }),
        queryClient.invalidateQueries({ queryKey: ["reports"] }),
        queryClient.invalidateQueries({ queryKey: ["profilePicture"] }),
      ]);
    },
  });
};
