import { FC, useCallback } from "react";

import { Alert, Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input } from "src/ui/Input/Input";
import { PiWarningBold } from "react-icons/pi";
import { Button } from "src/ui/Button/Button";
import * as yup from "yup";
import { useUsersChangePasswordMutation } from "@api/groups/mutations/useUsersChangePasswordMutation";
import { ChangePasswordRequest } from "@api/groups";
import { ReactComponent as Close } from "src/assets/close.svg";
import s from "./AuthFormChangePassword.module.scss";

const schema = yup
  .object()
  .shape({
    old_password: yup.string().required(),
    new_password: yup.string().required(),
  })
  .required();

const supportLink = "Oddyse-search@gmail.com";
const supportPhone = "+7 800 999 33 11";

interface AuthFormChangePasswordProps {
  onSuccess: () => void;
  onClose: () => void;
}

export const AuthFormChangePassword: FC<AuthFormChangePasswordProps> = ({
  onSuccess,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<ChangePasswordRequest>({
    defaultValues: {
      old_password: "",
      new_password: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { mutateAsync: changePassword, error } =
    useUsersChangePasswordMutation();

  const onSubmit = useCallback(
    (data: ChangePasswordRequest) => {
      changePassword(data)
        .then(() => {
          onSuccess();
        })
        .catch(() => {});
    },
    [onSuccess, changePassword],
  );

  return (
    <Flex component="section" vertical gap={40} className={s.form}>
      <Flex align="center" justify="space-between">
        <Text variant="header-6">Восстановление пароля</Text>
        <Button pin="round" type="flat" size="small" onClick={onClose}>
          <Close width={24} height={24} />
        </Button>
      </Flex>
      <Flex
        component="form"
        vertical
        gap={16}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="old_password"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              placeholder="Старый пароль"
              required
              validate={fieldState}
              isError={!!error}
            />
          )}
        />
        <Controller
          name="new_password"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              placeholder="Новый пароль"
              required
              validate={fieldState}
              isError={!!error}
            />
          )}
        />
        {error ? (
          <Alert
            icon={<PiWarningBold size={16} />}
            showIcon
            type="error"
            message="Пользователя с такой почтной не существует, проверьте правильность введеных данных, или свяжитесь с службой поддержки"
          />
        ) : null}
        <Flex vertical gap={8}>
          <Button
            type="primary"
            disabled={!isValid}
            fullWidth
            htmlType="submit"
          >
            Отправить
          </Button>
        </Flex>
      </Flex>
      <Flex vertical align="center" gap={24}>
        <Text variant="body-6" align="center">
          Или обратитесь в службу поддержки
          <br />
          для восстановления пароля:
        </Text>
        <a href={`mailto:${supportLink}`}>
          <Text variant="subheader-3" color="green">
            {supportLink}
          </Text>
        </a>
        <a href={`tel:${supportPhone}`}>
          <Text variant="subheader-3" color="green">
            {supportPhone}
          </Text>
        </a>
      </Flex>
      <div />
    </Flex>
  );
};
