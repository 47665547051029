import { FC, useCallback, useState } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "src/ui/Button/Button";
import { Input } from "src/ui/Input/Input";
import { ReportsFioSearchRequest, useReportsFioSearch } from "@api/report";
import Alert from "antd/lib/alert";
import { SubmitButton } from "../../components/SubmitButton/SubmitButton";
import s from "./MainTabFio.module.scss";
import { MainTabFieldValues, MainTabProps } from "../../types";
import { MultiFioModal } from "./components/MultiFioModal/MultiFioModal";
import { dropZoneFileListSchema } from "../../constants";

interface ReportsFioFieldValues
  extends ReportsFioSearchRequest,
    MainTabFieldValues {}

const CURRENT_YEAR = new Date().getFullYear();

const birthDayError = "Введите валидный день месяца";
const birthMonthError = "Введите валидный месяц (от 1 до 12)";
const birthYearError = `Введите валидный год (от 1900 до ${CURRENT_YEAR})`;
const ageError = `Введите валидный год (от 1900 до ${CURRENT_YEAR})`;

const schema = yup
  .object<ReportsFioSearchRequest>()
  .shape({
    last_name: yup.string(),
    first_name: yup.string(),
    middle_name: yup.string(),
    birth_day: yup
      .number()
      .integer(birthDayError)
      .min(1, birthDayError)
      .max(31, birthDayError),
    birth_month: yup
      .number()
      .integer(birthMonthError)
      .min(1, birthMonthError)
      .max(12, birthMonthError),
    birth_year: yup
      .number()
      .integer(birthYearError)
      .min(1900, birthYearError)
      .max(CURRENT_YEAR, birthYearError),
    age_from: yup
      .number()
      .integer(ageError)
      .min(0, ageError)
      .max(150, ageError),
    age_to: yup.number().integer(ageError).min(0, ageError).max(150, ageError),
    sopdFileList: dropZoneFileListSchema,
  })
  .required()
  .test(
    "age_from-less-than-age_to",
    '"Возраст от" должен быть не больше, чем "Возраст до"',
    ({ age_from, age_to }) => {
      if (typeof age_from !== "number" || typeof age_to !== "number") {
        return true;
      }
      return age_from < age_to;
    },
  )
  .test(
    "at-least-three-required-fields",
    "Должно быть заполнено хотя бы три поля",
    (value) => Object.values(value).filter(Boolean).length >= 3,
  );

type MainTabFioProps = MainTabProps;

export const MainTabFio: FC<MainTabFioProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      age_from: undefined,
      age_to: undefined,
      birth_day: 1,
      birth_month: 1,
      birth_year: 1990,
      first_name: "",
      last_name: "",
      middle_name: "",
    },
  });

  const [isMultiFioModalOpen, setIsMultiFioModalOpen] = useState(false);
  const onMultiFioModalOpen = useCallback(() => {
    setIsMultiFioModalOpen(true);
  }, []);
  const onMultiFioModalClose = useCallback(() => {
    setIsMultiFioModalOpen(false);
  }, []);

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsFioSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsFioFieldValues) => {
      onSubmit({ requestData, search, withSopd: true });
    },
    [search, onSubmit],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex vertical gap={24}>
        <Flex gap={12} wrap="wrap">
          <Controller
            name="last_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                placeholder="Фамилия"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="first_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                placeholder="Имя"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="middle_name"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                placeholder="Отчество"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_day"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min="1"
                max="31"
                step="1"
                placeholder="День"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_month"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min="1"
                max="12"
                step="1"
                placeholder="Месяц"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="birth_year"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min="1900"
                max={CURRENT_YEAR}
                step="1"
                placeholder="Год"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="age_from"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min="0"
                max="150"
                step="1"
                placeholder="Возраст от"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="age_to"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                type="number"
                min="0"
                max="150"
                step="1"
                placeholder="Возраст до"
                {...field}
                validate={fieldState}
              />
            )}
          />
          <Controller
            name="sopdFileList"
            control={control}
            render={({ field }) => (
              <SubmitButton
                fileList={field.value}
                onFileListChange={field.onChange}
                disabled={!isValid || isSearchPending}
              />
            )}
          />
        </Flex>
        {Object.entries(errors)
          .filter(([key]) => !key)
          .map(([, value], index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Alert key={index} type="error" message={value.message} />
          ))}
        <Button
          fullWidth
          disabled={isMultiFioModalOpen}
          type="secondary"
          onClick={onMultiFioModalOpen}
        >
          Массовый запрос из файла
        </Button>
        <MultiFioModal
          open={isMultiFioModalOpen}
          onClose={onMultiFioModalClose}
        />
      </Flex>
    </form>
  );
};
