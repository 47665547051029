import { useRef, useEffect, FC } from "react";
import { dataURLtoFile } from "../TwoFactorEdit/utils/dataURLtoFile";

export const ImageItem: FC<{ src?: string; alt?: string }> = ({ src, alt }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (src) {
      const reader = new FileReader();
      reader.readAsDataURL(dataURLtoFile(src ?? "", "file.png"));
      reader.onload = (e) => {
        const image = new Image();
        image.src = (e.target?.result ?? "") as string;
        image.onload = () => {
          if (imageRef.current) {
            imageRef.current.src = image.src;
          }
        };
      };
    }
  }, [src]);
  return <img ref={imageRef} alt={alt} />;
};
