import { useQuery } from "@tanstack/react-query";
import { group_get } from "../queries";
import { GroupRequest } from "../types";

interface UseGroupsProps {
  enabled?: boolean;
}

export const useGroup = (
  { group_id }: GroupRequest,
  { enabled }: UseGroupsProps = {},
) =>
  useQuery({
    queryKey: ["group", group_id],
    queryFn: () => group_get({ group_id }),
    enabled,
  });
