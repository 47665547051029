import { useCallback, useLayoutEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { MAIN_TAB_QUERY, MainTab } from "../constants";

export const useMainTabSearchParam = (): readonly [
  tab: MainTab | null,
  setTab: (newState: MainTab) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabQuery = searchParams.get(MAIN_TAB_QUERY);
  const tab =
    tabQuery && Object.values<string>(MainTab).includes(tabQuery)
      ? (tabQuery as MainTab)
      : null;
  const { pathname } = useLocation();

  const setTab = useCallback(
    (newTab: MainTab) => {
      setSearchParams((prevSearchParams) => ({
        ...prevSearchParams,
        tab: newTab,
      }));
    },
    [setSearchParams],
  );

  useLayoutEffect(() => {
    if (pathname === String(AppRoute.Lk) && !tab) {
      setTab(MainTab.Fio);
    }
  }, [setTab, tab, pathname]);

  return [tab, setTab];
};
