import { ReportType } from "@api/report/constants";

const defaultTitleGetter = (request: unknown): string => {
  try {
    if (!request) {
      return "";
    }
    if (typeof request === "string") {
      return request;
    }
    if (
      typeof request === "number" ||
      typeof request === "bigint" ||
      typeof request === "symbol"
    ) {
      return String(request);
    }
    if (typeof request === "object") {
      return Object.values(request).filter(Boolean).join(" ");
    }
    if (Array.isArray(request)) {
      return request.filter(Boolean).join(" ");
    }
    return JSON.stringify(request, null);
  } catch {
    return "";
  }
};

export const mapReportTypeToTitleGetter: Record<
  ReportType,
  (request: unknown) => string
> = {
  [ReportType.SEARCH_ADDRESS]: defaultTitleGetter,
  [ReportType.SEARCH_CREDIT_REPORT]: defaultTitleGetter,
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: defaultTitleGetter,
  [ReportType.SEARCH_EMAIL]: defaultTitleGetter,
  [ReportType.SEARCH_FIO]: defaultTitleGetter,
  [ReportType.SEARCH_GRN]: defaultTitleGetter,
  [ReportType.SEARCH_SCORING]: defaultTitleGetter,
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: () => "Поиск по фото",
  [ReportType.SEARCH_DRIVER_LICENSE]: defaultTitleGetter,
  [ReportType.SEARCH_INFO]: defaultTitleGetter,
  [ReportType.SEARCH_INN_FL]: defaultTitleGetter,
  [ReportType.SEARCH_INN_UL]: defaultTitleGetter,
  [ReportType.SEARCH_OGRN]: defaultTitleGetter,
  [ReportType.SEARCH_PASSPORT]: defaultTitleGetter,
  [ReportType.SEARCH_PHONE]: defaultTitleGetter,
  [ReportType.SEARCH_SNILS]: defaultTitleGetter,
  [ReportType.SEARCH_VIN]: defaultTitleGetter,
};
