import { ReportsCommonRetrieveResponse } from "@api/report/retrieve/responses/commonRetrieve";
import { Photo } from "@api/report/retrieve/photo";
import { OpenSourcesFio } from "../openSources";
import { CrossingBorders } from "../crossingBorders";

export type ReportsFioRetrieveResponse = {
  open_sources?: OpenSourcesFio;
  photo?: Photo;
  crossing_borders?: CrossingBorders;
} & ReportsCommonRetrieveResponse;

export const isReportsWithOpenSource = (
  data: object,
): data is ReportsFioRetrieveResponse =>
  Object.hasOwn(data, "crossing_borders");
