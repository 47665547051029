import { AxiosError } from "axios";
import { client } from "@api/client";

import { endpoints } from "./endpoints";
import {
  CreateResponse,
  Group,
  Create,
  Edit,
  GroupsAddMemberRequest,
  GroupsAddMemberResponse,
  GroupsGrantPermissionsRequest,
  EditMemberRequest,
  CurrentUser,
  ChangePasswordRequest,
  GroupsReportsTotalCountRequest,
  GroupsReportsTotalCountResponse,
  GroupsSetLoginDurationRequest,
  GroupsSetNonRussianApiAllowedRequest,
  UsersSetQuotaRequest,
  UsersSetQuotaResponse,
  UsersRegisterResponse,
  UsersRegisterRequest,
  UsersConfirmEmailRequest,
  UsersConfirmEmailResponse,
  UsersResendEmailRequest,
  UsersResendEmailResponse,
  UsersProfilePictureRequest,
  UsersProfilePictureDeleteRequest,
  UsersProfilePictureRetrieveResponse,
  UsersProfilePictureResponse,
  UsersProfilePictureDeleteResponse,
  UsersProfilePictureRetrieveRequest,
  GroupsGrantUserPermissionsRequest,
  GroupsGrantPermissionsResponse,
  GroupsGrantUserPermissionsResponse,
  GroupRequest,
  GroupsGetPermissionsRequest,
  GroupsGetPermissionsResponse,
} from "./types";

export const groups = () => client.get<undefined, Group[]>(endpoints.GROUPS);

export const group_get = async (data: GroupRequest) => {
  const gs = await groups();
  const foundGroup = gs.find((g) => g.group_id === data.group_id);

  if (!foundGroup) {
    throw new AxiosError("Group not found");
  }

  return foundGroup;
};

export const groups_create = (data: Create) =>
  client.post<undefined, CreateResponse>(endpoints.GROUPS_CREATE, data);

export const groups_edit = (data: Edit) =>
  client.post<undefined, string>(endpoints.GROUPS_EDIT, data);

export const groups_add_member = (data: GroupsAddMemberRequest) =>
  client.post<undefined, GroupsAddMemberResponse>(
    endpoints.GROUPS_ADD_MEMBER,
    data,
  );

export const groups_remove_member = (data: GroupsAddMemberResponse) =>
  client.post<undefined, string>(endpoints.GROUPS_REMOVE_MEMBER, data);

export const groups_get_permissions = (data: GroupsGetPermissionsRequest) =>
  client.post<GroupsGetPermissionsResponse, GroupsGetPermissionsResponse>(
    endpoints.GROUPS_GET_PERMISSIONS,
    data,
  );

export const groups_grant_permissions = (data: GroupsGrantPermissionsRequest) =>
  client.post<GroupsGrantPermissionsResponse, GroupsGrantPermissionsResponse>(
    endpoints.GROUPS_GRANT_PERMISSIONS,
    data,
  );

export const groups_grant_user_permissions = (
  data: GroupsGrantUserPermissionsRequest,
) =>
  client.post<
    GroupsGrantUserPermissionsResponse,
    GroupsGrantUserPermissionsResponse
  >(endpoints.GROUPS_GRANT_USER_PERMISSIONS, data);

export const groups_set_non_russian_ip_allowed = (
  data: GroupsSetNonRussianApiAllowedRequest,
) =>
  client.post<string, string>(
    endpoints.GROUPS_SET_NON_RUSSIAN_IP_ALLOWED,
    data,
  );

export const groups_set_login_duration = (
  data: GroupsSetLoginDurationRequest,
) => client.post<string, string>(endpoints.GROUPS_LOGIN_DURATION, data);

export const users_edit = (data: EditMemberRequest) =>
  client.post<string, string>(endpoints.USERS_EDIT, data);

export const current_user = () =>
  client.get<CurrentUser, CurrentUser>(endpoints.USERS_CURRENT_USER);

export const changePassword = (data: ChangePasswordRequest) =>
  client.post<undefined, undefined, ChangePasswordRequest>(
    endpoints.USERS_CHANGE_PASSWORD,
    data,
  );

export const groupsReportsTotalCount = (data: GroupsReportsTotalCountRequest) =>
  client.post<
    GroupsReportsTotalCountResponse,
    GroupsReportsTotalCountResponse,
    GroupsReportsTotalCountRequest
  >(endpoints.GROUPS_REPORTS_TOTAL_COUNT, data);

export const usersSetQuota = (data: UsersSetQuotaRequest) =>
  client.post<undefined, UsersSetQuotaResponse, UsersSetQuotaRequest>(
    endpoints.USERS_SET_QUOTA,
    data,
  );

export const usersRegister = (data: UsersRegisterRequest) =>
  client.post<undefined, UsersRegisterResponse, UsersRegisterRequest>(
    endpoints.USERS_REGISTER,
    data,
  );

export const usersConfirmEmail = (data: UsersConfirmEmailRequest) =>
  client.post<undefined, UsersConfirmEmailResponse, UsersConfirmEmailRequest>(
    endpoints.USERS_CONFIRM_EMAIL,
    data,
  );

export const usersResendEmail = (data: UsersResendEmailRequest) =>
  client.post<undefined, UsersResendEmailResponse, UsersResendEmailRequest>(
    endpoints.USERS_RESEND_EMAIL,
    data,
  );

export const usersProfilePicture = (data: UsersProfilePictureRequest) =>
  client.post<
    undefined,
    UsersProfilePictureResponse,
    UsersProfilePictureRequest
  >(endpoints.USERS_PROFILE_PICTURE, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const usersProfilePictureDelete = (
  data: UsersProfilePictureDeleteRequest,
) =>
  client.post<
    undefined,
    UsersProfilePictureDeleteResponse,
    UsersProfilePictureDeleteRequest
  >(endpoints.USERS_PROFILE_PICTURE_DELETE, data);

export const usersProfilePictureRetrieve = (
  data: UsersProfilePictureRetrieveRequest,
) =>
  client.post<
    undefined,
    UsersProfilePictureRetrieveResponse,
    UsersProfilePictureRetrieveRequest
  >(endpoints.USERS_PROFILE_PICTURE_RETRIEVE, data, { responseType: "blob" });
