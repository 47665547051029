import { Flex } from "antd";
import { FC } from "react";
import { Block } from "src/ui/Block/Block";
import { Text } from "src/ui/Text/Text";
import { Link, useParams } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { Button } from "src/ui/Button/Button";
import { LuChevronLeft } from "react-icons/lu";
import { BreadCrumps } from "src/components/BreadCrumps/BreadCrumps";
import s from "./Search.module.scss";

export const Search: FC = () => {
  const { query } = useParams();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Flex vertical gap={8}>
        <Flex gap={4} align="center">
          <Link className={s.link} to={AppRoute.Lk}>
            <Button type="flat" pin="round">
              <LuChevronLeft size={24} />
            </Button>
          </Link>
          <Text variant="header-8">{`Результаты поиска${query ? ` "${query}"` : ""}`}</Text>
        </Flex>
        <BreadCrumps
          mapPathToTitle={{ [AppRoute.Search]: "Системный поиск" }}
          paths={[AppRoute.Search]}
        />
      </Flex>
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">Поиск</Text>
        </Flex>
      </Block>
    </Flex>
  );
};
