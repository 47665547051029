import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { FC } from "react";
import s from "./Search.module.scss";
import { Fio } from "./components/Fio/Fio";
import { Phone } from "./components/Phone/Phone";
import { OtherFields } from "./components/OtherFields/OtherFields";

const items: TabsProps["items"] = [
  {
    key: "Fio",
    label: "ФИО",
    children: <Fio />,
  },
  {
    key: "Phone",
    label: "Телефон",
    children: <Phone />,
  },
  {
    key: "OtherFields",
    label: "Другие поля",
    children: <OtherFields />,
  },
];

export const Search: FC = () => (
  <div className={s.wrapper}>
    <Tabs defaultActiveKey="Groups" items={items} className={s.tab_body} />
  </div>
);
