import { useParams } from "react-router";
import {
  useReportsCreditRatingRetrieve,
  normalizeReportsFullRetrieve,
} from "@api/report";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";
import { RetrieveCreditRating } from "@entities/RetrieveCreditRating/RetrieveCreditRating";

export const SearchCreditRatingWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: creditRatingReport, isLoading } =
    useReportsCreditRatingRetrieve({
      id: reportId,
    });

  return (
    <Skeleton loading={isLoading}>
      {creditRatingReport && (
        <RetrieveCreditRating
          report={normalizeReportsFullRetrieve(creditRatingReport)}
          type={type}
        />
      )}
    </Skeleton>
  );
};
