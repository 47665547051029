export const validationMessage = {
  Required: "Обязательное поле",
  RequiredPolicy: "Необходимо принять условия",
  WrongEmail: "E-mail неверный формат",
  WrongPhone: "Телефон указан неверно",
  WrongInn: "Длина ИНН 12 цифр",
  WrongPassport: "Длина серии и номера паспорта 10 цифр",
  WrongPassword: "Неверный пароль",
  WrongBirthday: "Неверный формат дата рождения",
  MinNumber: ({ min }: { min: number }) =>
    `Значение должно быть не меньше ${String(min)}`,
  MaxNumber: ({ max }: { max: number }) =>
    `Значение должно быть не больше ${String(max)}`,
  MinLen: ({ min }: { min: number }) =>
    `Значение должно быть не короче ${String(min)} символов`,
  MaxLen: ({ max }: { max: number }) =>
    `Значение должно быть не длинне ${String(max)} символов`,
};

export const validationRegex = {
  /**
   * Регулярное выражение для проверки верхнего регистра, цифр и символов
   */
  password: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
};
