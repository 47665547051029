import { Flex } from "antd";
import { ReportsPhotoByFaceRetrieveResponse } from "@api/report";
import { ReportCommonProps } from "@entities/types";
import { FullDossier } from "../../components/FullDossier/FullDossier";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { calculateReportStatus } from "../../utils/getReportStatus";

export type RetrievePhotoByFaceProps = {
  report: ReportsPhotoByFaceRetrieveResponse;
} & ReportCommonProps;

export const RetrievePhotoByFace = ({
  report,
  type,
}: RetrievePhotoByFaceProps) => (
  <RetrieveStatus
    status={
      calculateReportStatus([report?.photo_response?.status]) || report?.status
    }
  >
    <Flex vertical gap={32}>
      <FullDossier
        status={report?.photo_response?.status}
        type={type}
        photos={report?.photo_response?.result?.faces?.[0]?.candidates?.map(
          (candidate) => candidate?.photos?.[0]
        )}
      />
    </Flex>
  </RetrieveStatus>
);
