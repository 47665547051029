import { Skeleton } from "antd";
import { useParams } from "react-router";
import { useReportsPhoneRetrieve } from "@api/report";
import { SearchPhone } from "@entities/SearchPhone/SearchPhone";
import { ReportCommonProps } from "@entities/types";

export const SearchPhoneWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: phoneReport, isLoading } = useReportsPhoneRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {phoneReport && <SearchPhone report={phoneReport} type={type} />}
    </Skeleton>
  );
};
