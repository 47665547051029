import { Flex, Skeleton } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Toggle } from "src/ui/Toggle/Toggle";
import { mapPermissionToLabel } from "src/utils/permissions/constants";
import { useGroupsGetPermissions } from "@api/groups/mutations/useGroupsGrantPermissions";
import { Group } from "@api/groups";
import { Text } from "src/ui/Text/Text";
import { AccessesSettingsFormValues } from "../../types";

interface AccessesSettingsPermissionReportsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<AccessesSettingsFormValues, any>;
  watch: UseFormWatch<AccessesSettingsFormValues>;
  setValue: UseFormSetValue<AccessesSettingsFormValues>;
  group: Group;
}

export const AccessesSettingsPermissionReports: FC<
  AccessesSettingsPermissionReportsProps
> = ({ control, watch, setValue, group }) => {
  const [isAllReportsEnabled, setIsAllReportsEnabled] = useState(false);
  const { data: permissions = [], isLoading: isPermissionsLoading } =
    useGroupsGetPermissions({ group_id: group.group_id });

  const watchPermissions = watch(permissions);
  const allChecked = watchPermissions.every(Boolean);

  useEffect(() => {
    setIsAllReportsEnabled((currIsAllReportsEnabled) => {
      if (currIsAllReportsEnabled !== allChecked) {
        return allChecked;
      }
      return currIsAllReportsEnabled;
    });
  }, [allChecked]);

  const onAllReportsEnabledChange = useCallback(
    (newIsAllReportsEnabled: boolean) => {
      if (newIsAllReportsEnabled) {
        permissions.forEach((permission) => {
          setValue(permission, true);
        });
      } else if (allChecked) {
        permissions.forEach((permission) => {
          setValue(permission, false);
        });
      }

      setIsAllReportsEnabled(newIsAllReportsEnabled);
    },
    [setValue, allChecked, permissions],
  );

  if (isPermissionsLoading) {
    return <Skeleton />;
  }

  if (!permissions) {
    return <Text color="red">Не удалось загрузить списко доступов группы</Text>;
  }

  return (
    <Flex vertical gap={8}>
      <Toggle
        value={isAllReportsEnabled}
        onChange={onAllReportsEnabledChange}
        title="Все проверки"
      />
      {permissions.map((permission) => (
        <Controller
          key={permission}
          name={permission}
          control={control}
          render={({ field, fieldState }) => (
            <Toggle
              {...field}
              validate={fieldState}
              title={mapPermissionToLabel[permission]}
            />
          )}
        />
      ))}
    </Flex>
  );
};
