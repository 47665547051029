import { FC, useCallback } from "react";
import { Group } from "@api/groups";
import { MultipleInput } from "src/ui/MultipleInput/MultipleInput";
import {
  useAllowedIpCreateMutation,
  useAllowedIpDeleteMutation,
  useAllowedIpSearchQuery,
} from "@api/ip/mutations";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";

interface AllowedIpsInputProps {
  group: Group;
}

export const AllowedIpsInput: FC<AllowedIpsInputProps> = ({ group }) => {
  const { group_id } = group;

  const { data: allowedIps, isSuccess: isAllowedIpsSuccess } =
    useAllowedIpSearchQuery({ group_id });
  const { mutateAsync: createAllowedIp, isPending: isAllowedIpCreating } =
    useAllowedIpCreateMutation();
  const { mutateAsync: deleteAllowedIp, isPending: isAllowedIpDeleting } =
    useAllowedIpDeleteMutation();

  const [api] = useNotification();

  const onAdd = useCallback(
    async (ip: string) => {
      await import("is-ip").then(({ isIP }) => {
        if (!isIP(ip)) return;

        createAllowedIp({
          group_id,
          ip,
        }).catch((error) => {
          if (handleBackendErrors({ api, error })) return undefined;

          return undefined;
        });
      });
    },
    [createAllowedIp, group_id, api],
  );

  const onDelete = useCallback(
    async (ip: string) => {
      await import("is-ip").then(({ isIP }) => {
        if (!isIP(ip)) return;

        deleteAllowedIp({
          group_id,
          ip,
        }).catch((error) => {
          if (handleBackendErrors({ api, error })) return undefined;

          return undefined;
        });
      });
    },
    [deleteAllowedIp, group_id, api],
  );

  if (!isAllowedIpsSuccess) {
    return null;
  }

  return (
    <MultipleInput
      disabled={isAllowedIpCreating || isAllowedIpDeleting}
      values={(allowedIps || []).map(({ ip }) => ip)}
      onAdd={onAdd}
      onDelete={onDelete}
      placeholder="Введите IP, для разделения используйте пробел"
    />
  );
};
