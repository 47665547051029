import { useCallback, useEffect, useState } from "react";

import { Header } from "@pages/Analytics/components/Header";
import { CheckPaper } from "@pages/Analytics/components/CheckPaper/CheckPaper";
import { TypeCheckPaper } from "@pages/Analytics/components/TypeCheckPaper/TypeCheckPaper";
import {
  useGroupStatistics,
  useGroupStatisticsByGroup,
} from "@api/analytics/queries/useStatisticsGroup";
import { SettingsModal } from "@pages/Analytics/components/SettingsModal/SettingsModal";
import { Interval } from "@pages/Analytics/types";
import { useParams } from "react-router";
import { useModalMessage } from "../../ui/Modal/useModalMessage";
import s from "./Analytics.module.scss";

export const Analytics = () => {
  const [intervalValue, setIntervalValue] = useState<Interval>(Interval.all);
  const [dateFrom, setDateFrom] = useState<Date | undefined>(undefined);
  const [dateTo, setDateTo] = useState<Date | undefined>(undefined);
  const { group_id, user_id } = useParams<{
    group_id?: string;
    user_id?: string;
  }>();

  const { data: statisticsAll, refetch: refetchStatisticsAll } =
    useGroupStatistics(
      {
        granularity: "DAY",
        date_from: dateFrom?.toISOString(),
        date_to: dateTo?.toISOString(),
        group_by_type: false,
        group_id: Number(group_id),
        user_id: Number(user_id),
      },
      {
        enabled: Boolean(group_id && user_id),
      },
    );

  const { data: statisticsGroupByType, refetch: refetchStatisticsGroupByType } =
    useGroupStatisticsByGroup(
      {
        granularity: "DAY",
        date_from: dateFrom?.toISOString(),
        date_to: dateTo?.toISOString(),
        group_by_type: true,
        group_id: Number(group_id),
        user_id: Number(user_id),
      },
      {
        enabled: Boolean(group_id && user_id),
      },
    );

  const onChangeIntervalValue = useCallback((interval: Interval) => {
    setIntervalValue(interval);
  }, []);

  useEffect(() => {
    if (intervalValue === Interval.last30Days) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      setDateTo(today);

      const todayLast30Days = new Date();
      todayLast30Days.setHours(0);
      todayLast30Days.setMinutes(0);
      todayLast30Days.setSeconds(0);
      todayLast30Days.setMilliseconds(0);

      todayLast30Days.setDate(todayLast30Days.getDate() - 30);
      setDateFrom(todayLast30Days);
    }

    if (intervalValue === Interval.last90Days) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      setDateTo(today);

      const todayLast30Days = new Date();
      todayLast30Days.setHours(0);
      todayLast30Days.setMinutes(0);
      todayLast30Days.setSeconds(0);
      todayLast30Days.setMilliseconds(0);

      todayLast30Days.setDate(todayLast30Days.getDate() - 90);
      setDateFrom(todayLast30Days);
    }
  }, [intervalValue]);

  useEffect(() => {
    refetchStatisticsAll().catch(() => {});
    refetchStatisticsGroupByType().catch(() => {});
  }, [dateFrom, dateTo, refetchStatisticsAll, refetchStatisticsGroupByType]);

  const { openModal, closeModal } = useModalMessage();

  const openModalSettings = useCallback(() => {
    openModal({
      children: (
        <SettingsModal
          intervalValue={intervalValue}
          onChangeIntervalValue={onChangeIntervalValue}
          closeModal={closeModal}
        />
      ),
    });
  }, [closeModal, intervalValue, onChangeIntervalValue, openModal]);

  return (
    <div className={s.container}>
      <Header openModalSettings={openModalSettings} />
      <CheckPaper statisticsAll={statisticsAll} intervalValue={intervalValue} />
      <TypeCheckPaper
        statisticsGroupByType={statisticsGroupByType}
        intervalValue={intervalValue}
      />
    </div>
  );
};
