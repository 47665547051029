import { useQuery } from "@tanstack/react-query";

import { GroupStatisticsRequest } from "@api/analytics/types";
import { getGroupStatistics } from "../queries";

export const useGroupStatistics = (
  data: GroupStatisticsRequest<false>,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    queryKey: ["getGroupStatistics"],
    queryFn: () => getGroupStatistics<false>({ ...data, group_by_type: false }),
    enabled,
  });

export const useGroupStatisticsByGroup = (
  data: GroupStatisticsRequest<true>,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    queryKey: ["getGroupStatisticsByGroup"],
    queryFn: () => getGroupStatistics<true>({ ...data, group_by_type: true }),
    enabled,
  });
