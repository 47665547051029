import { Permission } from "@api/groups";

export const mapPermissionToLabel: Record<Permission, string> = {
  [Permission.AccessApiPermission]: "Доступ к API",
  [Permission.AdminPermission]: "Админ",
  [Permission.MemberPermission]: "Права сотрудника",
  [Permission.ReportAddressPermission]: "Поиск по адресу",
  [Permission.ReportCreditRatingPermission]: "Поиск по кредитному рейтингу",
  [Permission.ReportDriverLicensePermission]: "Поиск по ВУ",
  [Permission.ReportEmailPermission]: "Поиск по Email",
  [Permission.ReportFioPermission]: "Поиск по ФИО",
  [Permission.ReportGrnPermission]: "Поиск по ГРН",
  [Permission.ReportInfoPermission]: "Поиск по Ключевому слову",
  [Permission.ReportInnFlPermission]: "Поиск по ИНН (физ.лица)",
  [Permission.ReportInnUlPermission]: "Поиск по ИНН (юр.лица)",
  [Permission.ReportOgrnPermission]: "Поиск по ОГРН",
  [Permission.ReportPassportPermission]: "Поиск по Паспорту",
  [Permission.ReportPhonePermission]: "Поиск по Телефону",
  [Permission.ReportPhotoByFacePermission]: "Поиск по Фотографии лица",
  [Permission.ReportPhotoByFioPermission]: "Поиск Фото",
  [Permission.ReportScoringPermission]: "Поиск по Скорингу",
  [Permission.ReportSnilsPermission]: "Поиск по СНИЛС",
  [Permission.ReportVinPermission]: "Поиск по ВИН",
  [Permission.SuperuserPermission]: "Полные права",
};

export enum PermissionType {
  Gold = "gold",
  Normal = "normal",
}

export const mapPermissionToType: Record<Permission, PermissionType> = {
  [Permission.AccessApiPermission]: PermissionType.Gold,
  [Permission.AdminPermission]: PermissionType.Normal,
  [Permission.MemberPermission]: PermissionType.Normal,
  [Permission.ReportAddressPermission]: PermissionType.Normal,
  [Permission.ReportCreditRatingPermission]: PermissionType.Normal,
  [Permission.ReportDriverLicensePermission]: PermissionType.Normal,
  [Permission.ReportEmailPermission]: PermissionType.Normal,
  [Permission.ReportFioPermission]: PermissionType.Normal,
  [Permission.ReportGrnPermission]: PermissionType.Normal,
  [Permission.ReportInfoPermission]: PermissionType.Normal,
  [Permission.ReportInnFlPermission]: PermissionType.Normal,
  [Permission.ReportInnUlPermission]: PermissionType.Normal,
  [Permission.ReportOgrnPermission]: PermissionType.Normal,
  [Permission.ReportPassportPermission]: PermissionType.Normal,
  [Permission.ReportPhonePermission]: PermissionType.Normal,
  [Permission.ReportPhotoByFacePermission]: PermissionType.Normal,
  [Permission.ReportPhotoByFioPermission]: PermissionType.Normal,
  [Permission.ReportScoringPermission]: PermissionType.Normal,
  [Permission.ReportSnilsPermission]: PermissionType.Normal,
  [Permission.ReportVinPermission]: PermissionType.Normal,
  [Permission.SuperuserPermission]: PermissionType.Gold,
};
