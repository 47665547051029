import {
  CrossingBorderItemEnum,
  CrossingBordersSummaryKeys,
} from "../crossingBorders";

export const crossingBorderItemEnumOrder: CrossingBorderItemEnum[] = [
  CrossingBorderItemEnum.crossingDate,
  CrossingBorderItemEnum.transportType,
  CrossingBorderItemEnum.sourceCountry,
  CrossingBorderItemEnum.destinationCountry,
  CrossingBorderItemEnum.documentCountry,
];

export const crossingBorderItemTitles: Record<CrossingBorderItemEnum, string> =
  {
    [CrossingBorderItemEnum.crossingDate]: "Дата пересечения",
    [CrossingBorderItemEnum.transportType]: "Транспорт",
    [CrossingBorderItemEnum.sourceCountry]: "Страна откуда",
    [CrossingBorderItemEnum.destinationCountry]: "Страна куда",
    [CrossingBorderItemEnum.documentCountry]: "Страна выдачи документа",
  };

export const crossingBordersSummaryTexts: Record<
  CrossingBordersSummaryKeys,
  string
> = {
  [CrossingBordersSummaryKeys.count]: "Количество пересечений",
  [CrossingBordersSummaryKeys.countriesVisited]: "Посещенные страны",
  [CrossingBordersSummaryKeys.countryDocuments]: "Страна выдачи документов",
  [CrossingBordersSummaryKeys.transportList]: "Тип транспорта",
};

export const summaryKeys: CrossingBordersSummaryKeys[] = [
  CrossingBordersSummaryKeys.count,
  CrossingBordersSummaryKeys.countriesVisited,
  CrossingBordersSummaryKeys.countryDocuments,
  CrossingBordersSummaryKeys.transportList,
];
