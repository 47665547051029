import { useState, useRef, useEffect, FC } from "react";

import { Button, Input } from "antd";
import {
  useTwoFactorDisable,
  useTwoFactorEnable,
  useTwoFactorGenerate,
} from "@api/auth";
import s from "./TwoFactorEdit.module.scss";
import { dataURLtoFile } from "./utils/dataURLtoFile";

export const TwoFactorEdit: FC = () => {
  const [code, setCode] = useState("");
  const { data } = useTwoFactorGenerate();
  const { mutate: disable } = useTwoFactorDisable();
  const { mutate: enable } = useTwoFactorEnable();
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(
        dataURLtoFile(data.qr ?? "", "file.png", "data:image/jpeg;base64"),
      );
      reader.onload = (e) => {
        const image = new Image();
        image.src = (e.target?.result ?? "") as string;
        image.onload = () => {
          if (imageRef.current) {
            imageRef.current.src = image.src;
          }
        };
      };
    }
  }, [data]);

  const enableTwoFactor = () => {
    if (data?.secret) {
      enable({
        secret: data.secret,
        code,
      });
    }
  };

  const disableTwoFactor = () => {
    disable();
  };

  return (
    <div className={s.wrapper}>
      <div className={s.redact_panel}>
        <img ref={imageRef} alt="" />
        <Input
          required
          value={code}
          placeholder="Код"
          onChange={(e) => {
            setCode(e.currentTarget.value);
          }}
        />
        <Button onClick={enableTwoFactor}>
          Включить двухфакторную авторизацию
        </Button>
        <Button onClick={disableTwoFactor}>
          Выключить двухфакторную авторизацию
        </Button>
      </div>
    </div>
  );
};
