import { Block } from "src/ui/Block/Block";
import React from "react";
import { Text } from "src/ui/Text/Text";
import { MdNorthEast } from "react-icons/md";
import { Address } from "@api/report";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import s from "./Addresses.module.scss";
import { PageSearchPointer } from "../../../../components/PageSearchPointer/PageSearchPointer";

export type AddressesProps = {
  addresses?: Address[] | null;
} & RetrieveCardCommonProps;

export const Addresses = ({ addresses, status }: AddressesProps) => (
  <RetrieveCard title="Адреса" status={status}>
    <div className={s.container}>
      <Text color="darkGray" variant="body-1">
        Адрес
      </Text>
      <Text color="darkGray" variant="body-1">
        Год
      </Text>
      <Text color="darkGray" variant="body-1">
        Количество упоминаний
      </Text>

      {addresses?.map((address) => (
        <>
          <PageSearchPointer title={address?.address} />
          <Text variant="body-5">{address?.year}</Text>
          <Text variant="body-5">{address?.count}</Text>
        </>
      ))}
    </div>
  </RetrieveCard>
);
