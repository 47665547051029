export interface GetDatasetsParams {
  datasets?: { data: number[] }[];
  height: number;
}

export const getBackgroundColor = () => {};

export const getDatasets = ({ datasets, height }: GetDatasetsParams) =>
  datasets?.map((dataset) => ({
    ...dataset,
    borderColor: "#5ACC88",
    backgroundColor: (context) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, height);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      gradient.addColorStop(0, "rgba(90, 204, 136, 0.3)");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      gradient.addColorStop(1, "rgba(90, 204, 136, 0)");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return gradient;
    },
    fill: true,
    tension: 0.4,
  })) || [];
