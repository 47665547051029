import { useQuery } from "@tanstack/react-query";
import { UsersProfilePictureRetrieveRequest } from "../types";
import { usersProfilePictureRetrieve } from "../queries";

export const useUsersProfilePictureRetrieveQuery = (
  data: UsersProfilePictureRetrieveRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    queryKey: ["profilePicture"],
    queryFn: () =>
      usersProfilePictureRetrieve(data).then((photo) => {
        const photoSrc = URL.createObjectURL(photo);
        return photoSrc;
      }),
    enabled,
  });
