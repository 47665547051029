import { createContext, Dispatch, SetStateAction, useContext } from "react";

const PasswordContext = createContext<{
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
}>({
  password: "",
  setPassword() {
    throw new Error("Outside context");
  },
});

export const PasswordContextProvider = PasswordContext.Provider;
export const usePassword = () => useContext(PasswordContext);
