import React from "react";
import { Text } from "src/ui/Text/Text";
import { Income } from "@api/report";
import { MdNorthEast } from "react-icons/md";
import s from "./Incomes.module.scss";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import { PageSearchPointer } from "../../../../components/PageSearchPointer/PageSearchPointer";

type IncomesProps = {
  incomes?: Income[] | null;
} & RetrieveCardCommonProps;

export const Incomes = ({ incomes, status }: IncomesProps) => (
  <RetrieveCard title="Доходы" status={status}>
    <div className={s.container}>
      <Text color="darkGray" variant="body-1">
        Место работы
      </Text>
      <Text color="darkGray" variant="body-1">
        Сумма
      </Text>
      <Text color="darkGray" variant="body-1" className={s.container__right}>
        Дата актуальности
      </Text>
      {incomes?.map((income: Income) => (
        <>
          <PageSearchPointer title={income.work_place} />
          <Text variant="body-5">{income.sum}</Text>
          <Text variant="body-5" className={s.container__right}>
            {" "}
            {income.date}
          </Text>
        </>
      ))}
    </div>
  </RetrieveCard>
);
