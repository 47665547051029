import { MdSnippetFolder, MdAnalytics } from "react-icons/md";
// import { IoDocumentText } from "react-icons/io5";
import { TbShieldSearch } from "react-icons/tb";
import { MAIN_TAB_QUERY, MainTab } from "@pages/Main/constants";
import { Text } from "src/ui/Text/Text";
import { AppRoute } from "src/app/routes/constants";
import { ReactNode } from "react";
import { generatePath } from "react-router";

export enum MenuGroup {
  Checking = "checking",
  Cabinet = "cabinet",
  Organization = "organization",
  Info = "info",
}

interface MenuItem {
  key: string;
  title: ReactNode;
  icon: ReactNode;
  children?: MenuSubItem[];
}
interface MenuSubItem {
  key: string;
  title: ReactNode;
  href: string;
}

interface GetMenuItemsProps {
  group_id?: number;
  user_id?: number;
}

export const getMenuItems = ({
  group_id,
  user_id,
}: GetMenuItemsProps): MenuItem[] => [
  {
    key: MenuGroup.Checking,
    title: <Text variant="body-5">Проверка</Text>,
    icon: <TbShieldSearch size={24} />,
    children: [
      // {
      //   key: MainTab.Dossier,
      //   title: <Text variant="body-2">Полное досье 🔥</Text>,
      //   href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Dossier}`,
      // },
      {
        key: MainTab.Fio,
        title: <Text variant="body-2">По ФИО</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Fio}`,
      },
      {
        key: MainTab.Phone,
        title: <Text variant="body-2">По телефону</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Phone}`,
      },
      {
        key: MainTab.Address,
        title: <Text variant="body-2">По адресу</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Address}`,
      },
      {
        key: MainTab.InnOrSnils,
        title: <Text variant="body-2">По ИНН или СНИЛС</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.InnOrSnils}`,
      },
      {
        key: MainTab.Passport,
        title: <Text variant="body-2">По паспорту</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Passport}`,
      },
      {
        key: MainTab.Auto,
        title: <Text variant="body-2">По номеру авто</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Auto}`,
      },
      {
        key: MainTab.DriverLicense,
        title: <Text variant="body-2">По ВУ</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.DriverLicense}`,
      },
      {
        key: MainTab.Company,
        title: <Text variant="body-2">По юр. лицам</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Company}`,
      },
      {
        key: MainTab.Photo,
        title: <Text variant="body-2">По фото</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Photo}`,
      },
      {
        key: MainTab.CreditRating,
        title: <Text variant="body-2">Кредитный рейтинг</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.CreditRating}`,
      },
      {
        key: MainTab.Scoring,
        title: <Text variant="body-2">Скоринг</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Scoring}`,
      },
      {
        key: MainTab.Info,
        title: <Text variant="body-2">По ключевому слову</Text>,
        href: `${AppRoute.Lk}?${MAIN_TAB_QUERY}=${MainTab.Info}`,
      },
    ],
  },
  ...(group_id && user_id
    ? [
        {
          key: MenuGroup.Cabinet,
          title: <Text variant="body-5">Кабинет</Text>,
          icon: <MdAnalytics size={24} />,
          children: [
            {
              key: "21",
              title: <Text variant="body-2">Аналитика</Text>,
              href: generatePath(AppRoute.Analytics, {
                group_id: String(group_id),
                user_id: String(user_id),
              }),
            },
            {
              key: "22",
              title: <Text variant="body-2">Доступы</Text>,
              href: generatePath(AppRoute.Accesses, {
                group_id: String(group_id),
              }),
            },
          ],
        },
      ]
    : []),
  {
    key: MenuGroup.Organization,
    title: <Text variant="body-5">Организация</Text>,
    icon: <MdSnippetFolder size={24} />,
    children: [
      {
        key: "15",
        title: <Text variant="body-2">История проверок</Text>,
        href: AppRoute.History,
      },
      // { key: "16", title: <Text variant="body-2">Опция 2</Text> },
    ],
  },
  // {
  //   key: MenuGroup.Info,
  //   title: <Text variant="body-5">Инфо</Text>,
  //   icon: <IoDocumentText size={24} />,
  //   children: [
  //     { key: "17", title: <Text variant="body-2">Опция 1</Text> },
  //     { key: "18", title: <Text variant="body-2">Опция 2</Text> },
  //   ],
  // },
  // { key: "19", title: <Text variant="body-5">Телеграм бот</Text> },
  // { key: "20", title: <Text variant="body-5">Обновления</Text> },
];
