import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import {
  reportsAddressSearch,
  reportsCreditRatingSearch,
  reportsDelete,
  reportsDriverLicenseSearch,
  reportsEmailSearch,
  reportsExportCsv,
  reportsFioMultiSearch,
  reportsFioSearch,
  reportsGrnSearch,
  reportsInfoSearch,
  reportsInnFlSearch,
  reportsInnUlSearch,
  reportsOgrnSearch,
  reportsPassportSearch,
  reportsPhoneSearch,
  reportsPhotoByFaceSearch,
  reportsPhotoByFioSearch,
  reportsScoringSearch,
  reportsSnilsSearch,
  reportsTotalCount,
  reportsVinSearch,
} from "./queries";
import {
  ReportsAddressSearchRequest,
  ReportsCreditRatingSearchRequest,
  ReportsDeleteRequest,
  ReportsDriverLicenseSearchRequest,
  ReportsEmailSearchRequest,
  ReportsExportCsvRequest,
  ReportsFioMultiSearchRequest,
  ReportsFioSearchRequest,
  ReportsGrnSearchRequest,
  ReportsInfoSearchRequest,
  ReportsInnFlSearchRequest,
  ReportsInnUlSearchRequest,
  ReportsOgrnSearchRequest,
  ReportsPassportSearchRequest,
  ReportsPhoneSearchRequest,
  ReportsPhotoByFaceSearchRequest,
  ReportsPhotoByFioSearchRequest,
  ReportsScoringSearchRequest,
  ReportsSnilsSearchRequest,
  ReportsTotalCountRequest,
  ReportsVinSearchRequest,
} from "./request";

export const useReportsAddressSearch = () =>
  useMutation({
    mutationKey: ["reportsAddressSearch", "reports"],
    mutationFn: (data: ReportsAddressSearchRequest) =>
      reportsAddressSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsCreditRatingSearch = () =>
  useMutation({
    mutationKey: ["reportsCreditRatingSearch", "reports"],
    mutationFn: (data: ReportsCreditRatingSearchRequest) =>
      reportsCreditRatingSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsEmailSearch = () =>
  useMutation({
    mutationKey: ["reportsEmailSearch", "reports"],
    mutationFn: (data: ReportsEmailSearchRequest) => reportsEmailSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsFioSearch = () =>
  useMutation({
    mutationKey: ["reportsFioSearch", "reports"],
    mutationFn: (data: ReportsFioSearchRequest) => reportsFioSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsFioMultiSearch = () =>
  useMutation({
    mutationKey: ["reportsFioMultiSearch", "reports"],
    mutationFn: (data: ReportsFioMultiSearchRequest) =>
      reportsFioMultiSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsGrnSearch = () =>
  useMutation({
    mutationKey: ["reportsGrnSearch", "reports"],
    mutationFn: (data: ReportsGrnSearchRequest) => reportsGrnSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsDriverLicenseSearch = () =>
  useMutation({
    mutationKey: ["reportsDriverLicenseSearch", "reports"],
    mutationFn: (data: ReportsDriverLicenseSearchRequest) =>
      reportsDriverLicenseSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsInfoSearch = () =>
  useMutation({
    mutationKey: ["reportsInfoSearch", "reports"],
    mutationFn: (data: ReportsInfoSearchRequest) => reportsInfoSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsInnFlSearch = () =>
  useMutation({
    mutationKey: ["reportsInnFlSearch", "reports"],
    mutationFn: (data: ReportsInnFlSearchRequest) => reportsInnFlSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsInnUlSearch = () =>
  useMutation({
    mutationKey: ["reportsInnUlSearch", "reports"],
    mutationFn: (data: ReportsInnUlSearchRequest) => reportsInnUlSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsOgrnSearch = () =>
  useMutation({
    mutationKey: ["reportsOgrnSearch", "reports"],
    mutationFn: (data: ReportsOgrnSearchRequest) => reportsOgrnSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsPassportSearch = () =>
  useMutation({
    mutationKey: ["reportsPassportSearch", "reports"],
    mutationFn: (data: ReportsPassportSearchRequest) =>
      reportsPassportSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsPhoneSearch = () =>
  useMutation({
    mutationKey: ["reportsPhoneSearch", "reports"],
    mutationFn: (data: ReportsPhoneSearchRequest) => reportsPhoneSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsPhotoByFaceSearch = () =>
  useMutation({
    mutationKey: ["reportsPhotoByFaceSearch", "reports"],
    mutationFn: (data: ReportsPhotoByFaceSearchRequest) =>
      reportsPhotoByFaceSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsPhotoByFioSearch = () =>
  useMutation({
    mutationKey: ["reportsPhotoByFioSearch", "reports"],
    mutationFn: (data: ReportsPhotoByFioSearchRequest) =>
      reportsPhotoByFioSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsSnilsSearch = () =>
  useMutation({
    mutationKey: ["reportsSnilsSearch", "reports"],
    mutationFn: (data: ReportsSnilsSearchRequest) => reportsSnilsSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsVinSearch = () =>
  useMutation({
    mutationKey: ["reportsVinSearch", "reports"],
    mutationFn: (data: ReportsVinSearchRequest) => reportsVinSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsScoringSearch = () =>
  useMutation({
    mutationKey: ["reportsScoringSearch", "reports"],
    mutationFn: (data: ReportsScoringSearchRequest) =>
      reportsScoringSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsTotalCount = (
  data: ReportsTotalCountRequest,
  { enabled }: { enabled?: boolean } = {},
) =>
  useQuery({
    queryKey: ["reports", `request_contains:${data.request_contains}`],
    queryFn: () => reportsTotalCount(data),
    enabled,
  });

export const useReportsDelete = () =>
  useMutation({
    mutationKey: ["reportsDelete"],
    mutationFn: (data: ReportsDeleteRequest) => reportsDelete(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });

export const useReportsExportCsv = () =>
  useMutation({
    mutationKey: ["reportsExportCsv"],
    mutationFn: (data: ReportsExportCsvRequest) => reportsExportCsv(data),
  });
