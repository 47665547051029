import { Flex } from "antd";
import { FC } from "react";
import { Block } from "src/ui/Block/Block";
import { Text } from "src/ui/Text/Text";
import { HistoryList } from "src/components/HistoryList/HistoryList";
import { useMainTabSearchParam } from "@pages/Main/hooks/useMainTabSearchParam";
import s from "./History.module.scss";

export const History: FC = () => {
  const [tab] = useMainTabSearchParam();

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Block>
        <Flex vertical gap={32}>
          <Text variant="header-6">История проверок</Text>
          <HistoryList withPagination limit={15} tab={tab} />
        </Flex>
      </Block>
    </Flex>
  );
};
