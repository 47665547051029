export function toHumanReadableDate(isoDateStr: string): string {
  try {
    const date = new Date(isoDateStr);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
    const year = date.getUTCFullYear();

    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    return `${day}.${month}.${String(year)} в ${hours}:${minutes}`;
  } catch {
    return "";
  }
}
