import s from "./Status.module.scss";
import { ReportStatus } from "@api/report";
import { ReactComponent as Loading } from "src/assets/loading.svg";
import { ReactComponent as Cross } from "src/assets/cross.svg";
import { ReactComponent as Finished } from "src/assets/finished.svg";
import { ReactComponent as Warning } from "src/assets/warning.svg";
import { Text } from "src/ui/Text/Text";

interface StatusProps {
  reportStatus?: ReportStatus | null;
}

export const Status = ({ reportStatus }: StatusProps) => {
  return (
    <div className={s.container}>
      {reportStatus === ReportStatus.InProgress && (
        <>
          <Loading />
          <Text variant="subheader-4">В процессе сбора данных</Text>
        </>
      )}
      {reportStatus === ReportStatus.Canceled && (
        <>
          <Cross />
          <Text variant="subheader-4">Отчет не сформирован</Text>
        </>
      )}
      {reportStatus === ReportStatus.Finished && (
        <>
          <Finished />
          <Text variant="subheader-4">Полностью сформирован</Text>
        </>
      )}
      {reportStatus === ReportStatus.NoData && (
        <>
          <Warning />
          <Text variant="subheader-4">Не удалось найти некоторые данные</Text>
        </>
      )}
    </div>
  );
};
