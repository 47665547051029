import { FC, useCallback, useState } from "react";
import { Flex } from "antd";
import { Toggle } from "src/ui/Toggle/Toggle";
import { MainTabProps } from "../../types";
import { MainTabCompanyNormal } from "./components/MainTabCompanyNormal/MainTabCompanyNormal";
import { MainTabCompanyOgrn } from "./components/MainTabCompanyOgrn/MainTabCompanyOgrn";

type MainTabCompanyProps = MainTabProps;

export const MainTabCompany: FC<MainTabCompanyProps> = ({ onSubmit }) => {
  const [type, setType] = useState<"ogrn" | "normal">("normal");

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("ogrn");
    } else {
      setType("normal");
    }
  }, []);

  return (
    <Flex vertical gap={24}>
      {type === "normal" ? (
        <MainTabCompanyNormal onSubmit={onSubmit} />
      ) : (
        <MainTabCompanyOgrn onSubmit={onSubmit} />
      )}
      <Toggle
        value={type === "ogrn"}
        onChange={onTypeChange}
        title="По номеру ОГРН"
      />
    </Flex>
  );
};
