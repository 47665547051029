import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { searchAutoRegistrationNumberSearch } from "../queries";
import { SearchAutoRegistrationNumberSearchRequest } from "../types";

export const useSearchAutoRegistrationNumber = () =>
  useMutation({
    mutationKey: ["searchAutoRegistrationNumberSearch"],
    mutationFn: (data: SearchAutoRegistrationNumberSearchRequest) =>
      searchAutoRegistrationNumberSearch(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["reports"] }),
  });
