import { Flex, List, Typography, Table } from "antd";

import { Face as FaceObj } from "@api/photo";
import { Candidate } from "@features/Admin/Photo/components/Face/Candidate/Candidate";
import { FC } from "react";
import { ImageItem } from "../../../common/ImageItem";
import s from "./Face.module.scss";

interface FaceProps {
  face: FaceObj;
}

const aliasesColumns = [
  {
    title: "banderlog_negative",
    dataIndex: "banderlog_negative",
    key: "banderlog_negative",
  },
  {
    title: "private",
    dataIndex: "private",
    key: "private",
  },
  {
    title: "public",
    dataIndex: "public",
    key: "public",
  },
  {
    title: "rus",
    dataIndex: "rus",
    key: "rus",
  },
  {
    title: "superprivate",
    dataIndex: "superprivate",
    key: "superprivate",
  },
  {
    title: "ukr",
    dataIndex: "ukr",
    key: "ukr",
  },
  {
    title: "ukr_public",
    dataIndex: "ukr_public",
    key: "ukr_public",
  },
  {
    title: "verified_fio",
    dataIndex: "verified_fio",
    key: "verified_fio",
  },
];

const storageImageColumns = [
  {
    title: "name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "dob",
    dataIndex: "dob",
    key: "dob",
  },
  {
    title: "img_path",
    dataIndex: "img_path",
    key: "img_path",
  },
  {
    title: "collection_name",
    dataIndex: "collection_name",
    key: "collection_name",
  },
];

export const Face: FC<FaceProps> = ({ face }) => (
  <List bordered>
    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>aliases</Typography.Text>
        </div>
        <Table columns={aliasesColumns} dataSource={[face.aliases]} />
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex className={s.list_item} gap={10}>
        <div>
          <Typography.Text mark>candidates</Typography.Text>
        </div>
        <div className={s.wrapper}>
          {face.candidates.map((candidate) => (
            <Candidate key={candidate.id} candidate={candidate} />
          ))}
        </div>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>collection_name</Typography.Text>
        </div>
        <p>{face.collection_name}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>face_bounds</Typography.Text>
        </div>
        <p>{face.face_bounds.join(", ")}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>hidden</Typography.Text>
        </div>
        <p>{String(face.hidden)}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>prob</Typography.Text>
        </div>
        <p>{face.prob}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>source_photo</Typography.Text>
        </div>
        <ImageItem src={face.source_photo} />
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>storage_image</Typography.Text>
        </div>
        <div>
          <Table
            columns={storageImageColumns}
            dataSource={[face.storage_image]}
          />
          <Flex justify="space-between" className={s.list_item}>
            <Typography.Text mark>photo</Typography.Text>
            <ImageItem src={face.storage_image.photo} />
          </Flex>
        </div>
      </Flex>
    </List.Item>
  </List>
);
