import { FC, useCallback } from "react";
import { Alert, Flex } from "antd";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { UserCard } from "src/components/UserCard/UserCard";
import { Text } from "src/ui/Text/Text";
import { ReactComponent as Error } from "src/assets/error.svg";
import { Button } from "src/ui/Button/Button";
import { AppRoute } from "src/app/routes/constants";
import { Link } from "react-router-dom";
import { useLogout } from "@api/auth/mutations/useLogout";
import { CommonModal } from "src/components/CommonModal/CommonModal";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import s from "./MainLayoutUserMenu.module.scss";
import { getIsExpireSoon } from "./utils/getIsExpireSoon";

interface MainLayoutUserMenuProps {
  onCollapse: (collapsed: boolean) => void;
}

export const MainLayoutUserMenu: FC<MainLayoutUserMenuProps> = ({
  onCollapse,
}) => {
  const { data: user } = useCurrentUserQuery();
  const { mutateAsync: logout, isPending: isLogoutPending } = useLogout();

  const { openModal } = useModalMessage();

  const onClose = useCallback(() => {
    onCollapse(true);
  }, [onCollapse]);

  const onLogout = useCallback(() => {
    logout({})
      .then(() => {
        onClose();
      })
      .catch(() => {
        openModal({
          children: (
            <CommonModal
              type="error"
              title="Произошла непредвиденная ошибка!"
              primaryButtonProps={{
                children: "Попробовать снова",
                onClick: onLogout,
              }}
              secondaryButtonProps={{
                children: "Хорошо",
              }}
            />
          ),
        });
      });
  }, [openModal, logout, onClose]);

  if (!user) {
    return null;
  }

  return (
    <Flex vertical gap={16} className={s.menu}>
      <Link to={AppRoute.User} className={s.menu__link}>
        <UserCard user={user} type="flat" size="s" />
      </Link>
      {getIsExpireSoon(user) ? (
        <Alert
          icon={<Error height={12} width={12} />}
          showIcon
          type="error"
          message={
            <Flex vertical>
              <Text variant="label-1">Истекает срок договора</Text>
              <Text variant="body-1">
                Осталось 30 дней. До 24 сентября 2024
              </Text>
            </Flex>
          }
        />
      ) : null}
      <Flex vertical className={s.menu__card} gap={8}>
        <Flex vertical gap={4}>
          <Text color="darkGray" variant="body-1">
            Остаток проверок на балансе
          </Text>
          {user.request_quota ? (
            <Text variant="label-3">
              до {user.request_quota - user.request_usage} проверок
            </Text>
          ) : (
            <Text variant="label-3">Безлимит</Text>
          )}
        </Flex>
        <Flex vertical gap={4}>
          <Text color="darkGray" variant="body-1">
            Совершено проверок
          </Text>
          <Flex gap={4} component="span" align="baseline">
            <Text variant="subheader-4">{user.request_usage}</Text>
            <Text variant="body-1">стандартных проверок</Text>
          </Flex>
          {/* <Flex gap={4} component="span" align="baseline">
            <Text variant="subheader-4">430</Text>
            <Text variant="body-1">полное досье</Text>
          </Flex> */}
        </Flex>
      </Flex>
      {/* <Flex vertical className={s.menu__card} gap={8}>
        <Text variant="subheader-4">Тариф: до 12 000 проверок</Text>
        <Button type="flat" size="small" fullWidth>
          <Text variant="label-1" color="green">
            Увеличить баланс проверок
          </Text>
        </Button>
      </Flex> */}
      <Flex vertical gap={12}>
        <Button type="text" onClick={onClose}>
          <Text variant="body-5">Мои документы</Text>
        </Button>
        <Link to={AppRoute.User} className={s.menu__link} onClick={onClose}>
          <Text variant="body-5">Аккаунт и безопасность</Text>
        </Link>
        <Button type="text" onClick={onLogout} disabled={isLogoutPending}>
          <Text variant="body-5">Выход</Text>
        </Button>
      </Flex>
    </Flex>
  );
};
