import { Layout } from "antd";
import { FC, ReactNode, useRef, useState } from "react";
import { ModalMessageProvider } from "src/ui/Modal/ModalMessageProvider";
import { useClickOutside } from "src/hooks/useClickOutside";
import s from "./MainLayout.module.scss";
import { MainLayoutHeader } from "./components/MainLayoutHeader/MainLayoutHeader";
import { MainLayoutAside } from "./components/MainLayoutAside/MainLayoutAside";
import { PasswordContextProvider } from "./components/PasswordContext";
import { MainLayoutUser } from "./components/MainLayoutUser/MainLayoutUser";

const { Content } = Layout;

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [leftCollapsed, setLeftCollapsed] = useState(true);
  const [rightCollapsed, setRightCollapsed] = useState(true);
  const [password, setPassword] = useState<string>("");
  const userButtonRef = useRef<HTMLButtonElement>(null);

  const { setWrapperRef } = useClickOutside({
    clickOutsideFn: (event: MouseEvent) => {
      if (!userButtonRef.current?.contains(event.target as Node)) {
        setRightCollapsed(true);
      }
    },
  });

  return (
    <PasswordContextProvider value={{ password, setPassword }}>
      <ModalMessageProvider>
        <Layout className={s.layout}>
          <MainLayoutHeader
            userButtonRef={userButtonRef}
            leftCollapsed={leftCollapsed}
            onLeftCollapse={setLeftCollapsed}
            rightCollapsed={rightCollapsed}
            onRightCollapse={setRightCollapsed}
          />
          <Layout>
            <MainLayoutAside
              collapsed={leftCollapsed}
              onCollapse={setLeftCollapsed}
            />
            <Content className={s.content}>{children}</Content>
            <MainLayoutUser
              collapsed={rightCollapsed}
              onCollapse={setRightCollapsed}
              setWrapperRef={setWrapperRef}
            />
          </Layout>
        </Layout>
      </ModalMessageProvider>
    </PasswordContextProvider>
  );
};
