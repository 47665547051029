import { useParams } from "react-router";
import {
  normalizeReportsFullRetrieve,
  useReportsInnUlRetrieve,
} from "@api/report";
import { SearchFull } from "@entities/SearchFull/SearchFull";
import { Skeleton } from "antd";
import { ReportCommonProps } from "@entities/types";

export const SearchInnUlWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: innUlReport, isLoading } = useReportsInnUlRetrieve({
    id: reportId,
  });

  return (
    <Skeleton loading={isLoading}>
      {innUlReport && (
        <SearchFull
          report={normalizeReportsFullRetrieve(innUlReport)}
          type={type}
        />
      )}
    </Skeleton>
  );
};
