import { useEffect, useRef, useState } from "react";

type OutsideClickParams = {
  callback?: () => void;
};

export const useOutsideClick = ({ callback }: OutsideClickParams) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    // Если клик произошел вне элемента, скрыть его
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback?.();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return { ref };
};
