import { client } from "@api/client";

import { ReportsEndpoint } from "../endpoints";
import {
  ReportsAddressSearchRequest,
  ReportsCreditRatingSearchRequest,
  ReportsDeleteRequest,
  ReportsDriverLicenseSearchRequest,
  ReportsEmailSearchRequest,
  ReportsExportCsvRequest,
  ReportsFioMultiSearchRequest,
  ReportsFioSearchRequest,
  ReportsGrnSearchRequest,
  ReportsInfoSearchRequest,
  ReportsInnFlSearchRequest,
  ReportsInnUlSearchRequest,
  ReportsOgrnSearchRequest,
  ReportsPassportSearchRequest,
  ReportsPhoneSearchRequest,
  ReportsPhotoByFaceSearchRequest,
  ReportsPhotoByFioSearchRequest,
  ReportsScoringSearchRequest,
  ReportsSnilsSearchRequest,
  ReportsTotalCountRequest,
  ReportsVinSearchRequest,
} from "./request";
import {
  ReportsSearchResponse,
  ReportsDeleteResponse,
  ReportsExportCsvResponse,
  ReportsFioMultiSearchResponse,
  ReportsTotalCountResponse,
} from "./response";

export const reportsAddressSearch = (data: ReportsAddressSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.AddressSearch,
    data,
  );

export const reportsCreditRatingSearch = (
  data: ReportsCreditRatingSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.CreditRatingSearch,
    data,
  );

export const reportsDelete = (data: ReportsDeleteRequest) =>
  client.post<undefined, ReportsDeleteResponse>(ReportsEndpoint.Delete, data);

export const reportsEmailSearch = (data: ReportsEmailSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.EmailSearch,
    data,
  );

export const reportsExportCsv = (data: ReportsExportCsvRequest) =>
  client.post<undefined, ReportsExportCsvResponse>(
    ReportsEndpoint.ExportCsv,
    data,
  );

export const reportsFioMultiSearch = (data: ReportsFioMultiSearchRequest) =>
  client.post<undefined, ReportsFioMultiSearchResponse>(
    ReportsEndpoint.FioMultiSearch,
    data,
  );

export const reportsFioSearch = (data: ReportsFioSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.FioSearch,
    data,
  );

export const reportsGrnSearch = (data: ReportsGrnSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.GrnSearch,
    data,
  );

export const reportsDriverLicenseSearch = (
  data: ReportsDriverLicenseSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.DriverLicenseSearch,
    data,
  );

export const reportsInfoSearch = (data: ReportsInfoSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.InfoSearch,
    data,
  );

export const reportsInnFlSearch = (data: ReportsInnFlSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.InnFlSearch,
    data,
  );

export const reportsInnUlSearch = (data: ReportsInnUlSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.InnUlSearch,
    data,
  );

export const reportsOgrnSearch = (data: ReportsOgrnSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.OgrnSearch,
    data,
  );

export const reportsPassportSearch = (data: ReportsPassportSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PassportSearch,
    data,
  );

export const reportsPhoneSearch = (data: ReportsPhoneSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PhoneSearch,
    data,
  );

export const reportsPhotoByFaceSearch = (
  data: ReportsPhotoByFaceSearchRequest,
) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PhotoByFaceSearch,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

export const reportsPhotoByFioSearch = (data: ReportsPhotoByFioSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.PhotoByFioSearch,
    data,
  );

export const reportsTotalCount = (data: ReportsTotalCountRequest) =>
  client.post<undefined, ReportsTotalCountResponse>(
    ReportsEndpoint.TotalCount,
    data,
  );

export const reportsSnilsSearch = (data: ReportsSnilsSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.SnilsSearch,
    data,
  );

export const reportsVinSearch = (data: ReportsVinSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.VinSearch,
    data,
  );

export const reportsScoringSearch = (data: ReportsScoringSearchRequest) =>
  client.post<undefined, ReportsSearchResponse>(
    ReportsEndpoint.ScoringSearch,
    data,
  );
