import { FC, useLayoutEffect, useMemo, useState } from "react";
import { Flex, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { useMainTabSearchParam } from "@pages/Main/hooks/useMainTabSearchParam";
import { useGroups } from "@api/groups";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import s from "./MainLayoutAsideMenu.module.scss";
import { MenuGroup, getMenuItems } from "./constants";

interface MainLayoutAsideMenuProps {
  collapsed: boolean;
}

export const MainLayoutAsideMenu: FC<MainLayoutAsideMenuProps> = ({
  collapsed,
}) => {
  const [activeKey, setActiveKey] = useState<string>("");
  const [tab] = useMainTabSearchParam();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (tab) {
      setActiveKey(MenuGroup.Checking);
    }
    if (pathname === (AppRoute.History as string)) {
      setActiveKey(MenuGroup.Organization);
    }
  }, [tab, pathname]);

  const { data: user } = useCurrentUserQuery();

  // TODO remove it after user always being in group
  const { data: groups } = useGroups();
  const group = groups?.[0];
  // TODO END remove it after backed

  const menuItems = useMemo(
    () => getMenuItems({ group_id: group?.group_id, user_id: user?.id }),
    [group?.group_id, user?.id],
  );

  return (
    <Flex vertical gap={16} className={s.menu}>
      <Menu mode="inline" activeKey={activeKey}>
        {menuItems.map((item) =>
          item.children ? (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.title}>
              {item.children.map((subItem) => {
                const onClick = () => {
                  if (subItem.href) {
                    navigate(subItem.href);
                  }
                };

                return (
                  <Menu.Item
                    key={subItem.key}
                    className={s.item}
                    onClick={onClick}
                  >
                    {subItem.title}
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.key}>
              {collapsed ? null : item.title}
            </Menu.Item>
          ),
        )}
      </Menu>
    </Flex>
  );
};
