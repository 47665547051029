import { Text } from "src/ui/Text/Text";
import { Link } from "react-router-dom";
import { Flex } from "antd";
import { LuChevronLeft } from "react-icons/lu";
import { VscSettings } from "react-icons/vsc";
import { AppRoute } from "../../../app/routes/constants";
import { Button } from "../../../ui/Button/Button";
import s from "./Header.module.scss";

import { BreadCrumps } from "../../../components/BreadCrumps/BreadCrumps";

interface HeaderProps {
  openModalSettings: () => void;
}

export const Header = ({ openModalSettings }: HeaderProps) => (
  <div className={s.container}>
    <Flex vertical gap={8}>
      <Link className={s.link} to={AppRoute.Root}>
        <Flex gap={4} align="center">
          <Button type="flat" pin="round">
            <LuChevronLeft size={24} />
          </Button>
          <Text variant="header-8">Главная страница</Text>
        </Flex>
      </Link>
      <BreadCrumps
        mapPathToTitle={{ [AppRoute.Analytics]: "Аналитика" }}
        paths={[AppRoute.Analytics]}
      />
    </Flex>

    <Button
      type="secondary"
      className={s.container__button}
      onClick={openModalSettings}
    >
      <VscSettings />
      Настроить временной отрезок
    </Button>
  </div>
);
