export enum ReportsPersonInfoDataEnum {
  full_name = "full_name",
  inn = "inn",
  passport = "passport",
  phone = "phone",
  snils = "snils",
  source_year = "source_year",
}

export const reportsPersonInfoDataWithTitles: Record<
  ReportsPersonInfoDataEnum,
  string
> = {
  [ReportsPersonInfoDataEnum.full_name]: "Полное имя",
  [ReportsPersonInfoDataEnum.inn]: "Инн",
  [ReportsPersonInfoDataEnum.passport]: "Паспорт",
  [ReportsPersonInfoDataEnum.phone]: "Телефон",
  [ReportsPersonInfoDataEnum.snils]: "СНИЛС",
  [ReportsPersonInfoDataEnum.source_year]: "Год",
};
