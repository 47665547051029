import { Flex, Image } from "antd";
import { FC, RefObject, useCallback, useState } from "react";
import { Text } from "src/ui/Text/Text";
import { Input } from "src/ui/Input/Input";
import { FaCircleUser, FaBell } from "react-icons/fa6";
import { Header } from "antd/es/layout/layout";
import { Button } from "src/ui/Button/Button";
import { IoMenu, IoClose, IoChevronBack } from "react-icons/io5";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { Link } from "react-router-dom";
import { AppRoute } from "src/app/routes/constants";
import { useUsersProfilePictureRetrieveQuery } from "@api/groups/mutations/useUsersProfilePictureRetrieveQuery";
import { useBooleanState } from "src/hooks/useBooleanState";
import classNames from "classnames";
import s from "./MainLayoutHeader.module.scss";
import logoImage from "../../../../../assets/logo.png";
import { MainLayoutHeaderSearch } from "./components/MainLayoutHeaderSearch/MainLayoutHeaderSearch";

interface MainLayoutHeaderProps {
  userButtonRef: RefObject<HTMLButtonElement>;
  leftCollapsed: boolean;
  rightCollapsed: boolean;
  onLeftCollapse: (collapsed: boolean) => void;
  onRightCollapse: (collapsed: boolean) => void;
}

export const MainLayoutHeader: FC<MainLayoutHeaderProps> = ({
  userButtonRef,
  leftCollapsed,
  rightCollapsed,
  onLeftCollapse,
  onRightCollapse,
}) => {
  const [filter, setFilter] = useState("");
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
    isRefetching: isUserRefetching,
  } = useCurrentUserQuery();
  const { openModal, closeModal } = useModalMessage();

  const toggleLeftCollapse = useCallback(() => {
    onLeftCollapse(!leftCollapsed);
  }, [leftCollapsed, onLeftCollapse]);

  const onUserClick = useCallback(() => {
    if (user && isUserSuccess) {
      onRightCollapse(!rightCollapsed);
    } else {
      openModal({
        children: <AuthForm onSuccess={closeModal} onClose={closeModal} />,
        maskClosable: false,
        closable: false,
      });
    }
  }, [
    rightCollapsed,
    onRightCollapse,
    user,
    isUserSuccess,
    openModal,
    closeModal,
  ]);

  const { data: photo } = useUsersProfilePictureRetrieveQuery(
    { user_id: user?.id || 0 },
    { enabled: Boolean(user) },
  );

  const {
    state: searchVisible,
    setTrue: showSearch,
    setFalse: hideSearch,
  } = useBooleanState();

  return (
    <Header className={s.header}>
      <Flex className={s.header__left_container} gap={32}>
        {searchVisible ? (
          <>
            <Flex align="center" justify="center" gap={16}>
              <Button
                type="flat"
                pin="round"
                icon={<IoChevronBack size={24} />}
                onClick={hideSearch}
              />
            </Flex>
            <div className={s.header__overlay} onClick={hideSearch} />
          </>
        ) : (
          <Flex align="center" justify="center" gap={16}>
            <Button
              type="flat"
              pin="round"
              icon={
                leftCollapsed ? <IoMenu size={24} /> : <IoClose size={24} />
              }
              onClick={toggleLeftCollapse}
            />
          </Flex>
        )}
      </Flex>
      {searchVisible ? null : (
        <Flex className={s.header__center_container}>
          <Link to={AppRoute.Lk} className={s.header__link}>
            <Flex align="center" justify="center" gap={8}>
              <Image src={logoImage} preview={false} alt="logo" />
              <Text variant="subheader-1">ODYSSEY SEARCH</Text>
            </Flex>
          </Link>
        </Flex>
      )}
      <Flex
        className={classNames(s.header__right_container, {
          [s.header__right_container_wide]: searchVisible,
        })}
        gap={32}
      >
        <Flex vertical className={s.header__right_container__wrapper}>
          <Input
            placeholder="Поиск по системе"
            value={filter}
            onUpdate={setFilter}
            onFocus={showSearch}
            disabled={!user}
          />
          {filter && searchVisible ? (
            <MainLayoutHeaderSearch query={filter} onClose={hideSearch} />
          ) : null}
        </Flex>
        <Flex align="center" justify="center" gap={16}>
          <Button type="flat" pin="round" icon={<FaBell size={24} />} />
          <Button
            ref={userButtonRef}
            type="flat"
            pin="round"
            disabled={isUserLoading || isUserRefetching}
            onClick={onUserClick}
            icon={
              isUserSuccess && photo ? (
                <Image
                  rootClassName={s.header__avatar}
                  preview={false}
                  src={photo}
                  alt={`${user?.id} avatar`}
                  height={24}
                  width={24}
                />
              ) : (
                <FaCircleUser size={24} />
              )
            }
          />
        </Flex>
      </Flex>
    </Header>
  );
};
