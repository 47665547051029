import { MdNorthEast } from "react-icons/md";
import { Text } from "../../ui/Text/Text";
import s from "./PageSearchPointer.module.scss";
import { useCallback } from "react";
import { useNotification } from "../../hooks/useNotification";
import { useOutsideClick } from "../../hooks/useOutsideClick";

type PageSearchPointerProps = {
  title: string;
};

// Функция для выделения текста
const highlightMatches = (searchTerm: string) => {
  if (!searchTerm) return;

  // Создаем регулярное выражение для поиска
  const regex = new RegExp(`(${searchTerm})`, "gi");

  // Ищем все текстовые узлы в теле страницы
  const walker = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_TEXT,
    null
  );

  const nodesToReplace: Text[] = [];
  let currentNode: Node | null;

  // Собираем все текстовые узлы
  while ((currentNode = walker.nextNode())) {
    const textNode = currentNode as Text;
    if (regex.test(textNode.textContent || "")) {
      nodesToReplace.push(textNode);
    }
  }

  // Заменяем найденные вхождения на span с подсветкой
  nodesToReplace.forEach((textNode) => {
    const span = document.createElement("span");
    span.innerHTML =
      textNode.textContent?.replace(regex, "<mark>$1</mark>") || "";
    textNode.replaceWith(span);
  });
};

// Функция для удаления всех выделений
const clearHighlights = () => {
  const markedElements = document.querySelectorAll("mark");

  // Удаляем обертку <mark> и восстанавливаем оригинальный текст
  markedElements.forEach((mark) => {
    const parent = mark.parentNode;
    if (parent) {
      parent.replaceChild(
        document.createTextNode(mark.textContent || ""),
        mark
      );
      parent.normalize(); // Объединяет смежные текстовые узлы
    }
  });
};

export const PageSearchPointer = ({ title }: PageSearchPointerProps) => {
  const [search] = useNotification();

  const { ref } = useOutsideClick({
    callback: () => search.destroy(title),
  });

  const handleSearch = useCallback(() => {
    clearHighlights();
    if (title.trim()) {
      highlightMatches(title);
      search.open({ key: title, message: title, duration: null });
    }
  }, []);

  return (
    <div className={s.container} onClick={handleSearch} ref={ref}>
      <MdNorthEast />
      <Text variant="body-5">{title}</Text>
    </div>
  );
};
