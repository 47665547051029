import { client } from "@api/client";

import { endpoints } from "./endpoints";
import {
  GroupStatisticsRequest,
  GroupStatisticsResponse,
  StatisticsRequest,
  StatisticsResponse,
} from "./types";

export const getStatistics = <T>(data: StatisticsRequest<T>) =>
  client.post<undefined, StatisticsResponse, StatisticsRequest<T>>(
    endpoints.STATISTICS,
    data,
  );

export const getGroupStatistics = <T>(data: GroupStatisticsRequest<T>) =>
  client.post<
    GroupStatisticsResponse,
    GroupStatisticsResponse,
    GroupStatisticsRequest<T>
  >(endpoints.GROUPS_STATISTICS, data);
