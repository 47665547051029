import { FC, PropsWithChildren } from "react";
import cn from "classnames";
import s from "./Block.module.scss";

export interface BlockProps extends PropsWithChildren {
  className?: string;
}

export const Block: FC<BlockProps> = ({ children, className }) => (
  <div className={cn(s.block, className)}>{children}</div>
);
