import { FC } from "react";
import { Flex } from "antd";
import { Text } from "src/ui/Text/Text";
import { Group } from "@api/groups";
import { AllowedIpsInput } from "src/components/AllowedIpsInput/AllowedIpsInput";
import { UserPassword } from "./components/UserPassword/UserPassword";
import s from "./UserSettingsSecurity.module.scss";
import { UserInaction } from "./components/UserInaction/UserInaction";
import { UserNonRussianIpAllowed } from "./components/UserNonRussianIpAllowed/UserNonRussianIpAllowed";

interface UserSettingsSecurityProps {
  group: Group;
}

export const UserSettingsSecurity: FC<UserSettingsSecurityProps> = ({
  group,
}) => (
  <Flex className={s.block} vertical gap={32}>
    <Text variant="header-6">Безопасность аккаунтов</Text>
    <UserPassword />
    <Flex vertical gap={16}>
      <Text variant="subheader-4">IP адреса доступа к аккаунту</Text>
      <Flex vertical gap={8}>
        <Flex vertical gap={4}>
          <Text variant="body-5" color="darkGray">
            Ограничения по IP
          </Text>
          <AllowedIpsInput group={group} />
        </Flex>
        <UserNonRussianIpAllowed group={group} />
      </Flex>
    </Flex>
    <UserInaction group={group} />
  </Flex>
);
