import {
  CurrentUser,
  Groups,
  Search,
  TwoFactorEdit,
  Photo,
} from "@features/Admin";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { FC } from "react";
import s from "./Admin.module.scss";

const items: TabsProps["items"] = [
  {
    key: "Groups",
    label: "Группы",
    children: <Groups />,
  },
  {
    key: "Search",
    label: "Поиск",
    children: <Search />,
  },
  {
    key: "CurrentUser",
    label: "Данные пользователя",
    children: <CurrentUser />,
  },
  {
    key: "TwoFactorEdit",
    label: "настройка двухфакторной авторизации",
    children: <TwoFactorEdit />,
  },
  {
    key: "Photo",
    label: "Фото",
    children: <Photo />,
  },
];

export const Admin: FC = () => (
  <div className={s.wrapper}>
    <Tabs defaultActiveKey="Groups" items={items} className={s.tab_body} />
  </div>
);
