import React, { memo } from "react";
import s from "./Common.module.scss";
import { Text } from "../../ui/Text/Text";
import {
  Indicator,
  IndicatorCommonProps,
  IndicatorModel,
} from "../Indicator/Indicator";

export type CommonProps = IndicatorCommonProps & {
  getIndicatorModel: (marker?: number) => IndicatorModel;
};

export const Common = memo(({ marker, getIndicatorModel }: CommonProps) => {
  const indicatorModel = getIndicatorModel(marker);
  return (
    <div className={s.info}>
      <div className={s.info__indicator}>
        <Text variant="body-1" color="darkGray">
          Общий показатель
        </Text>
        <Indicator
          marker={marker}
          text={indicatorModel.text}
          color={indicatorModel.color}
          indicatorIcon={indicatorModel.indicatorIcon}
        />
      </div>
    </div>
  );
});
