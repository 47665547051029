import { useState, useCallback } from "react";

export function useBooleanState(initialState = false) {
  const [state, setState] = useState<boolean>(initialState);

  const setTrue = useCallback(() => {
    setState(true);
  }, []);
  const setFalse = useCallback(() => {
    setState(false);
  }, []);
  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return {
    state,
    setTrue,
    setFalse,
    toggle,
  } as const;
}
