import {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  CSSProperties,
  ElementType,
  forwardRef,
  PropsWithChildren,
  ReactElement,
} from "react";
import cn from "classnames";
import s from "./Text.module.scss";

const TEXT_VARIANTS = [
  "label-5",
  "label-3",
  "label-1",
  "header-13",
  "header-12",
  "header-11",
  "header-10",
  "header-9",
  "header-8",
  "header-7",
  "header-6",
  "header-5",
  "header-4",
  "header-3",
  "header-2",
  "header-1",
  "subheader-5",
  "subheader-4",
  "subheader-3",
  "subheader-2",
  "subheader-1",
  "body-7",
  "body-6",
  "body-5",
  "body-4",
  "body-3",
  "body-2",
  "body-1",
] as const;

const TEXT_COLORS = [
  "primary",
  "white",
  "green",
  "blue",
  "darkGray",
  "aqua",
  "red",
  "yellow",
] as const;

export type TextColor =
  | "primary"
  | "white"
  | "green"
  | "blue"
  | "darkGray"
  | "aqua"
  | "red"
  | "yellow";

export interface TextProps<C extends ElementType = "span">
  extends PropsWithChildren {
  /**
   * Ability to override default html tag
   */
  as?: C;
  style?: CSSProperties;
  className?: string;
  /**
   * - body:
   *      - 1: font-size: 12px, line-height: 16px, font-weight: 400;
   *      - 2: font-size: 14px, line-height: 16px, font-weight: 400; (**Default variant**)
   *      - 3: font-size: 14px, line-height: 18px, font-weight: 400;
   *      - 4: font-size: 14px, line-height: 20px, font-weight: 400;
   *      - 5: font-size: 16px, line-height: 24px, font-weight: 400;
   *      - 6: font-size: 20px, line-height: 28px, font-weight: 400;
   *      - 7: font-size: 32px, line-height: 35.2px, font-weight: 400;
   * - header:
   *      - 1: font-size: 16px; line-height: 20px; font-weight: 400;
   *      - 2: font-size: 18px; line-height: 20px; font-weight: 400;
   *      - 3: font-size: 20px; line-height: 16px; font-weight: 400;
   *      - 4: font-size: 20px; line-height: 24px; font-weight: 400;
   *      - 5: font-size: 20px; line-height: 32px; font-weight: 400;
   *      - 6: font-size: 24px; line-height: 36px; font-weight: 400;
   *      - 7: font-size: 30px; line-height: 36px; font-weight: 400;
   *      - 8: font-size: 32px; line-height: 36px; font-weight: 400;
   *      - 9: font-size: 36px; line-height: 36px; font-weight: 400;
   *     - 10: font-size: 40px; line-height: 48px; font-weight: 400;
   *     - 11: font-size: 44px; line-height: 48px; font-weight: 400;
   *     - 12: font-size: 54px; line-height: 56px; font-weight: 400;
   *     - 13: font-size: 65px; line-height: 56px; font-weight: 400;
   * - subheader:
   *      - 1: font-size: 14px; line-height: 16px; font-weight: 500;
   *      - 2: font-size: 14px; line-height: 21px; font-weight: 500;
   *      - 3: font-size: 16px; line-height: 24px; font-weight: 500;
   *      - 4: font-size: 18px; line-height: 20px; font-weight: 500;
   *      - 5: font-size: 24px; line-height: 36px; font-weight: 700;
   * - label:
   *      - 1: font-size: 12px; line-height: 14px; font-weight: 600;
   *      - 3: font-size: 16px; line-height: 22px; font-weight: 600;
   *      - 5: font-size: 18px; line-height: 20px; font-weight: 600;
   */
  variant?: (typeof TEXT_VARIANTS)[number] | "inherit";
  color?: (typeof TEXT_COLORS)[number];
  /**
   * hidden overflow content will be displayed with ellipsis `…`
   *
   * - white-space: nowrap;
   * - overflow: hidden;
   * - text-overflow: ellipsis;
   */
  ellipsis?: boolean;
  ellipsisLines?: number;
  align?: CSSProperties["textAlign"];
  underline?: boolean;
}

type TextRef<C extends ElementType> = ComponentPropsWithRef<C>["ref"];

type TextPropsWithoutRef<C extends ElementType> = TextProps<C> &
  Omit<ComponentPropsWithoutRef<C>, keyof TextProps<C>>;

/**
 * A component for working with typography.
 *
 * **Hint:** Hover on props in your editor to read jsdoc
 *
 * Provides a convenient API for working with mixins of typography and text colors.
 * Just point at the prop in you favorite code editor and read the accompanying documentation
 * via `jsdoc` on where to apply this or that font or color.
 *
 * ```jsx
 * <Text variant="body-1" color="inherit" ellipsis>some test</Text>
 * ```
 */
export const Text = forwardRef(
  <C extends ElementType = "span">(
    {
      as,
      children,
      variant = "body-2",
      className,
      ellipsis,
      ellipsisLines,
      color,
      style = {},
      align,
      underline,
      ...rest
    }: TextPropsWithoutRef<C>,
    ref?: TextRef<C>,
  ) => {
    const Tag: ElementType = as || "span";

    if (typeof ellipsisLines === "number") {
      // eslint-disable-next-line no-param-reassign
      style.WebkitLineClamp = ellipsisLines;
    }

    return (
      <Tag
        ref={ref}
        className={cn(
          className,
          s.text,
          variant ? s[`text_variant_${variant}`] : "",
          ellipsis ? s.text_ellipsis : "",
          color ? s[`text_color_${color}`] : "",
          underline ? s.text_underline : "",
          typeof ellipsisLines === "number" ? s.text_ellipsis_lines : "",
        )}
        style={{ ...style, textAlign: align }}
        {...rest}
      >
        {children}
      </Tag>
    );
  },
) as (<C extends ElementType = "span">({
  _ref,
  ..._props
}: TextPropsWithoutRef<C> & { ref?: TextRef<C> }) => ReactElement) & {
  displayName: string;
};

Text.displayName = "Text";
