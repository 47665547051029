import { useCallback, useState } from "react";
import { Text } from "src/ui/Text/Text";

import { Radio } from "antd";
import { intervals, intervalsOrder } from "@pages/Analytics/constants";
import { Interval } from "@pages/Analytics/types";
import { Button } from "../../../../ui/Button/Button";
import s from "./SettingsModal.module.scss";

export interface SettingsModalProps {
  intervalValue: Interval;
  onChangeIntervalValue: (interval: Interval) => void;
  closeModal: () => void;
}

export const SettingsModal = ({
  intervalValue,
  onChangeIntervalValue,
  closeModal,
}: SettingsModalProps) => {
  const [intervalModalValue, setIntervalModalValue] =
    useState<Interval>(intervalValue);

  const submit = useCallback(() => {
    onChangeIntervalValue(intervalModalValue);
    closeModal();
  }, [intervalModalValue, closeModal, onChangeIntervalValue]);

  return (
    <div className={s.container}>
      <div className={s.container__title}>
        <Text variant="header-6">Настройки отображения</Text>
      </div>
      <div className={s.container__content}>
        <Text variant="subheader-4">Показывать добавленные в папки:</Text>
        {intervalsOrder.map((interval: Interval) => (
          <Radio
            key={interval}
            checked={intervalModalValue === interval}
            onChange={(e) => {
              if (e.target.checked) setIntervalModalValue(interval);
            }}
          >
            {intervals[interval]}
          </Radio>
        ))}
      </div>
      <div className={s.container__buttons}>
        <Button type="secondary">Очистить фильтры</Button>
        <Button onClick={submit}>Применить</Button>
      </div>
    </div>
  );
};
