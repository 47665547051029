import { Text } from "src/ui/Text/Text";
import cn from "classnames";
import { ReportCommonProps } from "@entities/types";
import { ReportType } from "@api/report/constants";
import { PersonInfo } from "@api/report";
import { Column } from "./Column/Column";
import s from "./FullDossier.module.scss";
import { mapReportTypeToLabel } from "../../utils/reports/mapReportTypeToLabel";
import { PhotoGallery } from "../PhotoGallery/PhotoGallery";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";

export type FullDossierProps = PersonInfo &
  ReportCommonProps & {
    requestDescribe?: string;
    photos?: string[] | null;
  } & RetrieveCardCommonProps;

export const FullDossier = ({
  phones,
  snilses,
  persons,
  inns,
  passports,
  citizens,
  other_last_names,
  emails,
  autos,
  photos,
  requestDescribe,
  type,
  status,
}: FullDossierProps) => {
  const label = mapReportTypeToLabel[type];

  return (
    <RetrieveCard
      title="Краткая сводка"
      status={status}
      header={
        <div className={s.container__header}>
          <Text color="aqua">{label}</Text>
          {requestDescribe && (
            <div>
              <Text variant="subheader-5" className={s.container__header__name}>
                Введенные данные запроса
                <br />
                {requestDescribe}
              </Text>
            </div>
          )}
        </div>
      }
    >
      <div className={cn(s.container__content__row)}>
        {type === ReportType.SEARCH_FIO ||
        type === ReportType.SEARCH_PHOTO_BY_IMAGE ? (
          <div
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          >
            <Text variant="label-1" color="darkGray">
              Фотографии
            </Text>
            {photos && photos.length > 0 ? (
              <PhotoGallery srcImages={photos} />
            ) : (
              "Не найдено"
            )}
          </div>
        ) : (
          <Column
            title="Лица"
            items={persons?.map(
              (person) =>
                `${person.last_name} ${person.first_name} ${person.middle_name}`
            )}
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          />
        )}
        {type !== ReportType.SEARCH_PHONE && (
          <Column
            title="Номера телефонов"
            items={phones}
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          />
        )}
        {type !== ReportType.SEARCH_SNILS && (
          <Column
            title="СНИЛС"
            items={snilses}
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          />
        )}
        {type !== ReportType.SEARCH_INN_FL &&
          type !== ReportType.SEARCH_INN_UL && (
            <Column
              title="ИНН"
              items={inns}
              className={cn(
                s.container__content__row__column,
                s.container__content__row__column__border
              )}
            />
          )}
        {type !== ReportType.SEARCH_PASSPORT && (
          <Column
            title="Паспорт"
            items={passports}
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          />
        )}
        <Column
          title="Двойное гражданство"
          items={citizens}
          className={cn(
            s.container__content__row__column,
            s.container__content__row__column__border
          )}
        />
        <Column
          title="Прошлые фамилии"
          items={other_last_names}
          className={cn(
            s.container__content__row__column,
            s.container__content__row__column__border
          )}
        />
        {type !== ReportType.SEARCH_EMAIL && (
          <Column
            title="E-mail"
            items={emails}
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          />
        )}
        {type !== ReportType.SEARCH_GRN && type !== ReportType.SEARCH_VIN && (
          <Column
            title="Автомобили"
            items={autos}
            className={cn(
              s.container__content__row__column,
              s.container__content__row__column__border
            )}
          />
        )}
      </div>
    </RetrieveCard>
  );
};
