import React from "react";

import { OrderMapItem, PhoneBookItem } from "@api/report";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";
import { YandexMap } from "src/components/YandexMap/YandexMap";

export type PhoneBookProps = {
  order_map?: OrderMapItem[];
} & RetrieveCardCommonProps;

export const OrderMap = ({ order_map, status }: PhoneBookProps) => (
  <RetrieveCard title="Карта заказов" status={status}>
    <YandexMap />
  </RetrieveCard>
);
