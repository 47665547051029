import {
  StatusPaper,
  StatusPaperProps,
} from "../../components/StatusPaper/StatusPaper";
import s from "./RetrieveStatus.module.scss";
import { PropsWithChildren } from "react";

export const RetrieveStatus = ({
  status,
  children,
}: PropsWithChildren<StatusPaperProps>) => (
  <div className={s.container}>
    <StatusPaper status={status} />
    {children}
  </div>
);
