import { CurrentUser } from "@api/groups";
import dayjs from "dayjs";

const ONE_MONTH_MS = 2592000000;

export const getIsExpireSoon = (user: CurrentUser) => {
  const nextMonthMs = Date.now() + ONE_MONTH_MS;

  const expiresAtNum = dayjs(user.expires_at, "DD.MM.YYYY hh:mm:ss").unix();
  const expiresAt = Number.isInteger(expiresAtNum) ? expiresAtNum : nextMonthMs;

  return expiresAt > nextMonthMs;
};
