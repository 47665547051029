import { createContext } from "react";
import { ModalPickedProps } from "./types";

interface ModalMessageProps {
  openModal: (newModalProps: ModalPickedProps) => void;
  closeModal: () => void;
}

export const ModalMessageContext = createContext<ModalMessageProps>({
  openModal: () => {},
  closeModal: () => {},
});
