import { FC, ReactNode, useCallback, useState } from "react";
import { Dropdown as AntdDropdown, MenuProps } from "antd";

import { DropdownProps as AntdDropdownProps } from "antd/lib";
import cn from "classnames";
import s from "./Dropdown.module.scss";

interface DropdownBodyProps {
  onOpen: () => void;
}

interface DropdownProps {
  body: ({ onOpen }: DropdownBodyProps) => ReactNode;
  items: MenuProps["items"];
  placement?: AntdDropdownProps["placement"];
  overlayClassName?: AntdDropdownProps["overlayClassName"];
  onOpenChange?: AntdDropdownProps["onOpenChange"];
  mouseLeaveDelay?: AntdDropdownProps["mouseLeaveDelay"];
}

export const Dropdown: FC<DropdownProps> = ({
  body,
  overlayClassName,
  items,
  onOpenChange,
  mouseLeaveDelay,
  placement,
}) => {
  const [isActive, toggleActive] = useState(false);

  const onOpen = useCallback(() => {
    toggleActive(true);
  }, []);

  return (
    <AntdDropdown
      open={isActive}
      trigger={["click"]}
      placement={placement}
      overlayClassName={cn(s.dropdown, overlayClassName)}
      menu={{ items }}
      mouseLeaveDelay={mouseLeaveDelay}
      onOpenChange={(open, info) => {
        toggleActive(open);
        onOpenChange?.(open, info);
      }}
    >
      {body({ onOpen })}
    </AntdDropdown>
  );
};
