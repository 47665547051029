import { Flex } from "antd";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { ReportsFoundPersons } from "src/components/ReportsFoundPersons/ReportsFoundPersons";
import { RelatedPersons } from "src/components/RelatedPersons/RelatedPersons";
import { Data } from "src/components/Data/Data";
import { ReportCommonProps } from "@entities/types";
import { ReportsFullRetrieve } from "@api/report/retrieve/retrieveFull";
import { OpenedData } from "./components/OpenedData/OpenedData";
import { Addresses } from "./components/Addresses/Addresses";
import { CrossingBordersSection } from "./components/CrossingBorders/CrossingBordersSection";
import { Scoring } from "src/components/Scoring/Scoring";
import { Incomes } from "./components/Incomes/Incomes";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import React from "react";
import { calculateReportStatus } from "../../utils/getReportStatus";

export type SearchFullProps = {
  report: ReportsFullRetrieve;
} & ReportCommonProps;

export const SearchFull = ({ report, type }: SearchFullProps) => (
  <RetrieveStatus
    status={
      calculateReportStatus([
        report?.person?.status,
        report?.sub_reports?.status,
        report?.crossing_borders?.status,
      ]) || report?.status
    }
  >
    <Flex vertical gap={32}>
      {report?.person?.status && (
        <FullDossier
          phones={report?.person?.person_info?.phones}
          snilses={report?.person?.person_info?.snilses}
          inns={report?.person?.person_info?.inns}
          passports={report?.person?.person_info?.passports}
          citizens={report?.person?.person_info?.citizens}
          other_last_names={report?.person?.person_info?.other_last_names}
          emails={report?.person?.person_info?.emails}
          requestDescribe={report?.request_describe}
          persons={report?.person?.person_info?.persons}
          autos={report?.person?.person_info?.autos}
          photos={report?.photo?.photos}
          type={type}
          status={report?.person?.status}
        />
      )}
      {report?.sub_reports?.status && (
        <ReportsFoundPersons
          report_list={report?.sub_reports?.report_list}
          status={report?.sub_reports?.status}
        />
      )}
      {report?.open_sources && (
        <OpenedData openSources={report?.open_sources} />
      )}
      {report?.crossing_borders?.status && (
        <CrossingBordersSection
          crossingBorders={report?.crossing_borders}
          status={report?.crossing_borders?.status}
        />
      )}
      {report?.person?.status && (
        <RelatedPersons
          possible_networks={report?.person?.person_info?.possible_networks}
          status={report?.person?.status}
        />
      )}
      {report?.person?.status && (
        <Addresses
          addresses={report?.person?.person_info?.addresses}
          status={report?.person?.status}
        />
      )}
      {report?.person?.status && (
        <Incomes
          incomes={report?.person?.person_info?.incomes}
          status={report?.person?.status}
        />
      )}
      {report?.person?.status && (
        <Scoring
          scoringResult={report?.person?.person_info?.scoring_result}
          status={report?.person?.status}
        />
      )}
      {report?.person?.person_info?.data?.map(
        (reportsPersonInfoData, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Data key={index} reportsPersonInfoData={reportsPersonInfoData} />
        )
      )}
    </Flex>
  </RetrieveStatus>
);
