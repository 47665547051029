import React, { useCallback } from "react";
import { PossibleNetwork } from "@api/report";
import { Text } from "../../ui/Text/Text";
import { Button } from "../../ui/Button/Button";
import { pluralize } from "../../utils/pluralize";
import { useModalMessage } from "../../ui/Modal/useModalMessage";
import {
  RelatedPersonsModal,
  RelatedPersonsProps,
} from "./RelatedPersonsModal/RelatedPersonsModal";
import s from "./RelatedPersons.module.scss";
import {
  RetrieveCard,
  RetrieveCardCommonProps,
} from "src/components/RetrieveCard/RetrieveCard";

export const RelatedPersons = ({
  possible_networks,
  status,
}: RelatedPersonsProps & RetrieveCardCommonProps) => {
  const { openModal } = useModalMessage();

  const groups: Map<string, PossibleNetwork[]> | undefined =
    possible_networks?.reduce((acc, item) => {
      const current = acc?.get(item.source);

      if (current) {
        acc.set(item.source, [...current, item]);
      } else {
        acc.set(item.source, [item]);
      }

      return acc;
    }, new Map<string, PossibleNetwork[]>());

  const groupsArray = Array.from(groups?.entries() || []);

  const show = useCallback(
    (relatedPersonsProps: RelatedPersonsProps) =>
      openModal({
        children: (
          <RelatedPersonsModal
            possible_networks={relatedPersonsProps.possible_networks}
          />
        ),
        maskClosable: true,
      }),
    [openModal]
  );

  return (
    <RetrieveCard title="Связанные лица" status={status}>
      <div className={s.container}>
        <Text color="darkGray" variant="body-1">
          Источник связи
        </Text>
        <Text color="darkGray" variant="body-1">
          Количество связей
        </Text>

        {groupsArray.map((group) => (
          <>
            <Text variant="body-5" key={group[0]}>
              {group[0]}
            </Text>
            <div>
              <Text variant="body-5" key={`${group[0]}-length`}>
                {group[1]?.length}{" "}
                {pluralize({
                  count: group[1]?.length,
                  oneLabel: "связанное лицо",
                  fromTwoTillFourLabel: "связанных лица",
                  label: "связанных лиц",
                })}
              </Text>
              <Button
                type="text_secondary"
                onClick={() => show({ possible_networks: group[1] })}
              >
                Показать
              </Button>
            </div>
          </>
        ))}
      </div>
    </RetrieveCard>
  );
};
