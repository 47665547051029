import { FoundStatus, ReportStatus } from "../constants";

export interface ReportsOpenSourcesIoSphere {
  data: {
    source: string;
    found: number;
  }[];
  status: ReportStatus;
}

export interface ReportsOpenSourcesLeakCheck {
  sources: string[];
  found: number;
  status: ReportStatus;
}

export interface Check {
  status: ReportStatus;
  found: FoundStatus;
  value?: string;
}

export interface OpenSourcesFio {
  inn_check: Check;
  ip_check: Check;
  ul_check: Check;
  ul_restriction_check: Check;
  disqualified_check: Check;
  bank_ruptcy_check: Check;
  enforcement_proceedings_check: Check;
  self_employment_check: Check;
}

export type OpenSourcesModel = OpenSourcesFio | ReportsOpenSourcesIoSphere;

export const isReportsOpenSourcesIoSphere = (
  data: OpenSourcesModel,
): data is ReportsOpenSourcesIoSphere =>
  Object.hasOwn(data, "data") && Object.hasOwn(data, "status");

export interface OpenSources {
  iosphere?: ReportsOpenSourcesIoSphere;
}

export const isOpenSources = (data: object): data is OpenSources =>
  Object.hasOwn(data, "iosphere");
