import { FC, useCallback, useState } from "react";

import { Checkbox, Flex } from "antd";
import cn from "classnames";
import { Controller, FieldErrors, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { CommonModal } from "src/components/CommonModal/CommonModal";
import { Text } from "src/ui/Text/Text";
import { Input } from "src/ui/Input/Input";
import { Button } from "src/ui/Button/Button";
import { validationMessage, validationRegex } from "src/utils/constants";
import { correctDigitRegexCallback } from "src/utils/validation";
import { ReactComponent as Close } from "src/assets/close.svg";
import { ReactComponent as ArrowBack } from "src/assets/arrow_back.svg";
import { useUsersRegisterMutation } from "@api/groups/mutations/useUsersRegisterMutation";
import { UsersRegisterRequest } from "@api/groups";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { AuthFormConfirmCode } from "../AuthFormConfirmCode/AuthFormConfirmCode";
import s from "./AuthFormRegister.module.scss";

const schema = yup
  .object<UsersRegisterRequest>()
  .shape({
    full_name: yup.string().required(validationMessage.Required),
    email: yup
      .string()
      .email(validationMessage.WrongEmail)
      .required(validationMessage.Required),
    password: yup
      .string()
      .min(8, validationMessage.MinLen({ min: 8 }))
      .matches(validationRegex.password, validationMessage.WrongPassword)
      .required(validationMessage.Required),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Пароль должен совпасть")
      .required(validationMessage.Required),
    phone: yup
      .string()
      .transform((v) => (typeof v === "string" ? v.replace(/\D/g, "") : ""))
      .length(11, validationMessage.WrongPhone)
      .required(validationMessage.Required),
    organization_name: yup.string().required(validationMessage.Required),
    organization_inn: yup
      .string()
      .transform((v) => (typeof v === "string" ? v.replace(/\D/g, "") : ""))
      .length(12, validationMessage.WrongInn)
      .test("digits-check", "ИНН указан неверно", correctDigitRegexCallback)
      .required(validationMessage.Required),
    job_title: yup.string().required(validationMessage.Required),
    personalDataAgreement: yup
      .boolean()
      .required(validationMessage.RequiredPolicy)
      .oneOf([true], validationMessage.RequiredPolicy),
    companyDataConfirmation: yup
      .boolean()
      .required(validationMessage.RequiredPolicy)
      .oneOf([true], validationMessage.RequiredPolicy),
  })
  .required();

export type AuthFormRegisterFormValues = {
  passwordConfirm: string;
  personalDataAgreement: boolean;
  companyDataConfirmation: boolean;
} & UsersRegisterRequest;

interface AuthFormRegisterProps {
  onChooseAuthorize: () => void;
  onSuccess: (data?: AuthFormRegisterFormValues) => void;
  onClose: () => void;
  onBack: () => void;
}

export const AuthFormRegister: FC<AuthFormRegisterProps> = ({
  onChooseAuthorize,
  onSuccess,
  onClose,
  onBack,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    getValues,
  } = useForm<AuthFormRegisterFormValues>({
    defaultValues: {
      full_name: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
      organization_inn: "",
      organization_name: "",
      job_title: "",
      companyDataConfirmation: false,
      personalDataAgreement: false,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [isConfirmMail, setIsConfirmMail] = useState(false);
  const [api] = useNotification();

  const { mutateAsync: register, isPending: isRegisterPending } =
    useUsersRegisterMutation();
  const { openModal } = useModalMessage();

  const onRegister = async (data: AuthFormRegisterFormValues) => {
    const pickedData: UsersRegisterRequest = {
      full_name: data.full_name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      organization_name: data.organization_name,
      organization_inn: data.organization_inn,
      job_title: data.job_title,
    };

    register(pickedData)
      .then(() => {
        setIsConfirmMail(true);
      })
      .catch((error) => {
        if (handleBackendErrors({ api, error })) return;

        openModal({
          children: (
            <CommonModal
              type="error"
              title="Пользователь с такой почтой уже существует"
              message="Попробуйте пройти авторизацию или пройдите регистрацию используя другую почту"
              secondaryButtonProps={{ onClick: onChooseAuthorize }}
            />
          ),
        });
      });
  };
  const onErrors = useCallback(
    (data: FieldErrors<AuthFormRegisterFormValues>) => {
      api.error({
        message: JSON.stringify(data, null, 2),
      });
    },
    [api],
  );

  if (isConfirmMail) {
    return (
      <AuthFormConfirmCode
        email={getValues().email}
        password={getValues().password}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <Flex component="section" vertical gap={24} className={cn(s.form)}>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap={8}>
          <Button pin="round" type="flat" size="small" onClick={onBack}>
            <ArrowBack width={24} height={24} />
          </Button>
          <Text variant="header-6">Регистрация</Text>
        </Flex>
        <Button pin="round" type="flat" size="small" onClick={onClose}>
          <Close width={24} height={24} />
        </Button>
      </Flex>
      <Flex
        component="form"
        vertical
        gap={16}
        onSubmit={handleSubmit(onRegister, onErrors)}
      >
        <Controller
          name="full_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="ФИО"
              required
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="Корпоративный E-mail"
              required
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="Номер телефона"
              required
              validate={fieldState}
              mask="+7 (000) 000-00-00"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="Придумайте пароль"
              required
              type="password"
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="passwordConfirm"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="Повторите пароль"
              required
              type="password"
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="organization_inn"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="ИНН организации"
              required
              validate={fieldState}
              mask="000000000000"
            />
          )}
        />
        <Controller
          name="organization_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="Название компании"
              required
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="job_title"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              {...field}
              placeholder="Должность"
              required
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="personalDataAgreement"
          control={control}
          render={({ field }) => (
            <Checkbox className={s.control} {...field}>
              Я соглашаюсь на обработку предоставленных персональных данных.
            </Checkbox>
          )}
        />
        <Controller
          name="companyDataConfirmation"
          control={control}
          render={({ field }) => (
            <Checkbox className={s.control} {...field}>
              Я заверяю, что уполномочен и представляю организацию, данные
              которой указываю в форму.
            </Checkbox>
          )}
        />
        <Button
          disabled={!isValid || isRegisterPending}
          fullWidth
          htmlType="submit"
        >
          Продолжить
        </Button>
      </Flex>
      <Text color="darkGray" variant="body-2">
        Услуги предоставляются на условиях нашего публичного предложения и
        политики обработки данных
      </Text>
    </Flex>
  );
};
