import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import { ReportsInfoSearchRequest, useReportsInfoSearch } from "@api/report";
import s from "./MainTabInfo.module.scss";
import { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants";

interface ReportsInfoFieldValues
  extends ReportsInfoSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object()
  .shape({
    info: yup.string().min(2).max(200).required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabInfoProps = MainTabProps;

export const MainTabInfo: FC<MainTabInfoProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      info: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsInfoSearch();

  const onSubmitInner = useCallback(
    (requestData: ReportsInfoFieldValues) => {
      onSubmit({ requestData, search, withSopd: true });
    },
    [search, onSubmit],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12}>
        <Controller
          name="info"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Ключевое слово"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
