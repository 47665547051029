import { MouseEventHandler, useCallback, useRef, useState } from "react";
import { Flex, Tooltip } from "antd";
import { Button } from "src/ui/Button/Button";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { DropZoneFile } from "src/ui/DropZone/DropZone";
import { Text } from "src/ui/Text/Text";
import { SOPDModal } from "../SOPDModal/SOPDModal";
import s from "./SubmitButton.module.scss";

interface SubmitButtonProps {
  disabled?: boolean;
  fileList?: DropZoneFile[];
  onFileListChange?: (fileList: DropZoneFile[]) => void;
}

export const SubmitButton = ({
  disabled,
  fileList,
  onFileListChange,
}: SubmitButtonProps) => {
  const file = fileList?.[0]?.originFileObj;

  const [isOpen, setIsOpen] = useState(false);
  const { data: user, isSuccess: isUserSuccess } = useCurrentUserQuery();
  const { openModal, closeModal } = useModalMessage();
  const buttonRef = useRef<HTMLElement>(null);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSuccess = useCallback(() => {
    closeModal();

    const form = buttonRef.current?.closest("form");
    form?.requestSubmit();
  }, [closeModal]);

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (user && isUserSuccess) {
      onSuccess();
    } else {
      openModal({
        children: <AuthForm onSuccess={onSuccess} onClose={closeModal} />,
        maskClosable: false,
        closable: false,
      });
    }
  }, [onSuccess, openModal, closeModal, isUserSuccess, user]);

  return (
    <Flex vertical gap={12} ref={buttonRef}>
      <Button
        className={s.button_submit}
        disabled={disabled}
        onClick={handleClick}
      >
        Проверить
      </Button>
      {onFileListChange ? (
        <>
          <Button
            className={s.button_sopd}
            fullWidth
            type="text_secondary"
            disabled={false}
            htmlType="button"
            onClick={onOpen}
          >
            <Flex gap={6} align="center">
              {file ? (
                <Text color="primary">Приложено СОПД</Text>
              ) : (
                <Text color="darkGray">Загрузить СОПД</Text>
              )}
              <Tooltip
                title="Согласие на обработку персональных данных"
                trigger="hover"
              >
                <AiFillQuestionCircle />
              </Tooltip>
            </Flex>
          </Button>
          <SOPDModal
            fileList={fileList || []}
            onFileListChange={onFileListChange}
            open={isOpen}
            onClose={onClose}
          />
        </>
      ) : null}
    </Flex>
  );
};
