import { FC, useState } from "react";

import { Button, Input, List, Typography, Flex } from "antd";
import { ByFioResponse, usePhotoByFio } from "@api/photo";
import s from "./ByFio.module.scss";
import { Face } from "../Face/Face";

export const ByFio: FC = () => {
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [birthDay, setBirthDay] = useState<string>("");
  const [birthMonth, setBirthMonth] = useState<string>("");
  const [birthYear, setBirthYear] = useState<string>("");

  const [photoByFio, setPhotoByFio] = useState<ByFioResponse | null>(null);
  const { mutateAsync: mutatePhotoByFio } = usePhotoByFio();

  const search = async () => {
    const data = await mutatePhotoByFio({
      last_name: lastName,
      first_name: firstName,
      middle_name: middleName,
      birth_day: Number(birthDay),
      birth_month: Number(birthMonth),
      birth_year: Number(birthYear),
    });

    setPhotoByFio(data ?? null);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.redact_panel}>
        <Input
          required
          value={lastName}
          placeholder="Фамилия"
          onChange={(e) => {
            setLastName(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={firstName}
          placeholder="Имя"
          onChange={(e) => {
            setFirstName(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={middleName}
          placeholder="Отчество"
          onChange={(e) => {
            setMiddleName(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={birthDay}
          placeholder="День рождения"
          onChange={(e) => {
            setBirthDay(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={birthMonth}
          placeholder="Месяц рождения"
          onChange={(e) => {
            setBirthMonth(e.currentTarget.value);
          }}
        />
        <Input
          required
          value={birthYear}
          placeholder="Год рождения"
          onChange={(e) => {
            setBirthYear(e.currentTarget.value);
          }}
        />
        <Button onClick={search}>Поиск</Button>
      </div>
      {photoByFio && (
        <div className={s.wrapper}>
          <List bordered>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>Количество</Typography.Text>
                </div>
                <div>{photoByFio.count}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>Количество лиц</Typography.Text>
                </div>
                <div>{photoByFio.result.faces_count}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>full_response_uuid</Typography.Text>
                </div>
                <div>{photoByFio.result.full_response_uuid}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>preview</Typography.Text>
                </div>
                <div>{String(photoByFio.result.preview)}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>response_uuid</Typography.Text>
                </div>
                <div>{photoByFio.result.response_uuid}</div>
              </Flex>
            </List.Item>
            <List.Item className={s.list_item}>
              <Flex justify="space-between" className={s.list_item}>
                <div>
                  <Typography.Text mark>suspected</Typography.Text>
                </div>
                <div>{String(photoByFio.result.suspected)}</div>
              </Flex>
            </List.Item>
          </List>

          <Typography.Text mark>faces</Typography.Text>

          {photoByFio.result.faces.map((face, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Face key={index} face={face} />
          ))}
        </div>
      )}
    </div>
  );
};
