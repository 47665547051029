/* eslint-disable no-underscore-dangle */

import { Flex, List, Typography, Table } from "antd";
import { BorderCrossItem, Candidate as CandidateObj } from "@api/photo";
import { FC } from "react";
import { ImageItem } from "../../../../common/ImageItem";
import s from "./Candidate.module.scss";

interface CandidateProps {
  candidate: CandidateObj;
}

const borderCrossColumns = [
  {
    title: "sort",
    dataIndex: "sort",
    key: "sort",
  },
  {
    title: "_id",
    dataIndex: "_id",
    key: "_id",
  },
  {
    title: "_index",
    dataIndex: "_index",
    key: "_index",
  },
  {
    title: "airplane",
    dataIndex: "airplane",
    key: "airplane",
  },
  {
    title: "birthday",
    dataIndex: "birthday",
    key: "birthday",
  },
  {
    title: "company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "date2",
    dataIndex: "date2",
    key: "date2",
  },
  {
    title: "destination_city",
    dataIndex: "destination_city",
    key: "destination_city",
  },
  {
    title: "destination_country",
    dataIndex: "destination_country",
    key: "destination_country",
  },
  {
    title: "document_date",
    dataIndex: "document_date",
    key: "document_date",
  },
  {
    title: "document_num",
    dataIndex: "document_num",
    key: "document_num",
  },
  {
    title: "from",
    dataIndex: "from",
    key: "from",
  },
  {
    title: "from_type",
    dataIndex: "from_type",
    key: "from_type",
  },
  {
    title: "name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "type",
    dataIndex: "type",
    key: "type",
  },
];

const flatBorderCross = (borderCrossItem: BorderCrossItem) => ({
  sort: borderCrossItem.sort.join(", "),
  _id: borderCrossItem._id,
  _index: borderCrossItem._index,

  airplane: borderCrossItem._source.airplane,
  birthday: borderCrossItem._source.birthday,
  company: borderCrossItem._source.company,
  country: borderCrossItem._source.country,
  date: borderCrossItem._source.date,
  date2: borderCrossItem._source.date2,
  destination_city: borderCrossItem._source.destination_city,
  destination_country: borderCrossItem._source.destination_city,

  document_date: borderCrossItem._source.document.date,
  document_num: borderCrossItem._source.document.num,

  from: borderCrossItem._source.from,
  from_type: borderCrossItem._source.from_type,
  name: borderCrossItem._source.name,
  type: borderCrossItem._source.type,
});

const collectionObjColumns = [
  {
    title: "alias",
    dataIndex: "alias",
    key: "alias",
  },
  {
    title: "date_created",
    dataIndex: "date_created",
    key: "date_created",
  },
  {
    title: "description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "ext_index",
    dataIndex: "ext_index",
    key: "ext_index",
  },
  {
    title: "hash_pref_len",
    dataIndex: "hash_pref_len",
    key: "hash_pref_len",
  },
  {
    title: "hash_pref_num",
    dataIndex: "hash_pref_num",
    key: "hash_pref_num",
  },
  {
    title: "id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "join_field_fid",
    dataIndex: "join_field_fid",
    key: "join_field_fid",
  },
  {
    title: "join_field_path",
    dataIndex: "join_field_path",
    key: "join_field_path",
  },
  {
    title: "meta_source",
    dataIndex: "meta_source",
    key: "meta_source",
  },
  {
    title: "rec_model",
    dataIndex: "rec_model",
    key: "rec_model",
  },
  {
    title: "retr_by_hash",
    dataIndex: "retr_by_hash",
    key: "retr_by_hash",
  },
  {
    title: "row_count",
    dataIndex: "row_count",
    key: "row_count",
  },
  {
    title: "search_aliases",
    dataIndex: "search_aliases",
    key: "search_aliases",
  },
  {
    title: "source",
    dataIndex: "source",
    key: "source",
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "type",
    dataIndex: "type",
    key: "type",
  },
];

const flatCollectionObjColumns = (
  flatCollectionItem: CandidateObj["collection_obj"],
) => ({
  alias: flatCollectionItem?.alias,
  date_created: flatCollectionItem?.date_created,
  description: flatCollectionItem?.description,
  ext_index: flatCollectionItem?.ext_index,
  hash_pref_len: flatCollectionItem?.hash_pref_len,
  hash_pref_num: flatCollectionItem?.hash_pref_num,
  id: flatCollectionItem?.id,

  join_field_fid: flatCollectionItem?.join_field?.fid,
  join_field_path: flatCollectionItem?.join_field?.path,

  meta_source: flatCollectionItem?.meta_source,
  rec_model: flatCollectionItem?.rec_model,
  retr_by_hash: String(flatCollectionItem?.retr_by_hash),
  row_count: String(flatCollectionItem?.row_count),
  search_aliases: flatCollectionItem?.search_aliases?.join(", "),
  source: flatCollectionItem?.source,
  status: flatCollectionItem?.status,
  type: flatCollectionItem?.type,
});

const metaColumns = [
  {
    title: "date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "drivers_license_num",
    dataIndex: "drivers_license_num",
    key: "drivers_license_num",
  },
  {
    title: "drivers_license_date",
    dataIndex: "drivers_license_date",
    key: "drivers_license_date",
  },
  {
    title: "finder_user_id",
    dataIndex: "finder_user_id",
    key: "finder_user_id",
  },
  {
    title: "finder_username",
    dataIndex: "finder_username",
    key: "finder_username",
  },
  {
    title: "joinDate",
    dataIndex: "joinDate",
    key: "joinDate",
  },
  {
    title: "lastSeen",
    dataIndex: "lastSeen",
    key: "lastSeen",
  },
  {
    title: "orig_img_links",
    dataIndex: "orig_img_links",
    key: "orig_img_links",
  },
  {
    title: "passport_num",
    dataIndex: "passport_num",
    key: "passport_num",
  },
  {
    title: "passport_date",
    dataIndex: "passport_date",
    key: "passport_date",
  },
  {
    title: "passport_by",
    dataIndex: "passport_by",
    key: "passport_by",
  },
  {
    title: "service_name",
    dataIndex: "service_name",
    key: "service_name",
  },
  {
    title: "social_vk",
    dataIndex: "social_vk",
    key: "social_vk",
  },
];

const flatMeta = (meta: CandidateObj["meta"]) => ({
  date: meta?.date,

  drivers_license_num: meta?.drivers_license?.num,
  drivers_license_date: meta?.drivers_license?.date,

  finder_user_id: meta?.finder_user_id,
  finder_username: meta?.finder_username,
  joinDate: meta?.joinDate,
  lastSeen: meta?.lastSeen,

  orig_img_links: meta?.orig_img_links?.avatar,

  passport_num: meta?.passport?.num,
  passport_date: meta?.passport?.date,
  passport_by: meta?.passport?.by,

  service_name: meta?.service_name,

  social_vk: meta?.social?.vk,
});

export const Candidate: FC<CandidateProps> = ({ candidate }) => (
  <List bordered>
    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>bbox</Typography.Text>
        </div>
        <p>{candidate.bbox.join(", ")}</p>
      </Flex>
    </List.Item>

    {candidate.border_cross && (
      <List.Item className={s.list_item}>
        <Flex className={s.list_item}>
          <div>
            <Typography.Text mark>border_cross</Typography.Text>
          </div>
          <Table
            className={s.table_scroll}
            columns={borderCrossColumns}
            dataSource={candidate.border_cross.map((border_crossItem) =>
              flatBorderCross(border_crossItem),
            )}
          />
        </Flex>
      </List.Item>
    )}

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>collection_name</Typography.Text>
        </div>
        <p>{candidate.collection_name}</p>
      </Flex>
    </List.Item>

    {candidate.collection_obj && (
      <List.Item className={s.list_item}>
        <Flex className={s.list_item}>
          <div>
            <Typography.Text mark>collection_obj</Typography.Text>
          </div>
          <Table
            className={s.table_scroll}
            columns={collectionObjColumns}
            dataSource={[flatCollectionObjColumns(candidate.collection_obj)]}
          />
        </Flex>
      </List.Item>
    )}

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>external_url</Typography.Text>
        </div>
        <p>{candidate.external_url}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>fid</Typography.Text>
        </div>
        <p>{candidate.fid}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>hidden</Typography.Text>
        </div>
        <p>{String(candidate.hidden)}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>hidden_collection</Typography.Text>
        </div>
        <p>{String(candidate.hidden_collection)}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>id</Typography.Text>
        </div>
        <p>{candidate.id}</p>
      </Flex>
    </List.Item>

    {candidate.meta && (
      <List.Item className={s.list_item}>
        <Flex className={s.list_item}>
          <div>
            <Typography.Text mark>meta:</Typography.Text>
          </div>
          <Table
            className={s.table_scroll}
            columns={metaColumns}
            dataSource={[flatMeta(candidate.meta)]}
          />
        </Flex>
      </List.Item>
    )}

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>num_det</Typography.Text>
        </div>
        <p>{candidate.num_det}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>oddisey</Typography.Text>
        </div>
        <p>{candidate.oddisey.join(", ")}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>path</Typography.Text>
        </div>
        <img src={candidate.path} alt={candidate.path} />
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>photos</Typography.Text>
        </div>
        <div className={s.wrapper}>
          {candidate.photos.map((photo) => (
            <ImageItem key={photo} src={photo} />
          ))}
        </div>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>prob</Typography.Text>
        </div>
        <p>{candidate.prob}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>score</Typography.Text>
        </div>
        <p>{candidate.score}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>size</Typography.Text>
        </div>
        <p>{candidate.size}</p>
      </Flex>
    </List.Item>

    <List.Item className={s.list_item}>
      <Flex justify="space-between" className={s.list_item}>
        <div>
          <Typography.Text mark>total_dets</Typography.Text>
        </div>
        <p>{candidate.total_dets}</p>
      </Flex>
    </List.Item>
  </List>
);
