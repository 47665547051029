import { FC, useCallback, useState } from "react";

import { LoginRequest } from "@api/auth";
import { AuthFormMode } from "./constants";
import { AuthFormLogin } from "./components/AuthFormLogIn/AuthFormLogIn";
import {
  AuthFormRegister,
  AuthFormRegisterFormValues,
} from "./components/AuthFormRegister/AuthFormRegister";
import { AuthFormChangePassword } from "./components/AuthFormChangePassword/AuthFormChangePassword";
import { AuthFormConfirmCode } from "./components/AuthFormConfirmCode/AuthFormConfirmCode";

interface AuthFormProps {
  onSuccess: (data?: AuthFormRegisterFormValues | LoginRequest) => void;
  onClose: () => void;
}

export const AuthForm: FC<AuthFormProps> = ({ onSuccess, onClose }) => {
  const [mode, setMode] = useState<AuthFormMode>(AuthFormMode.LogIn);

  const onRegister = useCallback(() => {
    setMode(AuthFormMode.Register);
  }, []);
  const onChooseAuthorize = useCallback(() => {
    setMode(AuthFormMode.LogIn);
  }, []);
  const onChangePassword = useCallback(() => {
    setMode(AuthFormMode.ChangePassword);
  }, []);
  const onConfirmCode = useCallback(() => {
    setMode(AuthFormMode.ConfirmCode);
  }, []);

  const handleSuccess = useCallback(
    (data?: AuthFormRegisterFormValues | LoginRequest) => {
      setMode(AuthFormMode.LogIn);
      onSuccess(data);
    },
    [onSuccess],
  );

  if (mode === AuthFormMode.LogIn) {
    return (
      <AuthFormLogin
        onSuccess={handleSuccess}
        onRegister={onRegister}
        onChangePassword={onChangePassword}
        onClose={onClose}
      />
    );
  }

  if (mode === AuthFormMode.Register) {
    return (
      <AuthFormRegister
        onChooseAuthorize={onChooseAuthorize}
        onSuccess={handleSuccess}
        onClose={onClose}
        onBack={onChooseAuthorize}
      />
    );
  }

  if (mode === AuthFormMode.ChangePassword) {
    return (
      <AuthFormChangePassword onSuccess={onConfirmCode} onClose={onClose} />
    );
  }

  if (mode === AuthFormMode.ConfirmCode) {
    return (
      <AuthFormConfirmCode
        onSuccess={onConfirmCode}
        email="lol"
        password="kek"
      />
    );
  }

  return null;
};
