import { Flex } from "antd";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { Scoring } from "src/components/Scoring/Scoring";
import { ReportCommonProps } from "@entities/types";
import React from "react";
import { ReportsScoringRetrieveResponse, ReportStatus } from "@api/report";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { calculateReportStatus } from "../../utils/getReportStatus";

export type RetrieveScoringProps = {
  report: ReportsScoringRetrieveResponse;
} & ReportCommonProps;

export const RetrieveScoring = ({ report, type }: RetrieveScoringProps) => (
  <RetrieveStatus
    status={
      calculateReportStatus([report?.scoring_result?.status]) || report?.status
    }
  >
    <Flex vertical gap={32}>
      {report?.request_describe && (
        <FullDossier requestDescribe={report?.request_describe} type={type} />
      )}
      <Scoring
        scoringResult={report?.scoring_result}
        status={report?.scoring_result?.status}
      />
    </Flex>
  </RetrieveStatus>
);
