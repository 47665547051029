import { FC } from "react";
import { Flex } from "antd";
import { Button } from "src/ui/Button/Button";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import cn from "classnames";
import s from "./Pagination.module.scss";

interface PaginationProps {
  pagesCount: number;
  page: number;
  onUpdate: (newPage: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  pagesCount,
  page,
  onUpdate,
}) => (
  <Flex gap={6}>
    <Button
      type="flat"
      pin="round"
      onClick={() => {
        onUpdate(page - 1);
      }}
      disabled={page <= 1}
    >
      <LuChevronLeft className={s.button} />
    </Button>
    {Array.from({ length: pagesCount }).map((_v, pageIdx) => (
      <Button
        // Количесто на момент рендера фиксировано
        // eslint-disable-next-line react/no-array-index-key
        key={pageIdx}
        type="flat"
        pin="round"
        className={cn(s.page_button, {
          [s.page_button_active]: page === pageIdx + 1,
        })}
        onClick={() => {
          onUpdate(pageIdx + 1);
        }}
      >
        {pageIdx + 1}
      </Button>
    ))}
    <Button
      type="flat"
      pin="round"
      onClick={() => {
        onUpdate(page + 1);
      }}
      disabled={page <= 1}
    >
      <LuChevronRight className={s.button} />
    </Button>
  </Flex>
);
