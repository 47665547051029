import { useMutation } from "@tanstack/react-query";
import { usePassword } from "src/app/layouts/MainLayout/components/PasswordContext";
import { queryClient } from "@api/client";
import { LogoutRequest } from "../types";
import { logout } from "../queries";

export const useLogout = () => {
  const { setPassword } = usePassword();

  return useMutation({
    mutationKey: ["logout"],
    mutationFn: (data: LogoutRequest) => logout(data),
    onSuccess: async () => {
      setPassword("");
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: ["currentUser"] }),
        queryClient.invalidateQueries({ queryKey: ["reports"] }),
        queryClient.invalidateQueries({ queryKey: ["profilePicture"] }),
      ]);
    },
  });
};
