import { useQuery } from "@tanstack/react-query";

import { groups } from "../queries";

interface UseGroupsProps {
  enabled?: boolean;
}

export const useGroups = ({ enabled }: UseGroupsProps = {}) => {
  const response = useQuery({
    queryKey: ["groups"],
    queryFn: groups,
    enabled,
  });
  return {
    ...response,
    data: response.data,
  };
};
