import { Flex } from "antd";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { ReportCommonProps } from "@entities/types";
import React from "react";
import { ReportsCreditRatingRetrieveResponse } from "@api/report";
import { CreditRatingResult } from "./components/CreditRatingResult/CreditRatingResult";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { calculateReportStatus } from "../../utils/getReportStatus";

export type RetrieveCreditRatingProps = {
  report: ReportsCreditRatingRetrieveResponse;
} & ReportCommonProps;

export const RetrieveCreditRating = ({
  report,
  type,
}: RetrieveCreditRatingProps) => (
  <RetrieveStatus
    status={
      calculateReportStatus([report?.credit_rating?.status]) || report?.status
    }
  >
    <Flex vertical gap={32}>
      {report?.request_describe && (
        <FullDossier requestDescribe={report?.request_describe} type={type} />
      )}
      <CreditRatingResult
        creditRating={report?.credit_rating}
        status={report?.credit_rating?.status}
      />
    </Flex>
  </RetrieveStatus>
);
