import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { AxiosError } from "axios";
import {
  UsersProfilePictureRequest,
  UsersProfilePictureResponse,
} from "../types";
import { usersProfilePicture } from "../queries";

export const useUsersProfilePictureMutation = () =>
  useMutation<
    UsersProfilePictureResponse,
    AxiosError,
    UsersProfilePictureRequest
  >({
    mutationKey: ["profilePicture"],
    mutationFn: (data: UsersProfilePictureRequest) => usersProfilePicture(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["profilePicture"] }),
  });
