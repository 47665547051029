import { FC, useCallback } from "react";

import { Alert, Flex } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "src/ui/Text/Text";
import { Input } from "src/ui/Input/Input";
import * as yup from "yup";
import { validationMessage } from "src/utils/constants";
import { LoginRequest, useLogin } from "@api/auth";
import { Button } from "src/ui/Button/Button";
import { PiWarningBold } from "react-icons/pi";
import { ReactComponent as Close } from "src/assets/close.svg";
import s from "./AuthFormLogIn.module.scss";

const schema = yup
  .object<LoginRequest>()
  .shape({
    login: yup.string().required(validationMessage.Required),
    password: yup.string().required(validationMessage.Required),
  })
  .required();

interface AuthFormLoginProps {
  onSuccess: (data: LoginRequest) => void;
  onRegister: () => void;
  onChangePassword: () => void;
  onClose: () => void;
}

export const AuthFormLogin: FC<AuthFormLoginProps> = ({
  onSuccess,
  onRegister,
  onChangePassword,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginRequest>({
    defaultValues: {
      login: "",
      password: "",
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const {
    mutateAsync: signIn,
    error: loginError,
    isPending: isLoginPending,
  } = useLogin();

  const onLogin = useCallback<SubmitHandler<LoginRequest>>(
    async (data: LoginRequest) => {
      await signIn(data).then(() => {
        onSuccess(data);
      });
    },
    [onSuccess, signIn],
  );

  return (
    <Flex component="section" vertical gap={40}>
      <Flex align="center" justify="space-between">
        <Text variant="header-6">Вход в личный кабинет</Text>
        <Button pin="round" type="flat" size="small" onClick={onClose}>
          <Close width={24} height={24} />
        </Button>
      </Flex>
      <Flex component="form" vertical gap={16} onSubmit={handleSubmit(onLogin)}>
        <Controller
          name="login"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              placeholder="Логин"
              required
              validate={fieldState}
              isError={!!loginError}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              placeholder="Пароль"
              required
              type="password"
              validate={fieldState}
              isError={!!loginError}
            />
          )}
        />
        {loginError ? (
          <Alert
            icon={<PiWarningBold size={16} />}
            showIcon
            type="error"
            message="Неверный логин или пароль"
          />
        ) : null}
        <Flex vertical gap={8}>
          <Button
            type="primary"
            disabled={!isValid || isLoginPending}
            fullWidth
            htmlType="submit"
          >
            Войти
          </Button>
          <Flex vertical align="center">
            <Button onClick={onChangePassword} type="link_secondary">
              Забыли пароль?
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex vertical align="center" gap={4}>
        <Text variant="body-5" align="center">
          Нет аккаунта? Пройдите регистрацию и получите демо доступ
          использования сервиса.
        </Text>
        <Button onClick={onRegister} type="flat" fullWidth>
          <Text color="green" variant="subheader-3">
            Регистрация
          </Text>
        </Button>
      </Flex>
      <div className={s.footer} />
    </Flex>
  );
};
