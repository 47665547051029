import { digitRegex, lettersRegex } from "./regExps";

export const correctLettersRegexCallback = (value?: string) => {
  if (!value) return true;

  return lettersRegex.test(value);
};

export const correctDigitRegexCallback = (value?: string) => {
  if (!value) return true;

  return digitRegex.test(value);
};
