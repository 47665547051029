import { FC, useCallback } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import {
  MainTabFieldValues,
  MainTabProps,
} from "@pages/Main/components/MainTabs/types";
import { SearchAutoRegistrationNumberSearchRequest } from "@api/search";
import { useSearchAutoRegistrationNumber } from "@api/search/mutations/useSearchAutoRegistrationNumber";
import { dropZoneFileListSchema } from "@pages/Main/components/MainTabs/constants";
import s from "./MainTabAutoNormal.module.scss";

interface SearchAutoRegistrationNumberFieldValues
  extends SearchAutoRegistrationNumberSearchRequest,
    MainTabFieldValues {}

const schema = yup
  .object<SearchAutoRegistrationNumberSearchRequest>()
  .shape({
    auto_registration_number: yup.string().required(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabAutoNormalProps = MainTabProps;

export const MainTabAutoNormal: FC<MainTabAutoNormalProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      auto_registration_number: "",
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useSearchAutoRegistrationNumber();

  const onSubmitInner = useCallback(
    (requestData: SearchAutoRegistrationNumberFieldValues) => {
      onSubmit({ requestData, search, withSopd: true });
    },
    [onSubmit, search],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12}>
        <Controller
          name="auto_registration_number"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Номер авто"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
