import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import {
  groups_get_permissions,
  groups_grant_permissions,
  groups_grant_user_permissions,
  groups_set_login_duration,
  groups_set_non_russian_ip_allowed,
} from "../queries";
import {
  GroupsGetPermissionsRequest,
  GroupsGrantPermissionsRequest,
  GroupsGrantUserPermissionsRequest,
  GroupsSetLoginDurationRequest,
  GroupsSetNonRussianApiAllowedRequest,
} from "../types";

export const useGroupsGrantPermissions = () =>
  useMutation({
    mutationKey: ["groups"],
    mutationFn: (data: GroupsGrantPermissionsRequest) =>
      groups_grant_permissions(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });

export const useGroupsGetPermissions = (data: GroupsGetPermissionsRequest) =>
  useQuery({
    queryKey: ["group:permissions", data.group_id],
    queryFn: () => groups_get_permissions(data),
  });

export const useGroupsGrantUserPermissions = () =>
  useMutation({
    mutationKey: ["groups"],
    mutationFn: (data: GroupsGrantUserPermissionsRequest) =>
      groups_grant_user_permissions(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });

export const useGroupsSetNonRussianApiAllowed = () =>
  useMutation({
    mutationKey: ["groupsGrantPermissions"],
    mutationFn: (data: GroupsSetNonRussianApiAllowedRequest) =>
      groups_set_non_russian_ip_allowed(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });

export const useGroupsSetLoginDuration = () =>
  useMutation({
    mutationKey: ["groupsGrantPermissions"],
    mutationFn: (data: GroupsSetLoginDurationRequest) =>
      groups_set_login_duration(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });
