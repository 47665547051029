import { Main } from "@pages/Main/Main";
import { Admin } from "@pages/Admin/Admin";
import { History } from "@pages/History/History";
import { Search } from "@pages/Search/Search";
import { Report } from "@pages/Report/Report";
import { Root } from "@pages/Root/Root";
import { createHashRouter, Navigate } from "react-router-dom";
import { UserSettings } from "@pages/UserSettings/UserSettings";
import { Analytics } from "@pages/Analytics/Analytics";
import { Accesses } from "@pages/Accesses/Accesses";
import { AccessesAdd } from "@pages/AccessesAdd/AccessesAdd";
import { AccessesEdit } from "@pages/AccessesEdit/AccessesEdit";
import { AppRoute } from "./constants";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

export const router = createHashRouter([
  {
    path: AppRoute.Root,
    element: <Root />,
    children: [
      {
        path: "",
        element: <Navigate to={AppRoute.Lk} replace />,
      },
      {
        path: AppRoute.Report,
        element: <Report />,
      },
      {
        path: AppRoute.Search,
        element: <Search />,
      },
      {
        path: AppRoute.History,
        element: <History />,
      },
      {
        path: AppRoute.Lk,
        element: <PrivateRoute />,
        children: [
          {
            path: "",
            element: <Main />,
          },
          {
            path: AppRoute.Admin,
            element: <Admin />,
          },
          {
            path: AppRoute.User,
            element: <UserSettings />,
          },
          {
            path: AppRoute.Analytics,
            element: <Analytics />,
          },
          {
            path: AppRoute.Accesses,
            element: <Accesses />,
          },
          {
            path: AppRoute.AccessesAdd,
            element: <AccessesAdd />,
          },
          {
            path: AppRoute.AccessesEdit,
            element: <AccessesEdit />,
          },
        ],
      },
    ],
  },
]);
