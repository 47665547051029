import { FC, useCallback, useState } from "react";
import { Flex } from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SubmitButton } from "@pages/Main/components/MainTabs/components/SubmitButton/SubmitButton";
import { Input } from "src/ui/Input/Input";
import { useReportsCreditRatingSearch } from "@api/report";
import { Toggle } from "src/ui/Toggle/Toggle";
import s from "./MainTabCreditRating.module.scss";
import { MainTabFieldValues, MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants";

interface ReportsCreditRatingSearchForm extends MainTabFieldValues {
  last_name: string;
  first_name: string;
  middle_name?: string;
  birth_day: number;
  birth_month: number;
  birth_year: number;
  passport?: string;
}

const CURRENT_YEAR = new Date().getFullYear();

const birthDayError = "Введите валидный день месяца";
const birthMonthError = "Введите валидный месяц (от 1 до 12)";
const birthYearError = `Введите валидный год (от 1900 до ${CURRENT_YEAR})`;

const schema = yup
  .object<ReportsCreditRatingSearchForm>()
  .shape({
    last_name: yup.string().required(),
    first_name: yup.string().required(),
    middle_name: yup.string(),
    birth_day: yup
      .number()
      .integer(birthDayError)
      .min(1, birthDayError)
      .max(31, birthDayError)
      .required(),
    birth_month: yup
      .number()
      .integer(birthMonthError)
      .min(1, birthMonthError)
      .max(12, birthMonthError)
      .required(),
    birth_year: yup
      .number()
      .integer(birthYearError)
      .min(1900, birthYearError)
      .max(CURRENT_YEAR, birthYearError)
      .required(),
    // TODO бек пока не поддержал паспорт в этой ручке
    // passport: yup.string().required(),
    passport: yup.string(),
    sopdFileList: dropZoneFileListSchema,
  })
  .required();

type MainTabCreditRatingProps = MainTabProps;

export const MainTabCreditRating: FC<MainTabCreditRatingProps> = ({
  onSubmit,
}) => {
  const [type, setType] = useState<"extended" | "normal">("normal");

  const onTypeChange = useCallback((checked: boolean) => {
    if (checked) {
      setType("extended");
    } else {
      setType("normal");
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      birth_day: 1,
      birth_month: 1,
      birth_year: 1990,
      first_name: "",
      last_name: "",
      middle_name: "",
      // passport: '',
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsCreditRatingSearch();

  const onSubmitInner = useCallback(
    (data: ReportsCreditRatingSearchForm) => {
      const requestData = {
        ...data,
        /**
         * DD.MM.YYYY
         */
        birth_date: `${String(data.birth_day).padStart(2, "0")}.${String(data.birth_month).padStart(2, "0")}.${data.birth_year}`,
      };

      onSubmit({ requestData, search, withSopd: true });
    },
    [search, onSubmit],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex gap={12} wrap="wrap">
        <Controller
          name="last_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Фамилия"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="first_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              placeholder="Имя"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="middle_name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              placeholder="Отчество"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_day"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min="1"
              max="31"
              step="1"
              placeholder="День"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_month"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min="1"
              max="12"
              step="1"
              placeholder="Месяц"
              {...field}
              validate={fieldState}
            />
          )}
        />
        <Controller
          name="birth_year"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              className={s.input}
              tabIndex={0}
              required
              type="number"
              min="1900"
              max={CURRENT_YEAR}
              step="1"
              placeholder="Год"
              {...field}
              validate={fieldState}
            />
          )}
        />
        {type === "extended" ? (
          <Controller
            name="passport"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                className={s.input}
                tabIndex={0}
                required
                placeholder="Серия и номер паспорта"
                {...field}
                validate={fieldState}
              />
            )}
          />
        ) : (
          <div className={s.noop} />
        )}
        <Toggle
          className={s.checkbox_extended}
          value={type === "extended"}
          onChange={onTypeChange}
          title="Расширенный кредитный рейтиг"
        />
        <Controller
          name="sopdFileList"
          control={control}
          render={({ field }) => (
            <SubmitButton
              fileList={field.value}
              onFileListChange={field.onChange}
              disabled={!isValid || isSearchPending}
            />
          )}
        />
      </Flex>
    </form>
  );
};
