import { Block } from "src/ui/Block/Block";
import s from "./RetrieveCard.module.scss";
import { Text } from "../../ui/Text/Text";
import { Expand } from "../../ui/Expand/Expand";
import React, { PropsWithChildren, ReactNode } from "react";
import { useBooleanState } from "src/hooks/useBooleanState";
import { StatusPaper } from "src/components/StatusPaper/StatusPaper";
import { ReportStatus } from "@api/report";

export type RetrieveCardCommonProps = {
  status?: ReportStatus;
};

export type RetrieveCardProps = PropsWithChildren<
  {
    title: string;
    header?: ReactNode;
  } & RetrieveCardCommonProps
>;

export const RetrieveCard = ({
  title,
  header,
  status,
  children,
}: RetrieveCardProps) => {
  const { state, toggle } = useBooleanState(true);

  return (
    <Block className={s.container}>
      {header}

      {status === ReportStatus.Finished && (
        <Expand state={state} toggle={toggle} className={s.container__title}>
          <Text variant="subheader-4">{title}</Text>
        </Expand>
      )}
      {status !== ReportStatus.Finished && (
        <Text variant="subheader-4">{title}</Text>
      )}

      {status && status !== ReportStatus.Finished && (
        <StatusPaper status={status} />
      )}

      {state && status === ReportStatus.Finished && children}
    </Block>
  );
};
