import { useRef, useEffect } from "react";

export type ClickOutsideCallback = (event: MouseEvent) => void;

interface ConfigProps {
  clickOutsideFn: ClickOutsideCallback;
}

export function useClickOutside<ElementType extends HTMLElement>({
  clickOutsideFn,
}: ConfigProps) {
  const wrapperRef = useRef<ElementType | null>(null);
  const callbackRef = useRef<ClickOutsideCallback | null>(null);

  const setWrapperRef = (element: ElementType | null) => {
    wrapperRef.current = element;
  };

  useEffect(() => {
    if (typeof clickOutsideFn === "function") {
      callbackRef.current = clickOutsideFn;
    }
  }, [clickOutsideFn]);

  useEffect(() => {
    const listenerCallback = (event: MouseEvent) => {
      if (
        callbackRef.current &&
        !wrapperRef.current?.contains(event.target as Node)
      ) {
        callbackRef.current(event);
      }
    };

    window.addEventListener("mousedown", listenerCallback);

    return () => {
      window.removeEventListener("mousedown", listenerCallback);
    };
  }, []);

  return { setWrapperRef };
}
