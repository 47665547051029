import { FC } from "react";
import Sider from "antd/es/layout/Sider";
import cn from "classnames";
import s from "./MainLayoutUser.module.scss";
import { MainLayoutUserMenu } from "./components/MainLayoutUserMenu/MainLayoutUserMenu";

interface MainLayoutUserProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
  setWrapperRef: (element: HTMLElement | null) => void;
}

export const MainLayoutUser: FC<MainLayoutUserProps> = ({
  collapsed,
  onCollapse,
  setWrapperRef,
}) => (
  <Sider
    ref={setWrapperRef}
    className={cn(s.sider, { [s.sider_collapsed]: collapsed })}
    defaultCollapsed
    width={320}
    collapsedWidth={0}
    collapsed={collapsed}
    onCollapse={onCollapse}
  >
    <MainLayoutUserMenu onCollapse={onCollapse} />
  </Sider>
);
