import { useCallback, useEffect } from "react";
import { Location, Outlet, useLocation, useNavigate } from "react-router";
import { AuthForm } from "src/components/AuthForm/AuthForm";
import { useModalMessage } from "src/ui/Modal/useModalMessage";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { AppRoute } from "../../constants";

export const PrivateRoute = () => {
  const {
    data: user,
    isFetched: isUserFetched,
    refetch,
  } = useCurrentUserQuery();
  const { openModal, closeModal } = useModalMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as Location<{ redirect?: string }>;

  const onSuccess = useCallback(() => {
    refetch().catch(() => {});

    closeModal();

    if (typeof state?.redirect === "string") {
      navigate(state.redirect);
    } else {
      navigate(AppRoute.Lk);
    }
  }, [navigate, state, refetch, closeModal]);

  useEffect(() => {
    if (!user && isUserFetched) {
      openModal({
        children: <AuthForm onSuccess={onSuccess} onClose={closeModal} />,
        maskClosable: false,
        closable: false,
      });
    }
  }, [openModal, refetch, user, isUserFetched, onSuccess, closeModal]);

  return <Outlet />;
};
