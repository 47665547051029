import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@api/client";
import { AxiosError } from "axios";
import { UsersRegisterRequest, UsersRegisterResponse } from "../types";
import { usersRegister } from "../queries";

export const useUsersRegisterMutation = () =>
  useMutation<UsersRegisterResponse, AxiosError, UsersRegisterRequest>({
    mutationKey: ["currentUser"],
    mutationFn: (data: UsersRegisterRequest) => usersRegister(data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["currentUser"] }),
  });
