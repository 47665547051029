import { Skeleton } from "antd";
import React from "react";
import { useParams } from "react-router";
import { useReportsPhotoByFaceRetrieve } from "@api/report";
import { ReportCommonProps } from "@entities/types";
import { RetrievePhotoByFace } from "@entities/RetrievePhotoByFace/RetrievePhotoByFace";

export const SearchPhotoWidget = ({ type }: ReportCommonProps) => {
  const { reportId } = useParams();

  const { data: photoByFaceReport, isLoading } = useReportsPhotoByFaceRetrieve({
    id: reportId || "",
  });

  return (
    <Skeleton loading={isLoading}>
      {photoByFaceReport && (
        <RetrievePhotoByFace report={photoByFaceReport} type={type} />
      )}
    </Skeleton>
  );
};
