import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { SearchResponse } from "@api/search";
import { FC } from "react";
import s from "./Lists.module.scss";
import { ImageItem } from "../../../common/ImageItem";

interface ListsProps {
  lists: SearchResponse;
}

export const Lists: FC<ListsProps> = ({ lists }) => {
  const items: TabsProps["items"] = [
    {
      key: "addresses",
      label: "Адреса",
      children: (
        <div className={s.list}>
          {lists.addresses.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "autos",
      label: "Авто",
      children: (
        <div className={s.list}>
          {lists.autos.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "citizens",
      label: "Гражданство",
      children: (
        <div className={s.list}>
          {lists.citizens.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "emails",
      label: "Emails",
      children: (
        <div className={s.list}>
          {lists.emails.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "incomes",
      label: "Доходы",
      children: (
        <div className={s.list}>
          {lists.incomes.map((item) => (
            <div
              key={item.date + item.sum + item.work_place}
              className={s.sub_list}
            >
              <div>{`Дата: ${item.date}`}</div>
              <div>{`Сумма: ${item.sum}`}</div>
              <div>{`Рабочее место: ${item.work_place}`}</div>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "inns",
      label: "ИНН",
      children: (
        <div className={s.list}>
          {lists.inns.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "data",
      label: "Данные",
      children: (
        <div className={s.list}>
          {lists.data.map((item, index) => (
            // TODO fix it
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={s.sub_list}>
              {Object.keys(item ?? {}).map((value) =>
                value !== "other_data" ? (
                  <div key={value}>{`${value}: ${item[value]}`}</div>
                ) : (
                  <div key={value} className={s.sub_list}>
                    <div>Другие данные:</div>
                    {Object.keys(item.other_data ?? {}).map((other) => (
                      <div
                        key={other}
                      >{`${other}: ${item.other_data[other]}`}</div>
                    ))}
                  </div>
                ),
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "other_last_names",
      label: "Отчество",
      children: (
        <div className={s.list}>
          {lists.other_last_names.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "passports",
      label: "Паспорта",
      children: (
        <div className={s.list}>
          {lists.passports.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "persons",
      label: "Люди",
      children: (
        <div className={s.list}>
          {lists.persons.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={s.sub_list}>
              <div>{`Фамилия: ${item.Lastname}`}</div>
              <div>{`Имя: ${item.Firstname}`}</div>
              <div>{`Отчество: ${item.Middlename}`}</div>
              <div>{`Дата рождения: ${item.BirthDate}`}</div>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "phones",
      label: "Номера телефонов",
      children: (
        <div className={s.list}>
          {lists.phones.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "possible_networks",
      label: "Возможные сети",
      children: (
        <div className={s.list}>
          {lists.possible_networks.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={s.sub_list}>
              <div>{`Ресурс: ${item.source}`}</div>
              <div>{`ФИО: ${item.fio}`}</div>
              <div>{`Дата рождения: ${item.birth_date}`}</div>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "snilses",
      label: "Снилс",
      children: (
        <div className={s.list}>
          {lists.snilses.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      ),
    },
    {
      key: "photos",
      label: "Фотографии",
      children: (
        <div className={s.list}>
          {lists.photos.map((item) => (
            <div key={item}>
              <ImageItem src={item} />
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <Tabs defaultActiveKey="addresses" items={items} className={s.tab_body} />
  );
};
