import { Fragment, useCallback } from "react";
import { Text } from "src/ui/Text/Text";
import cn from "classnames";
import { usePagination } from "src/hooks/usePagination";
import { Pagination } from "antd";
import s from "./CrossingBordersTable.module.scss";

type DataType<KeyType extends string> = Record<KeyType, string>;

interface CrossingBordersTableProps<KeyType extends string> {
  headersOrder: string[];
  headers: Record<string, string>;
  data?: DataType<KeyType>[];
  limit: number;
}

export const CrossingBordersTable = <KeyType extends string>({
  headersOrder,
  headers,
  data = [],
  limit,
}: CrossingBordersTableProps<KeyType>) => {
  const fakeItem = headersOrder.reduce<DataType<KeyType>>((acc, header) => {
    acc[header] = "";
    return acc;
  }, {} as DataType<KeyType>);

  const remainder =
    data.length % limit === 0 ? 0 : limit - (data.length % limit);

  const dataWithFake = [...data];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < remainder; i++) {
    dataWithFake.push(fakeItem);
  }

  const { page, setPage, dataSegment } = usePagination<Record<string, string>>({
    data: dataWithFake,
    limit,
  });

  const onChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const renderDefault: Record<string, string[]> = headersOrder?.reduce<
    Record<string, string[]>
  >((acc, header) => {
    acc[header] = [];
    return acc;
  }, {});

  const renderData: Record<string, string[]> = dataSegment?.reduce<
    Record<string, string[]>
  >((acc, dataItem: Record<string, string>) => {
    headersOrder?.forEach((dataItemKey) =>
      acc[dataItemKey].push(dataItem[dataItemKey] ?? ""),
    );
    return acc;
  }, renderDefault);

  const settings = new Array(dataSegment.length + 1).fill(0);

  return (
    <div
      className={s.table}
      style={{ gridTemplateColumns: `repeat(${dataSegment.length + 1}, 1fr)` }}
    >
      {settings.map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={cn(
            s.table__setting,
            s.border,
            index === 0 && s.border__header,
          )}
        />
      ))}
      {headersOrder.map((header) => (
        <Fragment key={header}>
          <div className={cn(s.table__item, s.border, s.border__header)}>
            <Text variant="header-1" className={s.table__item__text}>
              {headers[header]}
            </Text>
          </div>
          {renderData[header].map((renderDataItem: string, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={cn(
                s.table__item,
                s.border,
                index % 2 === 1 && s.table__item_odd,
              )}
            >
              <Text variant="header-1" className={s.table__item__text}>
                {renderDataItem}
              </Text>
            </div>
          ))}
        </Fragment>
      ))}
      {settings.map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={cn(s.table__setting, index === 0 && s.border__header)}
        />
      ))}
      <Pagination
        className={s.table__pagination}
        defaultCurrent={1}
        current={page}
        total={dataWithFake.length}
        onChange={onChange}
        pageSize={limit}
      />
    </div>
  );
};
