import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useCallback,
  useState,
} from "react";
import { Flex, Tag } from "antd";
import type { InputProps } from "antd";
import { IoClose } from "react-icons/io5";
import { Input } from "../Input/Input";

interface MultipleInputProps extends InputProps {
  values: string[];
  onUpdate?: (values: string[]) => void;
  onAdd?: (value: string) => void;
  onDelete?: (value: string) => void;
}

export const MultipleInput: FC<MultipleInputProps> = ({
  values,
  onUpdate,
  onAdd,
  onDelete,
  ...props
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setInputValue(e.target.value);
    },
    [],
  );

  const KeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key !== "Enter" && e.key !== " ") return;

      e.preventDefault();
      const value = inputValue.trim();

      if (inputValue && !values.includes(value)) {
        onUpdate?.([...values, value]);
        onAdd?.(value);
        setInputValue("");
      }
    },
    [inputValue, values, onUpdate, onAdd],
  );

  const handleTagClose = useCallback(
    (removedTag: string) => {
      onUpdate?.(values.filter((tag) => tag !== removedTag));
      onDelete?.(removedTag);
    },
    [values, onUpdate, onDelete],
  );

  return (
    <Flex vertical gap={10}>
      <Input
        {...props}
        value={inputValue}
        onChange={handleChange}
        onKeyPress={KeyPress}
      />
      <Flex>
        {values.map((tag: string) => (
          <Tag
            key={tag}
            closable
            onClose={() => {
              handleTagClose(tag);
            }}
            closeIcon={<IoClose size={8} />}
          >
            {tag}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
};
