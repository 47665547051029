import { ReportType } from "@api/report/constants";

export const MAIN_TAB_QUERY = "tab";

export enum MainTab {
  Dossier = "dossier",
  Info = "info",
  Fio = "fio",
  Phone = "phone",
  Address = "address",
  InnOrSnils = "innOrSnils",
  Passport = "passport",
  Auto = "auto",
  DriverLicense = "driverLicense",
  Email = "email",
  Company = "company",
  Photo = "photo",
  CreditRating = "creditRating",
  Scoring = "scoring",
}

export const mapReportTypeToMainTab: Record<ReportType, MainTab> = {
  [ReportType.SEARCH_ADDRESS]: MainTab.Address,
  [ReportType.SEARCH_CREDIT_REPORT]: MainTab.CreditRating,
  [ReportType.SEARCH_FULL_CREDIT_REPORT]: MainTab.CreditRating,
  [ReportType.SEARCH_EMAIL]: MainTab.Email,
  [ReportType.SEARCH_FIO]: MainTab.Fio,
  [ReportType.SEARCH_GRN]: MainTab.Auto,
  [ReportType.SEARCH_DRIVER_LICENSE]: MainTab.DriverLicense,
  [ReportType.SEARCH_INFO]: MainTab.Info,
  [ReportType.SEARCH_INN_FL]: MainTab.InnOrSnils,
  [ReportType.SEARCH_INN_UL]: MainTab.Company,
  [ReportType.SEARCH_OGRN]: MainTab.Company,
  [ReportType.SEARCH_PASSPORT]: MainTab.Passport,
  [ReportType.SEARCH_PHONE]: MainTab.Phone,
  [ReportType.SEARCH_SNILS]: MainTab.InnOrSnils,
  [ReportType.SEARCH_VIN]: MainTab.Auto,
  [ReportType.SEARCH_PHOTO_BY_IMAGE]: MainTab.Photo,
  [ReportType.SEARCH_SCORING]: MainTab.Scoring,
};

export const mapMainTabToReportTypes: Record<MainTab, ReportType[]> = {
  [MainTab.Address]: [ReportType.SEARCH_ADDRESS],
  [MainTab.Auto]: [ReportType.SEARCH_GRN, ReportType.SEARCH_VIN],
  [MainTab.Company]: [ReportType.SEARCH_INN_UL, ReportType.SEARCH_OGRN],
  [MainTab.CreditRating]: [
    ReportType.SEARCH_CREDIT_REPORT,
    ReportType.SEARCH_FULL_CREDIT_REPORT,
  ],
  [MainTab.Dossier]: [], // TODO check it
  [MainTab.DriverLicense]: [ReportType.SEARCH_DRIVER_LICENSE],
  [MainTab.Email]: [ReportType.SEARCH_EMAIL],
  [MainTab.Fio]: [ReportType.SEARCH_FIO],
  [MainTab.Info]: [ReportType.SEARCH_INFO],
  [MainTab.InnOrSnils]: [ReportType.SEARCH_INN_FL, ReportType.SEARCH_SNILS],
  [MainTab.Passport]: [ReportType.SEARCH_PASSPORT],
  [MainTab.Phone]: [ReportType.SEARCH_PHONE],
  [MainTab.Photo]: [ReportType.SEARCH_PHOTO_BY_IMAGE],
  [MainTab.Scoring]: [ReportType.SEARCH_SCORING],
};
