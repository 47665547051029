import React from "react";
import { PossibleNetwork } from "@api/report";
import { Text } from "../../../ui/Text/Text";
import s from "./RelatedPersonsModal.module.scss";

export interface RelatedPersonsProps {
  possible_networks?: PossibleNetwork[] | null;
}

export const RelatedPersonsModal = ({
  possible_networks,
}: RelatedPersonsProps) => (
  <div className={s.container}>
    <Text variant="header-6">Связанные лица</Text>
    <div className={s.container__list}>
      {possible_networks?.map((possible_network, index) =>
        possible_network?.fio?.replaceAll(" ", "")?.length > 0 ? (
          // eslint-disable-next-line react/no-array-index-key
          <div className={s.container__list__item} key={index}>
            <Text variant="subheader-4">{possible_network.fio}</Text>
            <Text variant="subheader-2" color="green">
              Заказать отчет
            </Text>
          </div>
        ) : null,
      )}
    </div>
  </div>
);
