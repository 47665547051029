import { Flex } from "antd";
import React from "react";
import { ReportsFoundPersons } from "src/components/ReportsFoundPersons/ReportsFoundPersons";
import { Data } from "src/components/Data/Data";
import { RelatedPersons } from "src/components/RelatedPersons/RelatedPersons";
import { ReportCommonProps } from "@entities/types";
import { ReportsPhoneRetrieveResponse, ReportStatus } from "@api/report";
import { FullDossier } from "src/components/FullDossier/FullDossier";
import { PhoneBook } from "./components/PhoneBook/PhoneBook";
import { StatusPaper } from "../../components/StatusPaper/StatusPaper";
import { RetrieveStatus } from "@entities/RetrieveStatus/RetrieveStatus";
import { calculateReportStatus } from "../../utils/getReportStatus";
import { OrderMap } from "@entities/SearchPhone/components/OrderMap/OrderMap";

export type SearchPhoneProps = {
  report: ReportsPhoneRetrieveResponse;
} & ReportCommonProps;

export const SearchPhone = ({ report, type }: SearchPhoneProps) => {
  console.log("report", report);
  return (
    <RetrieveStatus
      status={
        calculateReportStatus([
          report?.person?.status,
          report?.sub_reports?.status,
        ]) || report?.status
      }
    >
      <Flex vertical gap={32}>
        {report?.person?.status && (
          <FullDossier
            phones={report?.person?.person_info?.phones}
            snilses={report?.person?.person_info?.snilses}
            inns={report?.person?.person_info?.inns}
            passports={report?.person.person_info?.passports}
            emails={report?.person?.person_info?.emails}
            requestDescribe={report?.request_describe}
            autos={report?.person?.person_info?.autos}
            persons={report?.person?.person_info?.persons}
            type={type}
            status={report?.person?.status}
          />
        )}
        {report?.sub_reports?.status && (
          <ReportsFoundPersons
            report_list={report?.sub_reports?.report_list}
            status={report?.sub_reports?.status}
          />
        )}
        {report?.person?.person_info?.order_map && <OrderMap />}
        {report?.person?.status && (
          <RelatedPersons
            possible_networks={report?.person?.person_info?.possible_networks}
            status={report?.person?.status}
          />
        )}
        {report?.person?.status && (
          <PhoneBook
            phoneBook={report?.person?.person_info?.phone_book}
            status={report?.person?.status}
          />
        )}
        {report?.person?.person_info?.data?.map(
          (reportsPersonInfoData, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Data key={index} reportsPersonInfoData={reportsPersonInfoData} />
          )
        )}
      </Flex>
    </RetrieveStatus>
  );
};
