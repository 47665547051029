import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "../styles/index.css";
import "./validation/yupConfig";
import { App } from "@processes/App/App";
import { queryClient } from "@api/client";
import { ConfigProvider } from "antd";
import { StrictMode } from "react";
import ErrorBoundary from "./layouts/ErrorBoundary/ErrorBoundary";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={{ hashed: false }}>
          <App />
        </ConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
);
