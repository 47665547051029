import cn from "classnames";
import { Button } from "src/ui/Button/Button";
import { useCallback, useState } from "react";
import { useBooleanState } from "src/hooks/useBooleanState";
import s from "./PhotoGallery.module.scss";

export interface PhotoGalleryProps {
  srcImages: string[];
}

export const PhotoGallery = ({ srcImages }: PhotoGalleryProps) => {
  const { state: isOpen, setTrue, setFalse } = useBooleanState(false);

  const openGallery = useCallback(() => setTrue(), [setTrue]);
  const closeGallery = useCallback(() => setFalse(), [setFalse]);
  const onImageInner = useCallback((e) => e.stopPropagation(), []);

  const [image, setImage] = useState<string>(srcImages[0]);

  return srcImages?.length > 0 ? (
    <div className={s.container}>
      <div className={s.container__images}>
        {srcImages[0] && (
          <img
            src={srcImages[0]}
            alt="Some"
            className={cn(
              s.container__images__image,
              s.container__images__image_first,
            )}
          />
        )}
        {srcImages[1] && (
          <img
            src={srcImages[1]}
            alt="Some"
            className={cn(
              s.container__images__image,
              s.container__images__image_second,
            )}
          />
        )}
        {srcImages[2] && (
          <img
            src={srcImages[2]}
            alt="Some"
            className={cn(
              s.container__images__image,
              s.container__images__image_third,
            )}
          />
        )}
      </div>
      <Button type="text_secondary" onClick={openGallery}>
        {srcImages?.length}+ Смотреть
      </Button>
      {isOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={s.container__modal} onClick={closeGallery}>
          <div className={s.container__modal__inner} onClick={closeGallery}>
            <img
              src={image}
              className={s.container__modal__inner__image}
              onClick={onImageInner}
              alt={image}
            />
            <div
              className={s.container__modal__inner__images}
              onClick={onImageInner}
            >
              {srcImages.map((srcImage, index) => (
                <img
                  src={srcImage}
                  onClick={() => setImage(srcImage)}
                  className={s.container__modal__inner__images__image}
                  alt={srcImage}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
