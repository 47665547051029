import { Flex } from "antd";
import { Block } from "src/ui/Block/Block";
// import { UserSettingsApi } from './components/UserSettingsApi/UserSettingsApi';
import { Text } from "src/ui/Text/Text";
import { BreadCrumps } from "src/components/BreadCrumps/BreadCrumps";
import { LuChevronLeft } from "react-icons/lu";
import { AppRoute } from "src/app/routes/constants";
import { Button } from "src/ui/Button/Button";
import { Link } from "react-router-dom";
import { useGroups } from "@api/groups";
import { useCurrentUserQuery } from "@api/groups/queries/useCurrentUser";
import { UserSettingsSessions } from "./components/UserSettingsSessions/UserSettingsSessions";
import { UserSettingsSecurity } from "./components/UserSettingsSecurity/UserSettingsSecurity";
import { UserSettingsMainInfo } from "./components/UserSettingsMainInfo/UserSettingsMainInfo";
import s from "./UserSettings.module.scss";

export const UserSettings = () => {
  const { data: user } = useCurrentUserQuery();
  const { data: groups } = useGroups();
  const group = groups?.[0];

  if (!user || !group) {
    return null;
  }

  return (
    <Flex vertical gap={32} className={s.wrapper}>
      <Flex vertical gap={8}>
        <Link className={s.link} to={AppRoute.Lk}>
          <Flex gap={4} align="center">
            <Button type="flat" pin="round">
              <LuChevronLeft size={24} />
            </Button>
            <Text variant="header-8">Настройки аккаунта</Text>
          </Flex>
        </Link>
        <BreadCrumps
          mapPathToTitle={{ [AppRoute.User]: "Настройки аккаунта" }}
          paths={[AppRoute.User]}
        />
      </Flex>
      <Block>
        <UserSettingsMainInfo user={user} group={group} />
      </Block>
      <Block>
        <UserSettingsSecurity group={group} />
      </Block>
      {/* <Block>
        <UserSettingsApi />
      </Block> */}
      <Block>
        <UserSettingsSessions
          user={{ ...user, user_id: user.id, permissions: [] }}
        />
      </Block>
    </Flex>
  );
};
