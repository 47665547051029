import { FC, MouseEventHandler, useCallback } from "react";
import { DropZone, DropZoneFile } from "src/ui/DropZone/DropZone";
import { Flex, Image } from "antd";
import { MdPortrait } from "react-icons/md";
import { Text } from "src/ui/Text/Text";
import { Button } from "src/ui/Button/Button";
import classNames from "classnames";
import { useReportsPhotoByFaceSearch } from "@api/report";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationMessage } from "src/utils/constants";
import { handleBackendErrors } from "src/utils/notifications/handleBackendErrors";
import { useNotification } from "src/hooks/useNotification";
import { SubmitButton } from "../../components/SubmitButton/SubmitButton";
import s from "./MainTabPhoto.module.scss";
import { MainTabProps } from "../../types";
import { dropZoneFileListSchema } from "../../constants";

interface MainTabPhotoFormValues {
  fileList: DropZoneFile[];
}

const schema = yup.object<MainTabPhotoFormValues>().shape({
  fileList: dropZoneFileListSchema.required(validationMessage.Required),
});

type MainTabPhotoProps = MainTabProps;

export const MainTabPhoto: FC<MainTabPhotoProps> = () => {
  const {
    control,
    handleSubmit,
    resetField,
    formState: { isValid },
  } = useForm<MainTabPhotoFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      fileList: [],
    },
  });

  const { mutateAsync: search, isPending: isSearchPending } =
    useReportsPhotoByFaceSearch();
  const [api] = useNotification();

  const onSubmitInner = useCallback(
    async (data: MainTabPhotoFormValues) => {
      if (!data.fileList[0]?.originFileObj) return;
      const { originFileObj: file } = data.fileList[0];

      const requestData = new FormData();
      requestData.append("image", file);

      search(requestData)
        .then(() => {
          api.success({
            message: "Запрос успешно создан!",
          });
        })
        .catch((error: unknown) => {
          if (handleBackendErrors({ api, error })) return;

          api.error({
            message: "Произошла непредвиденная ошибка!",
            description: JSON.stringify(String(error), null, 2),
          });
        });
    },
    [search, api],
  );

  const onClear = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      resetField("fileList");
    },
    [resetField],
  );

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmitInner)}>
      <Flex vertical gap={24}>
        <Controller
          name="fileList"
          control={control}
          render={({ field }) => {
            const file = field.value[0]?.originFileObj;

            return (
              <DropZone
                {...field}
                accept=".jpg,.jpeg,.png,.gif,.webp"
                multiple={false}
              >
                {file ? (
                  <Flex
                    align="center"
                    vertical
                    gap={8}
                    className={classNames(s.zone, s.photoZone)}
                  >
                    <Flex align="center" vertical gap={4}>
                      <Image
                        rootClassName={s.photo}
                        preview={false}
                        src={URL.createObjectURL(file)}
                        height={180}
                        width={180}
                      />
                      <Text color="aqua" variant="body-1">
                        {file.name}
                      </Text>
                    </Flex>
                    <Flex gap={8}>
                      <Button type="flat" size="small" onClick={onClear}>
                        <Text variant="body-4" color="darkGray">
                          Очистить
                        </Text>
                      </Button>
                      <Button type="flat" size="small">
                        <Text variant="body-4" color="green">
                          Заменить
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    align="center"
                    vertical
                    gap={32}
                    className={classNames(s.zone, s.dropZone)}
                  >
                    <Flex align="center" vertical gap={24}>
                      <MdPortrait size={48} className={s.icon} />
                      <Text color="aqua" variant="body-6">
                        Загрузите или перетащите файл формата: jpg, png, gif,
                        webp
                      </Text>
                    </Flex>
                    <Button type="flat">
                      <Text variant="body-4" color="green">
                        Выбрать файл
                      </Text>
                    </Button>
                  </Flex>
                )}
              </DropZone>
            );
          }}
        />
        <Flex justify="flex-end">
          <SubmitButton disabled={!isValid || isSearchPending} />
        </Flex>
      </Flex>
    </form>
  );
};
