export const endpoints = {
  GROUPS: "/api/v1/groups",
  GROUPS_CREATE: "/api/v1/groups:create",
  GROUPS_EDIT: "/api/v1/groups:edit",
  GROUPS_REPORTS_TOTAL_COUNT: "/api/v1/groups/reports:totalCount",
  GROUPS_ADD_MEMBER: "/api/v1/groups:addMember",
  GROUPS_REMOVE_MEMBER: "/api/v1/groups:removeMember",
  GROUPS_GET_PERMISSIONS: "/api/v1/groups:getGroupPermissions",
  GROUPS_GRANT_PERMISSIONS: "/api/v1/groups:grantPermissions",
  GROUPS_LOGIN_DURATION: "/api/v1/groups:setLoginDuration",
  GROUPS_GRANT_USER_PERMISSIONS: "/api/v1/groups:grantUserPermissions",
  GROUPS_SET_NON_RUSSIAN_IP_ALLOWED: "/api/v1/groups:setNonRussianIpAllowed",
  USERS_EDIT: "/api/v1/users:edit",
  USERS_CURRENT_USER: "/api/v1/users/currentUser",
  USERS_CHANGE_PASSWORD: "/api/v1/users/currentUser:changePassword",
  USERS_SET_QUOTA: "/api/v1/users:setQuota",
  USERS_REGISTER: "/api/v1/users:register",
  USERS_CONFIRM_EMAIL: "/api/v1/users:confirmEmail",
  USERS_RESEND_EMAIL: "/api/v1/users:resendEmail",
  USERS_PROFILE_PICTURE: "/api/v1/users/profilePicture",
  USERS_PROFILE_PICTURE_RETRIEVE: "/api/v1/users/profilePicture:retrieve",
  USERS_PROFILE_PICTURE_DELETE: "/api/v1/users/profilePicture:delete",
};
