import { client } from "@api/client";

import { SessionsEndpoint } from "./endpoints";
import {
  SessionsDeleteOtherRequest,
  SessionsDeleteOtherResponse,
  SessionsDeleteRequest,
  SessionsDeleteResponse,
  SessionsSearchRequest,
  SessionsSearchResponse,
} from "./types";

export const sessionsDeleteOther = (data: SessionsDeleteOtherRequest) =>
  client.post<undefined, SessionsDeleteOtherResponse>(
    SessionsEndpoint.DeleteOther,
    data,
  );

export const sessionsDelete = (data: SessionsDeleteRequest) =>
  client.post<undefined, SessionsDeleteResponse>(SessionsEndpoint.Delete, data);

export const sessionsSearch = (data: SessionsSearchRequest) =>
  client.post<undefined, SessionsSearchResponse>(SessionsEndpoint.Search, data);
