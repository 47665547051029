import { Text } from "../../../ui/Text/Text";

export interface ColumnProps {
  title: string;
  items?: string[] | null;
  className?: string;
}

export const Column = ({ title, items, className }: ColumnProps) => (
  <div className={className}>
    <Text variant="label-1" color="darkGray">
      {title}
    </Text>
    {items && items.length > 0
      ? items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={`${item}-${index}`} id={item} variant="body-5">
            {item}
          </Text>
        ))
      : "Не найдено"}
  </div>
);
