import { client } from "@api/client";

import { endpoints } from "./endpoints";
import {
  Fio,
  Phone,
  OtherFields,
  SearchResponse,
  SearchAutoRegistrationNumberSearchRequest,
  SearchAutoRegistrationNumberSearchResponse,
} from "./types";

export const search_fio = (data: Fio) =>
  client.post<undefined, SearchResponse>(endpoints.SEARCH_FIO, data);

export const search_phone = (data: Phone) =>
  client.post<undefined, SearchResponse>(endpoints.SEARCH_PHONE, data);

export const search_other_fields = (data: OtherFields) =>
  client.post<undefined, SearchResponse>(endpoints.SEARCH_OTHER_FIELDS, data);

export const searchAutoRegistrationNumberSearch = (
  data: SearchAutoRegistrationNumberSearchRequest,
) =>
  client.post<undefined, SearchAutoRegistrationNumberSearchResponse>(
    endpoints.AutoRegistrationNumber,
    data,
  );
